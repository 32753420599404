export enum ChecklistType {
	User = "User",
	Store = "Store",
}

export enum ChecklistSharedType {
	Shared = "Shared",
	Individual = "Individual",
}

export enum ChecklistTaskType {
	Event = "event",
	Activity = "activity",
}

export enum ChecklistTaskDateType {
	DueDateDays = "due_date_days ",
	ActualDueDate = "actual_due_date",
}
