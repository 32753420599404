import React from "react";
import { StoreForm } from "../../../../types/formTypes";
import "../healthChecksTable.scss";
interface HealthCheckModalProps {
	healthChecks: StoreForm[];
}

export const HealthCheckModal: React.FC<HealthCheckModalProps> = ({ healthChecks }) => {
	return (
		<div className="modal-content">
			<table className="health-checks-table">
				<thead>
					<tr>
						<th>Restaurant NSN</th>
						<th>Last Health Check</th>
						<th>Completed By</th>
					</tr>
				</thead>
				<tbody>
					{healthChecks.map((entry, index) => (
						<tr key={index}>
							<td>
								{entry.restaurantNSN} - {entry.restaurantName}
							</td>
							<td>{entry.createdAt}</td>
							<td>{entry.completedBy}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
