import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
	atom,
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from "recoil";
import { PathName } from "../constants/PathName";
import { gql } from "../generated/old";
import {
	Breadcrumb,
	DashboardMenu_Dashboardmenufields,
	LaygoBreadcrumb,
} from "../generated/graphql";
import useProduct from "../hooks/useProduct";
import useQueryParams from "../hooks/useQueryParams";
import { getResultProduct, strip_tags } from "../utils/utils";
import { UserContext } from "./UserContext";

const DASHBOARD_MENUS_QUERY = gql(`
	query GetDashboardMenus(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		dashboardMenus(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				dashboardMenuFields {
					color
					description
					descriptionEs
					fieldGroupName
					icon
					id
					parent
					title
					titleEs
					url
				}
			}
		}
	}
`);

const LAYGO_BREADCRUMBS_QUERY = gql(`
	query getLaygoBreadcrumbsQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$initiativeSlug: [String]
		$userEid: String
	) {
		laygoBreadcrumbs(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
			}
		) {
			nodes {
				laygoHomepageHeaderFields {
					en
					es
					id
				}
			}
		}
	}
`);

const BREADCRUMBS_QUERY = gql(`
	query getBreadcrumbsQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$initiativeSlug: [String]
		$userEid: String
	) {
		breadcrumbs(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
			}
		) {
			nodes {
				breadcrumbFields {
					id
					title
					titleEs
				}
			}
		}
	}
`);

export interface DashboardMenuSections {
	[key: string]: DashboardMenu_Dashboardmenufields[];
}

export interface BreadcrumbItem {
	name: string;
	id?: string;
	url?: string;
}

export const activeDashboardMenuAtom = atom<string>({
	key: "activeDashboardMenu",
	default: "root",
});

export const dashboardMenuSectionsAtom = atom<DashboardMenuSections>({
	key: "dashboardMenuSections",
	default: {},
});

export const dashboardTitleAtom = atom<string>({
	key: "dashboardTitle",
	default: "Dashboard",
});

export const dashboardParentColorAtom = atom<string>({
	key: "dashboardParentColor",
	default: "#828282",
});
export const useDashboardMenu = () => {
	const { store } = useContext(UserContext);
	const { permissionGroup, userData } = store;

	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const setDashboardMenuSections = useSetRecoilState(dashboardMenuSectionsAtom);
	const resetDashboardMenuSections = useResetRecoilState(dashboardMenuSectionsAtom);

	const [getDashboardMenus, getDashboardMenusRes] = useLazyQuery(DASHBOARD_MENUS_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		return () => {
			resetDashboardMenuSections();
		};
	}, [resetDashboardMenuSections]);

	useEffect(() => {
		void getDashboardMenus();
	}, [getDashboardMenus, permissionGroup]);

	useEffect(() => {
		if (getDashboardMenusRes.called && !getDashboardMenusRes.loading) {
			const data = getDashboardMenusRes.data;
			if (data && data.dashboardMenus && data.dashboardMenus.nodes) {
				//create menus
				const menus: DashboardMenuSections = {};

				data.dashboardMenus.nodes.forEach((item) => {
					if (item && item.dashboardMenuFields && item.dashboardMenuFields.parent) {
						if (menus[item.dashboardMenuFields.parent]) {
							menus[item.dashboardMenuFields.parent].push(item.dashboardMenuFields);
						} else {
							menus[item.dashboardMenuFields.parent] = [item.dashboardMenuFields];
						}
					} else if (item && item.dashboardMenuFields) {
						if (menus["root"]) {
							menus["root"].push(item.dashboardMenuFields);
						} else {
							menus["root"] = [item.dashboardMenuFields];
						}
					}
				});
				setDashboardMenuSections(menus);
			}
		}
	}, [
		getDashboardMenusRes.data,
		getDashboardMenusRes.called,
		getDashboardMenusRes.loading,
		setDashboardMenuSections,
	]);

	return {
		dashboardMenuSections,
		getDashboardMenus,
		getDashboardMenusRes,
	};
};

export const useDashboardPath = () => {
	const [title, setTitle] = useRecoilState(dashboardTitleAtom);
	const [parentColor, setParentColor] = useRecoilState(dashboardParentColorAtom);
	const [dashboardPath, setDashboardPath] = useState<BreadcrumbItem[]>([]);
	const activeDashboardMenu = useRecoilValue(activeDashboardMenuAtom);
	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const { store } = useContext(UserContext);
	const { translation } = store;
	const location = useLocation();

	const findParentItem = (parentID: string) => {
		var parentItem: DashboardMenu_Dashboardmenufields | undefined;
		Object.values(dashboardMenuSections).forEach((dashboardMenuSection) => {
			dashboardMenuSection.forEach((menuItem) => {
				if (menuItem.id === parentID) parentItem = menuItem;
			});
		});
		return parentItem;
	};

	useEffect(() => {
		// set title
		const dmsLength = Object.keys(dashboardMenuSections).length > 0;
		if (!dmsLength) return;
		if (activeDashboardMenu === "root") {
			setTitle(translation("Dashboard", "Dashboard"));
			setParentColor("#828282");
			setDashboardPath([{ id: "root", name: "Dashboard" }]);
		} else {
			const tempDashboardPath: BreadcrumbItem[] = [];

			var tempParent = activeDashboardMenu;
			// dashboard menus loop
			while (!tempDashboardPath.some((e) => e.id === "root")) {
				const parentItem = findParentItem(tempParent);

				if (parentItem) {
					tempDashboardPath.push({
						id: parentItem.id!,
						name: strip_tags(translation(parentItem.title, parentItem.titleEs)),
					});
				}

				if (parentItem && tempParent === activeDashboardMenu) {
					setTitle(strip_tags(translation(parentItem.title, parentItem.titleEs)));
				}
				if (parentItem && parentItem.parent) {
					tempParent = parentItem.parent;
				} else {
					tempDashboardPath.push({ id: "root", name: "Dashboard" });
					setParentColor(parentItem?.color || "#828282");
				}
			}
			setDashboardPath(tempDashboardPath.reverse());
		}
	}, [activeDashboardMenu, dashboardMenuSections, location]);

	return {
		title,
		parentColor,
		dashboardPath,
	};
};

export const useLaygoBreadcrumbs = () => {
	//state hooks
	const [laygoBreadcrumbPath, setLaygoBreadcrumbPath] = useState<BreadcrumbItem[]>([]); //To create
	const [laygoBreadCrumbs, setLaygoBreadcrumbs] = useState<LaygoBreadcrumb[]>(); //From DB

	//recoils
	const setActiveDashboardMenu = useSetRecoilState(activeDashboardMenuAtom);
	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const { store } = useContext(UserContext);
	const { translation, userLanguage, permissionGroup, userData } = store;

	//custom hooks
	const query = useQueryParams();
	const product = useProduct();
	const module = query.get("m");
	const section = query.get("s");
	const subSection1 = query.get("ss1");
	const page = query.get("p");
	const moduleSub = query.get("msub");

	const [getLaygoBreadcrumbs, { loading, error, data, called }] = useLazyQuery(
		LAYGO_BREADCRUMBS_QUERY,
		{
			variables: {
				acceptAccessRole: permissionGroup.accessLevel,
				acceptCompanyType: permissionGroup.companyType,
				acceptCustomRole: permissionGroup.customRole,
				initiativeSlug: module,
				userEid: userData.eID,
			},
		},
	);

	const findParentItemWithParams = (params: (string | null)[]) => {
		var parentItem: DashboardMenu_Dashboardmenufields | undefined;
		const filteredParams = params.filter((param) => param !== null);
		if (filteredParams.length > 0) {
			Object.values(dashboardMenuSections).forEach((dashboardMenuSection) => {
				dashboardMenuSection.forEach((menuItem) => {
					let hasAllValues = true;
					params.every((param) => {
						if ((menuItem.url && param && !menuItem.url.includes(param)) || menuItem.url === null) {
							hasAllValues = false;
							return false;
						}
						return true;
					});
					if (hasAllValues) {
						parentItem = menuItem;
					}
				});
			});
		}

		return parentItem;
	};

	useEffect(() => {
		void getLaygoBreadcrumbs();
	}, [getLaygoBreadcrumbs, permissionGroup, module]);

	useEffect(() => {
		if (called && !loading) {
			if (
				data &&
				data.laygoBreadcrumbs &&
				data.laygoBreadcrumbs.nodes &&
				data.laygoBreadcrumbs.nodes?.length > 0
			) {
				setLaygoBreadcrumbs(data.laygoBreadcrumbs.nodes as LaygoBreadcrumb[]);
			}
		}
	}, [data, called, loading, setLaygoBreadcrumbs]);

	useEffect(() => {
		//set product
		const tempBreadcrumbPath: BreadcrumbItem[] = [];
		if (product && module) {
			tempBreadcrumbPath.push({
				name: module,
				url: "no-click",
			});
			tempBreadcrumbPath.push({
				name: getResultProduct(product, userLanguage) || product,
				url:
					PathName.DASHBOARD +
					product +
					"/?m=" +
					module +
					"&msub=" +
					moduleSub +
					"&l=" +
					userLanguage,
			});
		}

		if (laygoBreadCrumbs && laygoBreadCrumbs.length > 0 && product === "mcd-laygo") {
			const breadcrumbTitles = laygoBreadCrumbs;

			if (section) {
				// try to find section and subsection from breadcrumbs
				const breadcrumbTitlesSection = breadcrumbTitles.filter(
					(el) => el?.laygoHomepageHeaderFields?.id === section,
				)[0];

				const name = translation(
					breadcrumbTitlesSection?.laygoHomepageHeaderFields?.en,
					breadcrumbTitlesSection?.laygoHomepageHeaderFields?.es,
				);
				tempBreadcrumbPath.push({
					name: breadcrumbTitlesSection ? name : section,
					url:
						PathName.DASHBOARD +
						product +
						"/?m=" +
						module +
						"&msub=" +
						moduleSub +
						"&s=" +
						section +
						"&p=" +
						section +
						"&l=" +
						userLanguage,
				});
			}

			if (subSection1) {
				const breadcrumbTitlesSs1 = breadcrumbTitles.filter(
					(el) => el?.laygoHomepageHeaderFields?.id === subSection1,
				)[0];
				const name = translation(
					breadcrumbTitlesSs1?.laygoHomepageHeaderFields?.en,
					breadcrumbTitlesSs1?.laygoHomepageHeaderFields?.es,
				);
				tempBreadcrumbPath.push({
					name: breadcrumbTitlesSs1 ? name : subSection1,
					url:
						PathName.DASHBOARD +
						product +
						"/?m=" +
						module +
						"&msub=" +
						moduleSub +
						"&s=" +
						section +
						"&ss1=" +
						subSection1 +
						"&p=" +
						subSection1 +
						"&l=" +
						userLanguage,
				});
			}

			if (page && section !== page && subSection1 !== page) {
				const breadcrumbPage = breadcrumbTitles.filter(
					(el) => el?.laygoHomepageHeaderFields?.id === page,
				)[0];
				const name = translation(
					breadcrumbPage?.laygoHomepageHeaderFields?.en,
					breadcrumbPage?.laygoHomepageHeaderFields?.es,
				);
				tempBreadcrumbPath.push({
					name: breadcrumbPage ? name : page,
				});
			}
		}

		setLaygoBreadcrumbPath(tempBreadcrumbPath);
	}, [
		product,
		module,
		moduleSub,
		section,
		subSection1,
		page,
		laygoBreadCrumbs,
		setLaygoBreadcrumbPath,
	]);

	//find dashboard element, if params MATCHED a URL iun the dashboard items
	useEffect(() => {
		if (product === "mcd-transitionkit") {
			return;
		}
		if (Object.keys(dashboardMenuSections).length > 0) {
			const parentItem = findParentItemWithParams([
				product,
				module,
				moduleSub,
				section,
				subSection1,
				page,
			]);
			if (parentItem) {
				setActiveDashboardMenu(parentItem.parent || "root");
			} else {
				setActiveDashboardMenu("root");
			}
		}
	}, [product, module, dashboardMenuSections]);

	return {
		getLaygoBreadcrumbs,
		loading,
		error,
		laygoBreadcrumbPath,
	};
};

export const useBreadcrumbs = () => {
	//state hooks
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>();

	//recoils
	const { store } = useContext(UserContext);
	const { permissionGroup, userData } = store;

	//custom hooks

	const [getBreadcrumbs, getBreadcrumbsRes] = useLazyQuery(BREADCRUMBS_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	useEffect(() => {
		void getBreadcrumbs();
	}, [getBreadcrumbs, permissionGroup]);

	useEffect(() => {
		if (getBreadcrumbsRes.called && !getBreadcrumbsRes.loading) {
			if (
				getBreadcrumbsRes.data &&
				getBreadcrumbsRes.data.breadcrumbs &&
				getBreadcrumbsRes.data.breadcrumbs.nodes &&
				getBreadcrumbsRes.data.breadcrumbs.nodes?.length > 0
			) {
				setBreadcrumbs(getBreadcrumbsRes.data.breadcrumbs.nodes as Breadcrumb[]);
			}
		}
	}, [getBreadcrumbs, getBreadcrumbsRes.loading, getBreadcrumbsRes.called, getBreadcrumbsRes.data]);

	return {
		breadcrumbs,
		getBreadcrumbsRes,
	};
};
