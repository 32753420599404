// import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { useRecoilState } from "recoil";
import "./topPdfViewer.scss";
import { topPdfViewerUrlAtom } from "../../contexts/topPdfViewer.recoil";
import PDFViewer from "../pdfViewer/PDFViewer";
import { calculate_top_margin } from "../../utils/utils";
import useProduct from "../../hooks/useProduct";

const TopPdfViewer: React.FC = () => {
	const product = useProduct();

	const [url, setUrl] = useRecoilState(topPdfViewerUrlAtom);
	if (!url) {
		return null;
	}

	const onCloseClick = () => {
		setUrl(undefined);
	};

	return (
		<div
			className="top-pdf-viewer"
			onClick={() => onCloseClick()}
			style={{ ...calculate_top_margin(product) }}
		>
			<div
				className="col-lg-9 col-lg-offset-3 tp-card animate__animated animate__fadeInRight animate__faster"
				onClick={(e) => e.stopPropagation()}
			>
				<PDFViewer file={url} shouldShowControl />
			</div>
		</div>
	);
};

export default TopPdfViewer;
