import useQueryParamsT from "../../hooks/useQueryParamsT";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
	OrderEnum,
	PostObjectsConnectionOrderbyEnum,
	ProductInfoFragment,
	ProductInfoNavigationFragment,
	useGetProductInfoNavigationLazyQuery,
	useGetProductInfosLazyQuery,
} from "../../generated/graphql";

interface InfoQueryParams extends Record<string, string | undefined> {
	m?: string;
	msub?: string;
	ref?: string;
	errors?: string;
	s?: string;
	p?: string;
}

interface NavigationSections {
	sectionId: string | null | undefined;
	sectionOrder: number;
	pages: NavigationSectionPages[];
}

interface NavigationSectionPages {
	pageId: string | null | undefined;
	pageOrder: number;
}
interface InfoPageLink {
	sectionId: string | null | undefined;
	pageId: string | null | undefined;
}
interface NavigationInfoPageLinks {
	prevSectionFirstPage: InfoPageLink | null;
	prevPage: InfoPageLink | null;
	nextPage: InfoPageLink | null;
	nextSectionFirstPage: InfoPageLink | null;
	backToStart: InfoPageLink | null;
}

export const useInfoQueryParams = () => {
	const {
		m: module,
		msub,
		ref,
		errors,
		s: navSection,
		p: page,
	} = useQueryParamsT<InfoQueryParams>();
	return { module, msub, ref, errors, navSection, page };
};

export const useInfoNavigationLinks = (
	navigation: ProductInfoNavigationFragment[],
	pages: ProductInfoFragment[],
	inner_p: string,
) => {
	const nestedSectionsArray = useMemo(() => {
		const nestedSectionsArray: NavigationSections[] = [];

		if (!navigation || !pages) {
			return [];
		}

		if (navigation && pages) {
			navigation.forEach((section) => {
				const sectionObj: NavigationSections = {
					sectionId: section.productInfoNavigationFields?.navId,
					sectionOrder: section.metaFields?.sortOrder ?? 0,
					pages: [],
				};
				pages
					.filter(
						(page) => page.productInfoFields?.parent === section.productInfoNavigationFields?.navId,
					)
					.forEach((page) => {
						sectionObj.pages.push({
							pageId: page.productInfoFields?.pifid,
							pageOrder: page.metaFields?.sortOrder ?? 0,
						});
					});
				nestedSectionsArray.push(sectionObj);
			});
		}
		return nestedSectionsArray;
	}, [navigation, pages]);

	const infoPageLinks = useMemo(() => {
		const infoPageLinks: NavigationInfoPageLinks = {
			prevSectionFirstPage: null,
			prevPage: null,
			nextPage: null,
			nextSectionFirstPage: null,
			backToStart: null,
		};
		nestedSectionsArray.forEach((section, i, navArray) => {
			const navLength = navArray.length;
			section.pages.forEach((page, j, pageArray) => {
				// find current page
				if (page.pageId === inner_p) {
					// prevSectionFirstPage
					if (i > 0 && navArray[i - 1].pages.length > 0) {
						infoPageLinks.prevSectionFirstPage = {
							sectionId: navArray[i - 1].sectionId,
							pageId: navArray[i - 1].pages[0].pageId,
						};
					}
					// prevPage
					if (j > 0) {
						infoPageLinks.prevPage = {
							sectionId: section.sectionId,
							pageId: pageArray[j - 1].pageId,
						};
					} else if (i > 0 && navArray[i - 1].pages.length > 0) {
						infoPageLinks.prevPage = {
							sectionId: navArray[i - 1].sectionId,
							pageId: navArray[i - 1].pages[navArray[i - 1].pages.length - 1].pageId,
						};
					}
					// nextPage
					if (j < pageArray.length - 1) {
						infoPageLinks.nextPage = {
							sectionId: section.sectionId,
							pageId: pageArray[j + 1].pageId,
						};
					} else if (i < navLength - 1 && navArray[i + 1].pages.length > 0) {
						infoPageLinks.nextPage = {
							sectionId: navArray[i + 1].sectionId,
							pageId: navArray[i + 1].pages[0].pageId,
						};
					} else {
						infoPageLinks.backToStart = {
							sectionId: navArray[0].sectionId,
							pageId: navArray[0].pages[0].pageId,
						};
					}
					// nextSectionFirstPage
					if (i < navLength - 1 && navArray[i + 1].pages.length > 0) {
						infoPageLinks.nextSectionFirstPage = {
							sectionId: navArray[i + 1].sectionId,
							pageId: navArray[i + 1].pages[0].pageId,
						};
					}
				}
			});
		});
		return infoPageLinks;
	}, [inner_p, nestedSectionsArray]);

	return {
		nestedSectionsArray,
		infoPageLinks,
	};
};

export const UseInfoService = () => {
	const { module } = useInfoQueryParams();
	const { store } = useContext(UserContext);
	const { permissionGroup, userData } = store;

	const [pages, setPages] = useState<ProductInfoFragment[]>([]);
	const [navigation, setNavigation] = useState<ProductInfoNavigationFragment[]>([]);

	/*query */
	const [_getProductInfos, getProductInfosRes] = useGetProductInfosLazyQuery();
	const [getProductInfoNavigation, getProductInfoNavigationRes] =
		useGetProductInfoNavigationLazyQuery();

	/*local functions*/
	const noComingSoonFields = (nodes: ProductInfoFragment[]) => {
		if (nodes) {
			const filtered = nodes.filter((node) => node.productInfoFields?.parent !== "coming-soon");
			return filtered;
		} else {
			return [];
		}
	};

	const noComingSoonNav = (nodes: ProductInfoNavigationFragment[]) => {
		if (nodes) {
			const filtered = nodes.filter(
				(node) => node.productInfoNavigationFields?.navId !== "coming soon",
			);
			return filtered;
		} else {
			return [];
		}
	};

	const _getAllProductInfos = useCallback(async () => {
		const productInfos: ProductInfoFragment[] = [];
		let hasNextPage: boolean = false;
		let endCursor: string | null | undefined = undefined;

		do {
			const res: any = await _getProductInfos({
				variables: {
					first: 100,
					after: endCursor,
					where: {
						acceptAccessRole: permissionGroup.accessLevel,
						acceptCompanyType: permissionGroup.companyType,
						acceptCustomRole: permissionGroup.customRole,
						initiativeSlug: module ? [module] : undefined,
						userEid: userData.eID,
						orderby: [
							{
								field: PostObjectsConnectionOrderbyEnum.SortOrder,
								order: OrderEnum.Asc,
							},
						],
					},
				},
			});
			const productInfoNodes = res.data?.productInfos?.nodes;

			if (productInfoNodes && productInfoNodes.length > 0) {
				const tempPages = noComingSoonFields(productInfoNodes as ProductInfoFragment[]);
				productInfos.push(...tempPages);
			}
			if (res.data?.productInfos?.pageInfo?.hasNextPage) {
				hasNextPage = true;
				endCursor = res.data?.productInfos?.pageInfo?.endCursor;
			}
			if (res.error) {
				console.error(res.error);
				break;
			}
		} while (hasNextPage);
		return productInfos;
	}, [_getProductInfos, permissionGroup, module, userData.eID]);

	// Startup
	useEffect(() => {
		_getAllProductInfos().then((res) => {
			setPages(res);
		});
	}, [_getAllProductInfos]);

	useEffect(() => {
		getProductInfoNavigation({
			variables: {
				where: {
					acceptAccessRole: permissionGroup.accessLevel,
					acceptCompanyType: permissionGroup.companyType,
					acceptCustomRole: permissionGroup.customRole,
					initiativeSlug: module ? [module] : undefined,
					userEid: userData.eID,
					orderby: [
						{
							field: PostObjectsConnectionOrderbyEnum.SortOrder,
							order: OrderEnum.Asc,
						},
					],
				},
			},
		});
	}, [
		getProductInfoNavigation,
		module,
		permissionGroup.accessLevel,
		permissionGroup.companyType,
		permissionGroup.customRole,
		userData.eID,
	]);

	useEffect(() => {
		if (
			getProductInfoNavigationRes.data?.piNavigations?.nodes &&
			getProductInfoNavigationRes.data.piNavigations.nodes.length > 0
		) {
			const tempNavigation = noComingSoonNav(
				getProductInfoNavigationRes.data.piNavigations.nodes as ProductInfoNavigationFragment[],
			);
			setNavigation(tempNavigation);
		}
	}, [getProductInfoNavigationRes]);

	const error = getProductInfoNavigationRes.error || getProductInfosRes.error;
	const loading = getProductInfoNavigationRes.loading || getProductInfosRes.loading;

	return { pages, navigation, error, loading };
};
