import Portals, { PortalSubscription } from "@ionic/portals";
import { useEffect, useState } from "react";

export interface PublishWithSubtopic<T> {
	subtopic: string;
	data: T;
}

export interface TkitInitialContext {
	token: string; // "" if no token
	route: string;
}

export const usePortalsInitialContext = () => {
	const [data, setData] = useState<TkitInitialContext>();

	useEffect(() => {
		Portals.getInitialContext<any>()
			.then((initialContextRes) => {
				if (initialContextRes.name === "tkit") {
					setData(initialContextRes.value);
				}
			})
			.catch(() => {});
	}, [setData]);

	return { data };
};

export const usePortalsSubscribe = <T>(topic: string, callback: (data: T) => void) => {
	const [subscription, setSubscription] = useState<PortalSubscription | undefined>();

	useEffect(() => {
		Portals.subscribe(
			{
				topic,
			},
			(result) => {
				callback(result.data as T);
			},
		).then((subscriptionRes) => {
			setSubscription(subscriptionRes);
		});
	}, [topic]);

	useEffect(() => {
		return () => {
			if (subscription) {
				Portals.unsubscribe(subscription);
			}
		};
	}, [subscription]);
};

export const openOadt = (route: string) => {
	return Portals.publish({
		topic: "openOadt",
		data: route,
	});
};
