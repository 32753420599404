import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { PathName } from "../constants/PathName";
import useQueryParams from "../hooks/useQueryParams";
import { LocalStorageKeys } from "../constants/LocalStorageKeys";

// You can't use capacitor here
// This only works with the mcd login web view (coming back from eID login) and not with ionic portal web view (tkit and oadt)
const McdCallback = () => {
	const queryParams = useQueryParams();
	const history = useHistory();

	const isFinished = useRef(false);

	useEffect(() => {
		// isFinished prevents multiple calls
		// not sure why it fires multiple times
		if (!isFinished.current) {
			isFinished.current = true;
			const token = queryParams.get("token");
			if (token) {
				if ((window as any).Native) {
					// android
					(window as any).Native.finish(token);
				} else if ((window as any).webkit?.messageHandlers?.["Native"]) {
					// ios
					(window as any).webkit.messageHandlers["Native"].postMessage(token);
				} else {
					// web
					localStorage.setItem(LocalStorageKeys.TOKEN, token);
					history.push(PathName.DASHBOARD);
				}
			}
		}
	}, [queryParams]);

	return <div>Logging in...{/* @TODO loading anim would be nice here */}</div>;
};

export default McdCallback;
