import React from "react";
import { FiX } from "react-icons/fi";
import "./modal.scss";

export interface ModalProps {
	render: JSX.Element;
	title?: JSX.Element | string;
	onCloseClick?: () => void;
}

const Modal: React.FC<ModalProps> = ({ render, onCloseClick, title }) => {
	return (
		<div className="g3-modal">
			<div
				className="g3-card animate__animated animate__fadeInUp animate__faster"
				onClick={(e) => e.stopPropagation()}
			>
				{(title || onCloseClick) && (
					<div className="g3-card-header flex">
						<div className="g3-modal-title">{title}</div>
						{onCloseClick && (
							<FiX
								className="m-4 h-5 w-5 cursor-pointer self-end rounded-full hover:bg-gray-300"
								onClick={() => onCloseClick()}
							/>
						)}
					</div>
				)}

				<div className="g3-modal-content">{render}</div>
			</div>
		</div>
	);
};

export default Modal;
