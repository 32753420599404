import React from "react";
import "./formProgressBar.scss";
interface FormProgressBarProps {
	value: number;
}

export const FormProgressBar: React.FC<FormProgressBarProps> = ({ value }) => {
	console.log("Rendering FormProgressBar");

	return (
		<div className="form-progress-bar-container">
			<div className="form-progress-bar">
				<div className="progress-bar-line" style={{ width: `${value * 100}%` }}></div>
			</div>
		</div>
	);
};
