import React, { useContext } from "react";
import { UserContextType, UserContext } from "../../../contexts/UserContext";
import { DashboardMenu_Dashboardmenufields } from "../../../generated/graphql";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { dashboardMenuSectionsAtom } from "../../../contexts/DashboardMenus.recoil";
import { useHistory } from "react-router";
import { useSidebarMenu } from "../../../contexts/SidebarMenus.recoil";
import { _navActiveAtom } from "../../../contexts/Navbar.recoil";
import ParseHTMLContent from "../../ParseHTMLContent/ParseHTMLContent";
import { isMobile } from "../../../utils/utils";
import { topPdfViewerUrlAtom } from "../../../contexts/topPdfViewer.recoil";
interface Props {
	menuItems: DashboardMenu_Dashboardmenufields[];
	menuId: string;
}

const SideMenu: React.FC<Props> = ({ menuItems, menuId }) => {
	const { store } = useContext<UserContextType>(UserContext);
	const { translation } = store;
	const { sidebarParentColor, setSidebarActiveMenu } = useSidebarMenu();
	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const setNavActive = useSetRecoilState(_navActiveAtom);
	const history = useHistory();
	const setPDFUrl = useSetRecoilState(topPdfViewerUrlAtom);

	const isValidUrl = (urlString: string) => {
		var inputElement = document.createElement("input");
		inputElement.type = "url";
		inputElement.value = urlString;

		if (!inputElement.checkValidity()) {
			return false;
		} else {
			return true;
		}
	};

	const handleClick = (menuItem: DashboardMenu_Dashboardmenufields) => {
		//first if it has a menu will go to child menu, else check if has valid url/path
		if (menuItem.id && dashboardMenuSections[menuItem.id]) {
			setSidebarActiveMenu(menuItem.id);
		} else if (menuItem.url) {
			// if its a FULL url not just the path
			if (isValidUrl(menuItem.url)) {
				const url = new URL(menuItem.url);
				if (
					menuItem.url.includes("https://files-cdn") &&
					menuItem.url.includes(".pdf") &&
					isMobile()
				) {
					return setPDFUrl(menuItem.url);
				}

				if (
					(url.hostname === window.location.hostname ||
						url.hostname.includes("localhost") ||
						url.hostname.includes("nuggetkms.com") ||
						url.hostname.includes("mcdtkit.com")) &&
					!url.hostname.includes("files-cdn")
				) {
					history.push(url.pathname + url.search + url.hash);
				} else {
					// for external links
					window.open(menuItem.url, "_blank", "noopener noreferrer");
				}
			} else {
				history.push(menuItem.url);
			}
			setNavActive("");
		}
	};
	return (
		<div className="sidebar-menu">
			{menuItems.map((menuItem, key) => {
				return (
					<div
						key={menuId + "-" + key}
						className="menu-item"
						onClick={() => handleClick(menuItem)}
						style={{ borderLeftColor: menuItem.color || sidebarParentColor || "" }}
					>
						<div className="icon-slot">
							<img src={menuItem.icon || ""} alt="" />
						</div>
						<div className="text-slot">
							<div className="title">
								<ParseHTMLContent htmlString={translation(menuItem.title, menuItem.titleEs)} />
							</div>
							{(menuItem.description || menuItem.descriptionEs) && (
								<div className="description">
									<ParseHTMLContent
										htmlString={translation(menuItem.description, menuItem.descriptionEs)}
									/>
								</div>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default SideMenu;
