import React, { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { imgPath, PathName } from "../../../constants/PathName";
import { Link } from "react-router-dom";
import useQueryParams from "../../../hooks/useQueryParams";
import { gql, useQuery } from "@apollo/client";
import Loading from "../../Loading/Loading";
import { useRecoilState, useResetRecoilState } from "recoil";
import { infoMenuListAtom } from "../../../contexts/infoMenuList.recoil";
import useProduct from "../../../hooks/useProduct";
import useWindowSize from "../../../hooks/useWindowSize";
import SideMenus from "../SideMenus/SideMenus";
import { activeDashboardMenuAtom } from "../../../contexts/DashboardMenus.recoil";
import { useGetAppActive, useSetAppActive } from "./appSwitcher.service";

const APP_SWITCHER_QUERY = gql`
	query GetAppSwitcherQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		mainNavApps(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				mainNavAppsFields {
					fieldGroupName
					icon
					someid: id
					label
					labelEs
					module
					msub
					url
				}
				metaFields {
					sortOrder
				}
			}
		}

		dashboardModules(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				dashboardModulesFields {
					id
					allowedeids
				}
			}
		}

		mainNavDashboards {
			nodes {
				mainNavModulesFields {
					fieldGroupName
					icon
					id
					label
					labelEs
					url
				}
			}
		}

		mainNavModules(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				mainNavModulesFields {
					fieldGroupName
					icon
					id
					label
					labelEs
					url
				}
			}
		}

		mainNavSubModules(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				mainNavSubModulesFields {
					icon
					id
					label
					labelEs
					module
					url
				}
			}
		}
	}
`;

const AppSwitcher = ({ isActive, closeNavOpt }) => {
	//state hooks
	const { appActive } = useGetAppActive();
	const { setAppActive } = useSetAppActive();

	//recoils - contexts
	const { store } = useContext(UserContext);
	const { translation, permissionGroup, userData } = store;
	const [infoMenuList] = useRecoilState(infoMenuListAtom);
	const resetActiveDashboardMenu = useResetRecoilState(activeDashboardMenuAtom);

	//custom hooks
	const query = useQueryParams();
	const product = useProduct();
	const size = useWindowSize();

	const { loading, error, data } = useQuery(APP_SWITCHER_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	//local variables
	const p = query.get("p");
	const homepath = PathName.DASHBOARD;
	const isWindowSmall = size.width < 1200;

	//functions

	//React hooks
	useEffect(() => {
		//everytime p changes and is in mcd info we want to always see navigation menu in sidebar
		if (product === "mcd-info" && isWindowSmall) {
			setAppActive("mcdInfoNav");
		} else {
			setAppActive("");
		}
	}, [p, product, isWindowSmall, isActive]);

	const onLinkClick = (e) => {
		closeNavOpt();
		resetActiveDashboardMenu();
	};

	//jsx variables
	//pre jsx process & variables
	if (loading) return <Loading />;
	if (error) return <p>Unable to find dashboard :(</p>;

	const mainDashboard = data.mainNavDashboards.nodes[0].mainNavModulesFields || [];

	//BEGIN APP SWITCHER
	return (
		<div
			id="appsLinks"
			className={"apps-links animated " + (isActive ? " slideInLeft " : " hidden ")}
		>
			<ul id="dashboard">
				<Link to={homepath} className="anc " onClick={onLinkClick}>
					<li>
						<img
							src={imgPath + "/" + mainDashboard["icon"]}
							alt={translation(mainDashboard["label"], mainDashboard["labelEs"])}
						/>
						{translation(mainDashboard["label"], mainDashboard["labelEs"])}
					</li>
				</Link>
			</ul>

			<ul
				id="sideMenus"
				className={appActive === "" ? "mcd-info-nav animated slideInRight " : "hidden "}
			>
				<SideMenus />
			</ul>
			<ul
				id="mcdInfoNav"
				className={appActive === "mcdInfoNav" ? "mcd-info-nav animated slideInRight " : "hidden "}
			>
				<li className="header sub" onClick={() => setAppActive("")} key={0}>
					<div id="backToInitiatives" className="anc">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.71 35">
							<path d="M18.2.37l3.15 3.14a1.27 1.27 0 0 1 0 1.77L9.13 17.5l12.22 12.22a1.27 1.27 0 0 1 0 1.77l-3.15 3.14a1.25 1.25 0 0 1-1.77 0L.5 18.7a1.68 1.68 0 0 1 0-2.4L16.43.37a1.25 1.25 0 0 1 1.77 0z" />
							<path d="M18.2.37l3.15 3.14a1.27 1.27 0 0 1 0 1.77L9.13 17.5l12.22 12.22a1.27 1.27 0 0 1 0 1.77l-3.15 3.14a1.25 1.25 0 0 1-1.77 0L.5 18.7a1.68 1.68 0 0 1 0-2.4L16.43.37a1.25 1.25 0 0 1 1.77 0z" />
						</svg>
					</div>
					{translation("Pages", "Paginas")}
				</li>

				{infoMenuList}
			</ul>
		</div>
	);
};

export default AppSwitcher;
