import React, { useContext, useEffect, useState } from "react";
import "./checklists.scss";
import { useGetChecklistAssignmentsLazyQuery } from "../../generated/graphql";
import { Pagination } from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";
import { ChecklistCard } from "./checklistCard/ChecklistCard";
import { UserContext, UserContextType } from "../../contexts/UserContext";
import useQueryParams from "../../hooks/useQueryParams";
import { calculate_top_margin } from "../../utils/utils";

const PAGE_ITEM_COUNT = 20;
export const CheckLists: React.FC = () => {
	const { store } = useContext<UserContextType>(UserContext);
	const {
		personID,
		permissionGroup: { accessLevel, companyType, customRole },
	} = store;
	const query = useQueryParams();
	const module = query.get("m"); // this is the initiative

	const [currentPage, setCurrentPage] = useState<number>(1);

	const [getChecklistAssignmentsLazyQuery, getChecklistAssignmentsLazyQueryRes] =
		useGetChecklistAssignmentsLazyQuery();

	useEffect(() => {
		getChecklistAssignmentsLazyQuery({
			variables: {
				pagination: {
					limit: PAGE_ITEM_COUNT,
					offset: (currentPage - 1) * PAGE_ITEM_COUNT,
				},
				where: {
					acceptAccessRole: accessLevel,
					acceptCompanyType: companyType,
					acceptCustomRoles: customRole,
					userPersonId: personID,
					initiativeSlug: module,
				},
			},
		});
	}, [
		accessLevel,
		companyType,
		currentPage,
		customRole,
		getChecklistAssignmentsLazyQuery,
		module,
		personID,
	]);

	const checklists = getChecklistAssignmentsLazyQueryRes.data?.getChecklistAssignments?.rows || [];
	const checklistCount =
		getChecklistAssignmentsLazyQueryRes.data?.getChecklistAssignments?.count || 0;

	const pages = checklistCount / PAGE_ITEM_COUNT;

	return (
		<div className="container-fluid main checklist-menus" style={{ ...calculate_top_margin("") }}>
			{getChecklistAssignmentsLazyQueryRes.loading && <Loading />}
			<div className="checklist-menu-header">
				<div className="checklist-menu-header-left">
					<h1>Checklists</h1>
				</div>
				<div className="checklist-menu-header-right">{checklistCount} items</div>
			</div>
			<div className="checklist-menu">
				{checklists.map((checklist) => {
					if (!checklist) {
						return null;
					}
					return (
						<React.Fragment key={checklist?.checklistAssignmentId}>
							<ChecklistCard checklist={checklist} />
						</React.Fragment>
					);
				})}
			</div>
			<div className="checklist-pagination">
				{pages > 1 && (
					<Pagination
						pages={pages}
						currentPage={currentPage}
						onPageClick={(selectedPage) => setCurrentPage(selectedPage)}
						hasJumpToPage
					/>
				)}
			</div>
		</div>
	);
};
