import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import NotFound from "../components/NotFound/NotFound";
import Footer from "../components/Footer/Footer";
import Login from "./login/Login";
import "material-design-icons";
import NavLayout from "../components/NavLayout/NavLayout";
import PrivateRoute from "../components/PrivateRoute";
import McdCallback from "./McdCallback";
import RollbarTest from "../components/RollbarTest/RollbarTest";
import TopModal from "../components/topModal/TopModal";
import Modals from "../components/modal/Modals";

const history = createBrowserHistory();

// @TODO clean up redirects. Remove query params from the link if the target page doesn't need that query params. Or if the query param is empty
// @TODO use Link instead of a
// @TODO language. query param and memory is confusing. unify them or pick one

const Main = () => {
	return (
		<Router history={history}>
			<Modals />
			<TopModal />
			<div style={{ display: "flow-root" }}>
				<Switch>
					<Route exact path={"/dev-about"} render={() => <div>dev about</div>} />
					<Route exact path={"/login"} render={() => <Login />} />
					<Route exact path="/mcd-callback" render={() => <McdCallback />} />
					<Route exact path="/404" render={() => <NotFound />} />
					<Route exact path="/rollbar-test" render={() => <RollbarTest />} />
					<PrivateRoute path="/" render={() => <NavLayout />} />
				</Switch>
				<Footer />
			</div>
		</Router>
	);
};

export default Main;
