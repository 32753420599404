import React from "react";
import "./listItemtaskview.scss"; // Import your CSS file
import {
	ChecklistViewStateType,
	useGetChecklistViewState,
	useSetChecklistViewState,
} from "../checklistDetail.hooks";

const TaskViewComponent: React.FC = () => {
	const { checklistViewState } = useGetChecklistViewState();
	const { setChecklistViewState } = useSetChecklistViewState();

	const handleButtonClick = (filter: ChecklistViewStateType) => {
		if (filter !== checklistViewState) {
			setChecklistViewState(filter);
		}
	};

	const getButtonClass = (filter: ChecklistViewStateType) => {
		return `button ${filter === checklistViewState ? "selected" : ""}`;
	};

	return (
		<div className="listitemtask-view-container">
			<div>View: </div>
			<div className="options">
				<button className={getButtonClass("all")} onClick={() => handleButtonClick("all")}>
					All Tasks
				</button>
				|
				<button className={getButtonClass("current")} onClick={() => handleButtonClick("current")}>
					Open Task
				</button>
				|
				<button
					className={getButtonClass("completed")}
					onClick={() => handleButtonClick("completed")}
				>
					Completed Task
				</button>
			</div>
		</div>
	);
};

export default TaskViewComponent;
