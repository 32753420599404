import React from "react";

import "./formsSection.scss";
import { FormDetailData } from "../../../../../../utils/deleteme/formsData";
import { Link } from "react-router-dom";
interface Props {
	index: number;
	section: any;
	isCompleted?: boolean;
	isSelected?: boolean;
	onSectionClick: (sectionIndex: number) => void;
}

export const FormSection: React.FC<Props> = ({
	index,
	section,
	isCompleted,
	isSelected,
	onSectionClick,
}) => {
	const handleClick = (sectionIndex: number) => {
		if (onSectionClick) {
			onSectionClick(index);
		}
	};
	return (
		<div
			className={`custom-form-section ${isSelected ? "custom-selected" : ""}`}
			onClick={() => handleClick(index)}
		>
			{isCompleted && <span className="custom-green-check-mark">✔</span>}
			{isSelected && <div className="custom-yellow-bar" />}
			<div className={`custom-section-name ${isCompleted ? "custom-completed" : ""}`}>
				<Link
					to="#"
					onClick={(e) => {
						e.preventDefault();
						handleClick(index);
					}}
				>
					{section.name}
				</Link>
			</div>
		</div>
	);
};
