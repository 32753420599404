import React, { FormEventHandler } from "react";

interface Props {
	submitSearch: FormEventHandler<HTMLFormElement> | undefined;
	isSearchValid: boolean;
	searchValue: string;
	setSearchValue: (value: string) => void;
	translation: (en: string, es: string) => string | undefined;
	isLoading: boolean;
}
export const DisplaySearchForm: React.FC<Props> = ({
	submitSearch,
	isSearchValid,
	searchValue,
	setSearchValue,
	translation,
	isLoading,
}) => {
	return (
		<form id="#search_form" className="search-form" onSubmit={submitSearch}>
			<div className="col-xs-8">
				<input
					className={isSearchValid ? "" : "invalid"}
					type="text"
					name="q"
					id="search_input"
					value={searchValue}
					onInput={(e) => setSearchValue(e.currentTarget.value)}
					placeholder={translation("Search", "Buscar")}
				/>
			</div>
			<div className="col-xs-2">
				<button
					type="submit"
					name="submit"
					className="btn btn-primary search-button ml-2"
					value="submit"
					disabled={isLoading || !isSearchValid}
				>
					{translation("Search", "Buscar")}
				</button>
			</div>
		</form>
	);
};
