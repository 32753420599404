import { useLocation } from "react-router-dom";

const useQueryParamsT = <T extends Record<string, string | undefined>>(): Partial<T> => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const queryParams: Partial<T> = {};

	params.forEach((value, key) => {
		(queryParams as any)[key] = value;
	});

	return queryParams;
};

export default useQueryParamsT;
