import React, { useCallback, useContext } from "react";
import "./checklist-report-tasks.scss";
import { ChecklistTaskRow } from "../checklistReportDetail.service";
import { countdownToDate } from "../../../checklistDetail/checklistTasksTable/ChecklistTasksTable.service";
import { useSetRecoilState } from "recoil";
import { modalRenderAtom, modalTitleAtom } from "../../../../../contexts/modal.recoil";
import { ChecklistReportTaskModal } from "./cheklistReportTaskModal/ChecklistReportTaskModal";
import { UserContext, UserContextType } from "../../../../../contexts/UserContext";
import ParseHTMLContent from "../../../../../components/ParseHTMLContent/ParseHTMLContent";
import { parseTimezone } from "../../../../../utils/utils-typescript";
type CheckListReportItemProps = {
	items: ChecklistTaskRow[];
	goLiveDate: Date;
	checklistName: string;
};

const CheckListReportTasks: React.FC<CheckListReportItemProps> = ({
	items,
	goLiveDate,
	checklistName,
}) => {
	const { store } = useContext<UserContextType>(UserContext);
	const { translation } = store;

	const setModalRender = useSetRecoilState(modalRenderAtom);
	const setModalTitle = useSetRecoilState(modalTitleAtom);

	const openTaskDetailsModal = useCallback(
		(item: ChecklistTaskRow) => {
			setModalTitle("Task Details");
			setModalRender(
				<ChecklistReportTaskModal
					additionalResourcesUrl={item.moreDetailsUrl}
					formatDueDate={countdownToDate(goLiveDate, item.taskDueDateDays)}
					parentChecklistName={checklistName}
					taskDescription={translation(item.taskDescription, item.taskDescriptionEs)}
					taskName={translation(item.taskTitle, item.taskTitleEs)}
					isCritical={item.taskIsCritical}
					isCompleted={item.incompleteTasks.length === 0}
				/>,
			);
		},
		[checklistName, goLiveDate, setModalRender, setModalTitle, translation],
	);
	const formatDateTime = (
		dateString: string | null,
		timeString: string | null,
		timezone: string | null,
	) => {
		try {
			const formattedDate = dateString != null ? formatDateOnly(dateString) : "";

			if (timeString == null || timeString === "") {
				return `${formattedDate}`;
			}
			let formattedTime = "";
			if (timeString != null) {
				const time = new Date(`2000-01-01T${timeString}`);
				formattedTime = time.toLocaleTimeString("en-US", {
					hour: "2-digit",
					minute: "2-digit",
				});
			}

			const formattedTimezone = parseTimezone(timezone ?? "");

			return `${formattedDate} ${formattedTime.toLowerCase()} ${formattedTimezone}`;
		} catch (error) {
			console.error("Error formatting date and time:", error);
			return "";
		}
	};
	const formatDateOnly = (dateString: string | null) => {
		if (dateString != null) {
			const year = dateString.substring(0, 4);
			const month = dateString.substring(4, 6);
			const day = dateString.substring(6, 8);

			const formattedDate = `${month}/${day}/${year}`;

			return formattedDate;
		}
		return "";
	};
	return (
		<>
			<tr className="checklist-report-table-row">
				<th className="checklist-report-table-header">Task</th>
				<th className="checklist-report-table-header">Due Date</th>
				<th className="checklist-report-table-header">Completed Task</th>
				<th className="checklist-report-table-header">Not Completed Task</th>
			</tr>
			{items.map((item, i) => (
				<tr key={i} className="checklist-report-row">
					<td className="checklist-report-table-element">
						<div
							className="checklist-report-task-name-container anc"
							onClick={() => {
								openTaskDetailsModal(item);
							}}
						>
							<ParseHTMLContent htmlString={translation(item.taskTitle, item.taskTitleEs)} />
						</div>
					</td>

					<td className="checklist-report-table-element">
						{(item.taskDaysOrActualDate === "Due Date Days" ||
							item.taskDaysOrActualDate === "due_date_days" ||
							item.taskDaysOrActualDate === "") &&
							item.taskDueDateDays != null && (
								<div className="checklist-report-due-date-container">
									<div className="checklist-report-circle-container">
										<div
											className="checklist-report-circle checklist-report-due-date-container-items"
											style={{
												backgroundColor: countdownToDate(
													goLiveDate,
													item.taskDueDateDays,
													item.incompleteTasks.length === 0,
												).color,
											}}
										></div>
									</div>
									<div className="checklist-report-due-date-container-items">
										{item.taskDueDateDays !== null &&
											countdownToDate(
												goLiveDate,
												item.taskDueDateDays,
												item.incompleteTasks.length === 0,
											).message}
									</div>
								</div>
							)}
						{item.taskDaysOrActualDate === "actual_due_date" && item.taskActualDueDate != null && (
							<div className="checklist-report-due-date-container-items">
								{item.taskActualDueDate != null && (
									<div>
										{formatDateTime(
											item.taskActualDueDate,
											item.taskActualDueDateTime,
											item.taskActualDueDateTimezone,
										)}
									</div>
								)}
							</div>
						)}
					</td>
					<td className="checklist-report-table-element">
						<div className="checklist-report-completed-task-container">
							{item.completedTasks.map((task, index) => (
								<div key={index}>{task}</div>
							))}
						</div>
					</td>

					<td className="checklist-report-table-element">
						<div className="checklist-report-uncompleted-task-container">
							{item.incompleteTasks.map((task, index) => (
								<div key={index}>{task}</div>
							))}
						</div>
					</td>
				</tr>
			))}
		</>
	);
};

export default CheckListReportTasks;
