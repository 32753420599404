import { gql, useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { UserContext, UserState } from "./UserContext";

interface MyUserBadges {
	ba_001: number;
	ba_002: number;
	ba_003: number;
	ba_004: number;
	ba_005: number;
	ba_006: number;
	ba_007: number;
	ba_008: number;
	ba_009: number;
	ba_010: number;
	ba_011: number;
	ba_013: number;
	ba_014: number;
	ba_012: number;
	ba_016: number;
	ba_015: number;
	ba_018: number;
	ba_017: number;
	ba_019: number;
	ba_021: number;
	ba_022: number;
	ba_020: number;
	ba_023: number;
	ba_024: number;
}

const GET_USER_BADGES_PROGRESS = gql`
	query GetMyBadgesQuery($personID: String!) {
		getMyBadges(personID: $personID) {
			ba_001
			ba_002
			ba_003
			ba_004
			ba_005
			ba_006
			ba_007
			ba_008
			ba_009
			ba_010
			ba_011
			ba_013
			ba_014
			ba_012
			ba_016
			ba_015
			ba_018
			ba_017
			ba_019
			ba_021
			ba_022
			ba_020
			ba_023
			ba_024
		}
	}
`;

export const myBadgesAtom = atom<MyUserBadges | undefined>({
	key: "myBadges",
	default: undefined,
});

export const useMyBadges = () => {
	const [myBadges, setMyBadges] = useRecoilState(myBadgesAtom);
	const { store } = useContext(UserContext) as any;
	const { personID } = store as UserState;

	//prepare query
	const [getUserBadges, { loading, error, data }] = useLazyQuery(GET_USER_BADGES_PROGRESS, {
		variables: {
			personID,
		},
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		if (data?.getMyBadges && !loading) {
			setMyBadges(data.getMyBadges);
		}
	}, [data, loading]);

	//export
	return {
		myBadges,
		getUserBadges,
		loading,
		error,
		data,
	};
};
