import { excludeWords_en, excludeWords_es } from "../constants/ExcludedWords";
import { Capacitor } from "@capacitor/core";

export const in_array = (val, arr) => {
	return arr && arr.some((arrVal) => arrVal === val);
};
export const empty = (val) => {
	return !val;
};
export const isset = (val) => {
	return !!val;
};

export const preg_match = () => {
	// @TODO
};

export const str_replace = (search, replace, string) => {
	return string.replaceAll(search, replace);
};

export const strtolower = (string) => {
	return string.toLocaleLowerCase();
};

export const print_r = () => {
	//@TODO
};

export const makeQuery = (currentQuery, additionalQuery) => {
	const queriesObj = {};
	currentQuery.forEach((v, k) => {
		queriesObj[k] = v;
	});

	const additionalQueryKeys = Object.keys(additionalQuery);
	for (let i = 0; i < additionalQueryKeys.length; ++i) {
		const key = additionalQueryKeys[i];
		queriesObj[key] = additionalQuery[key];
	}

	const queries = [];
	const queriesObjKeys = Object.keys(queriesObj);
	for (let i = 0; i < queriesObjKeys.length; ++i) {
		const key = queriesObjKeys[i];
		queries.push(`${key}=${queriesObj[key]}`);
	}

	return queries.join("&");
};

// not needed
// export const end = () => {
// 	//@TODO
// };

// export const strlen = () => {
// 	//@TODO
// };

export const explode = (separator, string, limit) => {
	const array = string.split(separator);
	if (limit !== undefined && array.length >= limit) {
		array.push(array.splice(limit - 1).join(separator));
	}
	return array;
};

export const is_array = (arr) => {
	return Array.isArray(arr);
};

export const array_push = (arr, val) => {
	return arr.push(val);
};

export const parse_url = () => {
	//@TODO
};

export const array_column = () => {
	//@TODO
};

export const array_search = () => {
	//@TODO
};

export const is_int = () => {
	//@TODO
};

export const substr = (val, start, end) => {
	return val.substring(start, end);
	//@TODO
};

export const ucfirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const strtotime = (string) => {
	return Date.parse(string);
	//@TODO
};

export const usort = () => {
	//@TODO
};

export const krsort = () => {
	//@TODO
};

export const count = () => {
	//@TODO
};

/*** CALCULATE MAIN CONTAINER MARGIN FROM TOP ***/
export const calculate_top_margin = (product, module) => {
	const navbarHeight = 50;
	// const subBarHeight = 0;
	const breadcrumbsHeight = 50;
	const footerHeight = 40;
	let topMargin = navbarHeight + breadcrumbsHeight;
	let botMargin = footerHeight;

	// if (product === "mcd-laygo") {
	// 	topMargin = navbarHeight + breadcrumbsHeight;
	// } else if (product === "mcd-resources") {
	// 	topMargin = navbarHeight;
	// } else if (module === "eeotf" && product === "mcd-info") {
	// 	topMargin = navbarHeight + subBarHeight;
	// } else {
	// 	topMargin = navbarHeight;
	// }

	if (!isMobile()) {
		botMargin = footerHeight;
	}

	return {
		marginTop: 0 + "px",
		"--marginTop": topMargin + "px",
		marginBottom: 0 + "px",
		"--marginBottom": botMargin + "px",
	};
};

export const setPageTitle = (product) => {
	let title = "MCD";
	let pageTitle = "Home";

	switch (product) {
		case "mcd-transitionkit":
			title = "NuggetKMS";
			pageTitle = "Home";
			break;
		case "mcd-paths":
			title = "Learning Paths";
			pageTitle = "Home";
			break;
		case "mcd-info":
			title = "Product Info";
			pageTitle = "Home";
			break;
		// case "mcd-numbers":
		// 	title = "Finding Your Numbers";
		// 	pageTitle = "Home";
		// 	break;
		// case "mcd-demo":
		// 	title = "Demo Walkthrough";
		// 	pageTitle = "Home";
		// 	break;
		case "mcd-laygo":
			title = "LAYGO";
			pageTitle = "Home";
			break;
		case "mcd-flashcards":
			title = "Flash Cards";
			pageTitle = "Home";
			break;
		case "mcd-resources":
			title = "Resource Library";
			pageTitle = "Home";
			break;
		case "mcd-search":
			title = "Search";
			pageTitle = "Search";
			break;
		default:
			title = "MCD";
			pageTitle = "Home";
	}

	// document.title = pageTitle + " - " + title;
	document.title = "NuggetKMS";
};

export const isMobile = () => {
	return Capacitor.isPluginAvailable("Nugget");
};

export const strip_tags = (string) => {
	if (string) {
		const noStyle = string.replace(/(<style[\w\W]+style>)/g, "");
		return noStyle.replace(/(<([^>]+)>)/gi, "");
	}
};

export const validateSearch = (term, userLanguage) => {
	let isValid = false;
	if (userLanguage === "es") {
		isValid = !excludeWords_es.includes(term);
	} else {
		isValid = !excludeWords_en.includes(term);
	}
	return isValid;
};

export const isSearchQuouted = (term) => {
	return (
		(term.startsWith("'") && term.endsWith("'")) || (term.startsWith('"') && term.endsWith('"'))
	);
};

export const getResInitiative = (initiative) => {
	switch (initiative.toLowerCase()) {
		case "cit":
			return "CIT";

		case "eeotf":
			return "EEOTF";

		case "myidm":
			return "My IdM";

		case "mfa":
			return "MFA";

		case "oadt":
			return "OADT";

		case "crewid":
			return "Crew ID";

		case "lifelenz":
			return "LIFELENZ";

		case "fsl":
			return "FSL";

		case "wfm":
			return "WFM";
		default:
			return "";
	}
};

export const getResultProduct = (product, userLanguage) => {
	if (product === "mcd-whats-new" || product === "whats-new") {
		if (userLanguage === "en" || userLanguage === "" || userLanguage === null) {
			return "What's New";
		} else if (userLanguage === "es") {
			return "Novedades";
		}
	} else if (product === "mcd-transitionkit" || product === "") {
		return "Learning Paths";
	} else if (product === "mcd-info") {
		return "Product Info";
	} else if (product === "mcd-laygo") {
		return "LAYGO";
	} else if (product === "mcd-demo") {
		return "Demo Walkthrough";
	} else if (product === "mcd-resources") {
		return "Resource Library";
	} else if (product === "mcd-flashcards") {
		return "Flash Cards";
	}
	return "";
};

export const isValidUrl = (urlString) => {
	var inputElement = document.createElement("input");
	inputElement.type = "url";
	inputElement.value = urlString;

	if (!inputElement.checkValidity()) {
		return false;
	} else {
		return true;
	}
};

export const buildUrl = (post, pathName, userLanguage) => {
	/** BUILD URL */
	let resultUrl = "";
	if (
		post.attributes["product"] === "mcd-whats-new" ||
		post.attributes["product"] === "whats-new" ||
		post.attributes["product"] === "mcd-transitionkit" ||
		post.attributes["product"] === "" ||
		post.attributes["product"] === null ||
		post.attributes["product"] === undefined
	) {
		if (!post.attributes["url"]) {
			resultUrl =
				pathName.DASHBOARD +
				"?p=whats-new" +
				"&t=" +
				(post.attributes["initiative"]
					? post.attributes["initiative"].toLowerCase()
					: post.initiatives?.[0]?.slug) +
				"&l=" +
				userLanguage +
				"#" +
				post.attributes["fieldId"];
		} else if (post.post_type === "pi-sections") {
			resultUrl =
				pathName.DASHBOARD +
				"mcd-info" +
				post.attributes["url"] +
				"&l=" +
				userLanguage +
				"#" +
				post.attributes["fieldId"];
		} else {
			resultUrl =
				pathName.DASHBOARD +
				post.attributes["url"] +
				"&l=" +
				userLanguage +
				"#" +
				post.attributes["fieldId"];
		}
	} else {
		resultUrl =
			pathName.DASHBOARD +
			post.attributes["product"] +
			"/" +
			post.attributes["url"] +
			"&l=" +
			userLanguage +
			"#" +
			post.attributes["fieldId"];
	}

	return resultUrl;
};

export const buildDescription = (attributes, translation) => {
	let resultDescription = "";
	let content = strip_tags(translation(attributes.content, attributes.content_es));
	let description =
		strip_tags(translation(attributes.description, attributes.description_es)) ?? "";
	switch (true) {
		case content != null && content !== "": {
			resultDescription = content;
			if (content.length > 500) {
				resultDescription = content.substring(0, 500) + "...";
			}
			break;
		}
		default: {
			resultDescription = description;
			if (description.length > 500) {
				resultDescription = description.substring(0, 500) + "...";
			}
		}
	}
	return resultDescription;
};

export const listOfInitiativeSlugs = [
	"cit",
	"crewid",
	"eeotf",
	"ned",
	"ol",
	"slr",
	"zip",
	"fsl",
	"lifelenz",
	"mfa",
	"myidm",
	"oadt",
	"wfm",
];

export const listOfSearchPostTypes = [
	// "product-info",
	"pi-sections",
	"laygo-questions",
	"resource-library",
	// "whats-new",
	"intros",
	"dashboard-access",
];
