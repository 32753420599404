import { openOadt } from "../../../../portals/portalsUtils";
import { isMobile } from "../../../../utils/utils";

interface Props {
	url: string;
	className?: string;
}

const OADTLink: React.FC<Props> = ({ url, className, children }) => {
	return (
		<>
			{isMobile() ? (
				<div
					className={className}
					onClick={() => {
						openOadt(url);
					}}
				>
					{children}
				</div>
			) : (
				<a href={url} className={className}>
					{children}
				</a>
			)}
		</>
	);
};

export default OADTLink;
