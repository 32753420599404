import React from "react";
import "./checklist-tasks-table.scss";
import ChecklistTaskRow from "./checklistTaskRow/ChecklistTaskRow";
import { useWindowBreakpoints } from "../../../../hooks/useWindowBreakpoints";
import {
	useGetChecklistAssignment,
	useGetChecklistAssignmentTasks,
} from "../checklistDetail.hooks";

type Props = {
	goLiveDate: Date | null;
	checklistAssignmentId: number;
};

const ChecklistTasksTable: React.FC<Props> = ({ goLiveDate, checklistAssignmentId }) => {
	const { checklistAssignment } = useGetChecklistAssignment();
	const { checklistAssignmentTasks, groupedChecklistAssignmentTasks } =
		useGetChecklistAssignmentTasks();
	const { isWindowMobile } = useWindowBreakpoints();

	const isShared = checklistAssignment?.sharedType === "Shared";
	return (
		<div className="check-list-table-container">
			{checklistAssignmentTasks.length > 0 && (
				<>
					{Object.entries(groupedChecklistAssignmentTasks).map(([key, checklistTasks]) => (
						<React.Fragment key={key}>
							<h3>{key}</h3>
							<table className="check-list-table">
								<tbody>
									<tr className="checklist-table-row">
										<th className="checklist-table-header col-task">Task</th>
										{goLiveDate != null && (
											<th className="checklist-table-header col-due-date">Due Date</th>
										)}
										{!isWindowMobile && (
											<th className="checklist-table-header col-completed-date">Completed Date</th>
										)}
										{!isWindowMobile && isShared && (
											<th className="checklist-table-header col-completed-by">Completed By</th>
										)}
									</tr>
									{checklistTasks.map((task, i) => (
										<ChecklistTaskRow
											key={task.checklistAssignmentTaskId + "-" + i}
											task={task}
											checklistAssignmentDate={goLiveDate}
										/>
									))}
								</tbody>
							</table>
						</React.Fragment>
					))}
				</>
			)}
		</div>
	);
};

export default ChecklistTasksTable;
