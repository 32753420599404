import React, { useState, useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import ParseHTMLContent from "../../../../components/ParseHTMLContent/ParseHTMLContent";

const AnswerChoice = ({ quizClass, selection, onClick }) => {
	return (
		<a
			href="#answer"
			className={quizClass}
			onClick={(e) => {
				e.preventDefault();
				onClick();
			}}
		>
			<li>{selection}</li>
		</a>
	);
};

const Question = ({ index, qa, quizClass, exit, next }) => {
	const [selectedAnswer, setSelectedAnswer] = useState();
	const { store } = useContext(UserContext);
	const { translation } = store;
	return (
		<div>
			<div id={`question-${index}`} className="qa-container">
				{!selectedAnswer && (
					<div id="question" className={`flashcard qa question animated flipInX ${quizClass}`}>
						<div>
							<h1 className={quizClass}>
								<ParseHTMLContent htmlString={translation(qa.question, qa.questionEs)} />
							</h1>
							<ul id="answers" className="choices">
								{qa.answers.map((choice) => {
									return (
										<AnswerChoice
											key={`answerchoice_${choice.cardId}`}
											quizClass={quizClass}
											selection={translation(choice.selection, choice.selectionEs)}
											onClick={() => {
												setSelectedAnswer(choice);
											}}
										/>
									);
								})}
							</ul>
						</div>
					</div>
				)}
				{selectedAnswer && (
					<div
						id="a"
						className={`flashcard qa ${selectedAnswer.code} animated flipInY`}
						onClick={() => {
							setSelectedAnswer(null);
						}}
					>
						<div>
							<h2>
								<ParseHTMLContent
									htmlString={translation(selectedAnswer.heading, selectedAnswer.headingEs)}
								/>
							</h2>
							<p></p>
							<p>
								<ParseHTMLContent
									htmlString={translation(selectedAnswer.text, selectedAnswer.textEs)}
								/>
							</p>
							<span className="flip-back">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 33.87">
									<path
										className="cls-1"
										d="M5.89 29.26l-3.48 3.49a1.4 1.4 0 0 1-2.39-1L0 21.23a1.35 1.35 0 0 1 1.35-1.35h10.53a1.39 1.39 0 0 1 1 2.37l-3.47 3.47a11.85 11.85 0 0 0 8.08 3.17 12 12 0 0 0 11.38-8.27 1.08 1.08 0 0 1 1-.77h3.27a.81.81 0 0 1 .81 1 16.92 16.92 0 0 1-28.06 8.41zM29.11 4.62l3.48-3.5a1.4 1.4 0 0 1 2.39 1v10.55A1.36 1.36 0 0 1 33.65 14H23.12a1.4 1.4 0 0 1-1-2.38l3.47-3.49A11.85 11.85 0 0 0 17.51 5a12 12 0 0 0-11.38 8.25 1.08 1.08 0 0 1-1 .78H1.84A.82.82 0 0 1 1 13a16.91 16.91 0 0 1 28.11-8.38z"
									></path>
									<path d="M5.89 29.26l-3.48 3.49a1.4 1.4 0 0 1-2.39-1L0 21.23a1.35 1.35 0 0 1 1.35-1.35h10.53a1.39 1.39 0 0 1 1 2.37l-3.47 3.47a11.85 11.85 0 0 0 8.08 3.17 12 12 0 0 0 11.38-8.27 1.08 1.08 0 0 1 1-.77h3.27a.81.81 0 0 1 .81 1 16.92 16.92 0 0 1-28.06 8.41zM29.11 4.62l3.48-3.5a1.4 1.4 0 0 1 2.39 1v10.55A1.36 1.36 0 0 1 33.65 14H23.12a1.4 1.4 0 0 1-1-2.38l3.47-3.49A11.85 11.85 0 0 0 17.51 5a12 12 0 0 0-11.38 8.25 1.08 1.08 0 0 1-1 .78H1.84A.82.82 0 0 1 1 13a16.91 16.91 0 0 1 28.11-8.38z"></path>
								</svg>
							</span>
						</div>
					</div>
				)}
				<a
					className="exit"
					href="#exit"
					onClick={(e) => {
						e.preventDefault();
						exit();
					}}
				>
					{translation("Exit", "Salir")}
				</a>
				{selectedAnswer && (
					<a
						href="#next"
						id="next"
						className="next-question"
						onClick={(e) => {
							e.preventDefault();
							next();
							setSelectedAnswer(null);
						}}
					>
						{translation("Next Question", "Siguiente Pregunta")}
					</a>
				)}
			</div>
		</div>
	);
};

export default Question;
