import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";

import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../contexts/UserContext";
import useQueryParams from "../../../../hooks/useQueryParams";
import { isset } from "../../../../utils/utils";
import usePathsFunctions from "../../usePathsFunctions";
import GetCheckXorPer from "../../PathsCommon/GetCheckXorPer";
import useStates from "../../../../hooks/useStates";
import ShowNotification from "../../../../components/ShowNotification/ShowNotification";

const UPDATE_FAVORITE_STORE_MUTATION = gql`
	mutation MyMutation($personID: String, $storeID: String, $isFavorite: Int) {
		updateFavoriteStore(
			input: { personID: $personID, storeID: $storeID, isFavorite: $isFavorite }
		) {
			message
			didUpdate
		}
	}
`;

const LprTable = ({ tabValue, lprHeaders, lprResults, setLprResults }) => {
	const { store } = useContext(UserContext);
	const { translation, userLanguage, personID } = store;
	const query = useQueryParams();
	const module = query.get("m");
	const moduleSub = query.get("msub");
	const { getNameArray } = usePathsFunctions();
	const nameArray = getNameArray();
	const myStoreArray = lprResults.filter((el) => el.my_store === "1");
	const [filter, setFilter] = useState("");
	const [notifState, setNotifState] = useStates({
		stid: undefined,
		fav: undefined,
	});

	const [updateFavoriteStore, { loading }] = useMutation(UPDATE_FAVORITE_STORE_MUTATION);

	//Functions

	const combine_user_and_percentage = (user, per) => {
		return (
			<td className="table-td-data">
				<span className="value-per">
					<GetCheckXorPer per={per} />
				</span>
				<br />
				<span className="value-user">
					{user} {translation("user(s)", "usuario(s)")}
				</span>
			</td>
		);
	};

	const dynamicColumns = () => {
		const dynCols = [];

		lprHeaders.forEach((lprHeader) => {
			if (lprHeader !== "swr" && lprHeader !== "sup") {
				dynCols.push({
					name: (
						<>
							{translation(nameArray[lprHeader]["en"], nameArray[lprHeader]["es"])}
							{isset(nameArray[lprHeader]["accessLevel"]) && (
								<>
									<br />
									<small>{nameArray[lprHeader]["accessLevel"]}</small>
								</>
							)}
						</>
					),
					selector: (row) => row[lprHeader + "_user"],
					cell: (row) =>
						combine_user_and_percentage(row[lprHeader + "_user"], row[lprHeader + "_percentage"]),
				});
			}
		});

		return dynCols;
	};

	const rowUsersCount = (row) => {
		let allUser = 0;

		lprHeaders.forEach((lprHeader) => {
			if (lprHeader !== "swr" && lprHeader !== "sup") {
				allUser += Number(row[lprHeader + "_user"]);
			}
		});
		return allUser;
	};

	const rowPercentCount = (row) => {
		let allPer = 0;

		lprHeaders.forEach((lprHeader) => {
			if (lprHeader !== "swr" && lprHeader !== "sup") {
				allPer += Number(row[lprHeader + "_user"]) * Number(row[lprHeader + "_percentage"]);
			}
		});
		return allPer;
	};

	const rowPercentCell = (row) => {
		let allUser = rowUsersCount(row);
		let allPer = rowPercentCount(row);

		return (
			<span className="value-per">
				{allPer !== 0 && allUser !== 0 ? <GetCheckXorPer per={allPer / allUser} /> : "0"}
			</span>
		);
	};

	const toggleFavorite = (e, row) => {
		//send to server favorite
		const isFavorite = row.my_store === "0" ? 1 : 0;
		updateFavoriteStore({
			variables: {
				personID: personID,
				storeID: row.store,
				isFavorite: isFavorite,
			},
		}).then((res) => {
			//expected res
			// res.data.updateFavoriteStore
			// 		.didUpdate: "yes"
			// 		.message: "Updated Succesfully"
			const newRow = { ...row, my_store: String(isFavorite) };

			const newLprResults = lprResults.map((el) => (el.store === newRow.store ? newRow : el));

			setLprResults(newLprResults);
			setNotifState({ stid: row.store, fav: isFavorite === 1 ? "add" : "remove" });
		});
	};

	const filteredData = () => {
		//first check which side of array to filter
		let fData = tabValue === 2 ? myStoreArray : lprResults;

		if (filter && filter !== "") {
			fData = fData.filter((el) => el.store.includes(filter));
		}

		return fData;
	};

	//BUILD COLUMN PROPERTIES
	const columns = [
		{
			name: translation("Restaurant #", "# del Restaurante"),
			selector: (row) => row.store,
			cell: (row) => (
				<Link
					className="btn-org-progress"
					to={
						"?m=" +
						module +
						"&msub=" +
						moduleSub +
						"&r=org-learning-path-store&stid=" +
						row["store"] +
						"&l=" +
						userLanguage
					}
				>
					{row["store"]}
				</Link>
			),
			sortable: true,
			minWidth: "150px",
		},
		{
			name: "",
			selector: (row) => row.store,
			cell: (row) => (
				<div
					className={"anc " + (loading ? "loading " : "")}
					onClick={(e) => !loading && toggleFavorite(e, row)}
				>
					<svg
						className={"icon-fav " + (row.my_store === "1" ? " icon-yellow " : " icon-mid-grey ")}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 35 35"
					>
						<path
							className="cls-1"
							d="M17.13.87,20.8,8.74a1.49,1.49,0,0,0,1.14.85l8.34,1.29a1.5,1.5,0,0,1,.85,2.52L25,19.74a1.49,1.49,0,0,0-.4,1.29L26,29.89a1.5,1.5,0,0,1-2.21,1.55l-7.3-4.06a1.46,1.46,0,0,0-1.46,0L7.75,31.44a1.5,1.5,0,0,1-2.22-1.55L7,21a1.49,1.49,0,0,0-.4-1.29L.42,13.4a1.5,1.5,0,0,1,.85-2.52L9.61,9.59a1.49,1.49,0,0,0,1.14-.85L14.42.87A1.49,1.49,0,0,1,17.13.87Z"
						/>
						<path d="M17.13.87,20.8,8.74a1.49,1.49,0,0,0,1.14.85l8.34,1.29a1.5,1.5,0,0,1,.85,2.52L25,19.74a1.49,1.49,0,0,0-.4,1.29L26,29.89a1.5,1.5,0,0,1-2.21,1.55l-7.3-4.06a1.46,1.46,0,0,0-1.46,0L7.75,31.44a1.5,1.5,0,0,1-2.22-1.55L7,21a1.49,1.49,0,0,0-.4-1.29L.42,13.4a1.5,1.5,0,0,1,.85-2.52L9.61,9.59a1.49,1.49,0,0,0,1.14-.85L14.42.87A1.49,1.49,0,0,1,17.13.87Z" />
					</svg>
				</div>
			),
			center: true,
			minWidth: "20px",
			maxWidth: "150px",
		},
		...dynamicColumns(),
		{
			name: (
				<div
					dangerouslySetInnerHTML={{
						__html: translation("Total<br /># Users", "# de Usuarios<br /> Total"),
					}}
				/>
			),
			selector: (row) => rowUsersCount(row),
			sortable: true,
			center: true,
			minWidth: "150px",
			maxWidth: "400px",
		},
		{
			name: (
				<div
					dangerouslySetInnerHTML={{
						__html: translation("Total<br />% Complete", "% Completo <br /> Total"),
					}}
				/>
			),
			selector: (row) => rowPercentCount(row),
			cell: (row) => rowPercentCell(row),
			sortable: true,
			center: true,
			minWidth: "150px",
			maxWidth: "400px",
		},
	];

	return (
		<div>
			{notifState.stid && notifState.fav && (
				<ShowNotification stid={notifState.stid} fav={notifState.fav} />
			)}
			<div className="lpr-options filter-by-sns">
				<span>{translation("Filter by SNS", "Filtra por SNS")}:</span>
				<span>
					<input
						type="text"
						className="lpr-nsn-search-box"
						id={"col_filter" + tabValue}
						onChange={(e) => {
							setFilter(e.currentTarget.value);
						}}
					/>
					<button className="lpr-search-button">
						{" "}
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
							<path d="M35,32.31A2.73,2.73,0,0,1,32.31,35a2.49,2.49,0,0,1-1.89-.8L23.2,27a14.4,14.4,0,0,1-8.39,2.61,14.46,14.46,0,0,1-5.75-1.17,14.49,14.49,0,0,1-7.89-7.89,14.74,14.74,0,0,1,0-11.51A15,15,0,0,1,4.32,4.32,15,15,0,0,1,9.06,1.17a14.71,14.71,0,0,1,11.5,0,14.51,14.51,0,0,1,7.89,7.88,14.54,14.54,0,0,1,1.17,5.76A14.4,14.4,0,0,1,27,23.2l7.21,7.21A2.58,2.58,0,0,1,35,32.31ZM21.47,21.46a9.07,9.07,0,0,0,2.76-6.65,9.08,9.08,0,0,0-2.76-6.66,9.12,9.12,0,0,0-6.66-2.77A9.08,9.08,0,0,0,8.15,8.15a9.08,9.08,0,0,0-2.77,6.66,9.08,9.08,0,0,0,2.77,6.65,9,9,0,0,0,6.66,2.77A9.08,9.08,0,0,0,21.47,21.46Z" />
						</svg>
					</button>
				</span>
			</div>
			<DataTable columns={columns} data={filteredData()} pagination />
		</div>
	);
};

export default LprTable;
