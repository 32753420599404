import React, { useContext } from "react";
import { PathName } from "../../constants/PathName";
import { UserContext } from "../../contexts/UserContext";

const NotFound = () => {
	const { store } = useContext(UserContext);
	const { translation } = store;
	return (
		<div className="container-fluid" data-lang="<?php translation('en','es'); ?>">
			<div className="row">
				<div className="col-xs-12 main">
					{/* content goes here */}
					<div className="row padded last">
						<div className="col-xs-12">
							<p
								dangerouslySetInnerHTML={{
									__html: translation(
										'Sorry, but the page you are looking for does not exist or has moved. Please use the menu to navigate or <a href="' +
											PathName.DASHBOARD +
											'">click here</a> to go home.',
										'Lo siento, pero la p&aacute;gina que buscas no existe o ha sido movida. Usa el menu para navegar o <a href="' +
											PathName.DASHBOARD +
											'">haz click aqu&iacute;</a> para ir al inicio.',
									),
								}}
							></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
