import React, { useContext, useEffect } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { PathName } from "../constants/PathName";
import { UserContext } from "../contexts/UserContext";
import useGetCurrentUser from "../hooks/useGetCurrentUser";
import Loading from "./Loading/Loading";

export interface Props {
	exact?: boolean;
	path: string;
	render?: (routeProps: RouteComponentProps) => JSX.Element;
	component?: JSX.Element;
}

const PrivateRoute: React.FC<Props> = ({ exact, path, render, component }) => {
	const { getCurrentUser, getCurrentUserRes } = useGetCurrentUser();
	const { store: userStore } = useContext(UserContext) as any;

	useEffect(() => {
		getCurrentUser();
	}, []);

	return (
		<Route
			exact={exact}
			path={path}
			render={(routeProps) => {
				if (getCurrentUserRes.isLoading) {
					return <Loading />;
				} else if (userStore.personID) {
					return render ? render(routeProps) : component;
				} else if (getCurrentUserRes.error !== undefined) {
					return <Redirect to={PathName.LOGIN} />;
				} else {
					return <Loading />;
				}
			}}
		/>
	);
};

export default PrivateRoute;
