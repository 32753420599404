import { useLocation } from "react-router-dom";
import { PathName } from "../constants/PathName";

const useProduct = () => {
	const location = useLocation();
	if (location.pathname === PathName.DASHBOARD) {
		return "mcd-transitionkit";
	} else {
		//pathnames star with "/" so with split get second element and not anything after
		return location.pathname.split("/")[1];
	}
};

export default useProduct;
