import { gql, useQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import Loading from "../../components/Loading/Loading";
import { UserContext } from "../../contexts/UserContext";
import useProduct from "../../hooks/useProduct";
import useQueryParams from "../../hooks/useQueryParams";
import { isset, setPageTitle } from "../../utils/utils";
import ResourceDetails from "./ResourceDetails/ResourceDetails";
import ResourceItems from "./ResourceItems/ResourceItems";
import "../../styles/mcd-resources.scss";

const RESOURCES_QUERY = gql`
	query getResources(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		resourcesLibraries(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
			}
			first: 100
		) {
			nodes {
				resourceLibraryFields {
					url
					titleEs
					title
					thumbnail
					technology
					tags
					resourcetype
					product
					language
					initiative
					id
					filename
					icon
					fileextension
					fieldGroupName
					featured
					excerptEs
					excerpt
					duration
					date
					contentEs
					content
					category
				}
			}
		}
		dashboardModules(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
			}
			first: 100
		) {
			nodes {
				dashboardModulesFields {
					url
					titleEs
					title
					product
					id
					fieldGroupName
					contentEs
					content
					allowedeids
				}
			}
		}
	}
`;

const Resources: React.FC = () => {
	// state hooks
	const query = useQueryParams();
	const { store }: any = useContext(UserContext);
	const { permissionGroup, userData } = store;

	// recoils

	// custom hooks
	const product = useProduct();
	const { loading, error, data } = useQuery(RESOURCES_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	// local variables
	const resourceID = query.get("resourceID");

	// functions

	// React hooks
	useEffect(() => {
		setPageTitle(product);
	}, []);

	// jsx variables

	if (loading) return <Loading />;
	if (error) return <p>Unable to load Resources :(</p>;

	const dashboardModules = data.dashboardModules.nodes || [];
	const resourcesLibraries = data.resourcesLibraries.nodes || [];

	return (
		<div>
			{!isset(resourceID) || resourceID === "" || resourceID === null ? (
				<ResourceItems dashboardModules={dashboardModules} resourceLibrary={resourcesLibraries} />
			) : (
				<ResourceDetails resourceID={resourceID} resourceLibrary={resourcesLibraries} />
			)}
		</div>
	);
};

export default Resources;
