import React from "react";
import { useState } from "react";
import "./healthChecksArea.scss";
import { useRecoilState } from "recoil";
import { healthCheckFilterValueAtom } from "./healthChecksArea.hooks";
export const HealthChecksArea = () => {
	const [showFeatureText, setShowFeatureText] = useState(false);
	const [isFilterBoxClicked, setIsFilterBoxClicked] = useState(false);
	const [filterValue, setFilterValueAtom] = useRecoilState(healthCheckFilterValueAtom);

	const handleButtonClick = () => {
		if (!showFeatureText) {
			window.location.href = "mcd-forms/?m=fsl&form=2&submission=2";
		} else {
			setShowFeatureText(true);
		}
	};
	const handleFilterBoxClick = () => {
		setIsFilterBoxClicked(!isFilterBoxClicked);
	};
	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterValueAtom(e.target.value);
	};

	return (
		<div className="healthChecksArea">
			<div className="healthChecks-header">Health Checks</div>
			<div className="healthChecks-completed-section">
				<div className="healthChecks-actions">
					<input
						type="text"
						className={`healthChecks-filter-box ${isFilterBoxClicked ? "clicked" : ""}`}
						placeholder="Filter by NSN"
						value={filterValue}
						onClick={handleFilterBoxClick}
						onChange={handleFilterChange}
					/>
					<button
						className={`healthChecks-review-update-button ${
							showFeatureText ? "feature-text-show" : ""
						}`}
						onClick={handleButtonClick}
						disabled={showFeatureText}
					>
						{showFeatureText ? "Feature Coming Soon!" : "New Health Check"}
					</button>
				</div>
			</div>
		</div>
	);
};
