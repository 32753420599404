import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PathName } from "../../../../../../constants/PathName";
import { UserContext } from "../../../../../../contexts/UserContext";
import { strtolower, str_replace } from "../../../../../../utils/utils";

const LpTask = ({ task, taskCompleted, updateTask, utsmLoading }) => {
	const { store } = useContext(UserContext);
	const { translation, userLanguage } = store;

	return (
		<li className={taskCompleted + "-task"} key={task["id"]}>
			<div className="row">
				<div className="col-xs-8">
					<Link
						to={(() => {
							if (task["product"] === "mcd-demo") {
								//create subdirectory name based on english demo title
								let $subdir = str_replace(" ", "-", task["title"]);
								$subdir = str_replace("(", "", $subdir);
								$subdir = str_replace(")", "", $subdir);
								$subdir = strtolower($subdir);

								return (
									PathName.DASHBOARD +
									task["product"] +
									"/html/" +
									$subdir +
									"/" +
									task["id"] +
									"-" +
									userLanguage +
									"-01.html#ref=" +
									PathName.DASHBOARD +
									"mcd-paths/"
								);
								// } else if (tasks['product'] === 'mcd-transitionkit' ) {
								//     echo PathName.DASHBOARD + tasks['product'] + '/';
								//     translation(tasks['page_url'],tasks['page_url_es']);
								//     echo '#ref=' + PathName.DASHBOARD;
							} else {
								return (
									PathName.DASHBOARD +
									task["product"] +
									"/" +
									task["pageUrl"] +
									"&l=" +
									userLanguage +
									"&ref=" +
									PathName.DASHBOARD +
									"mcd-paths/"
								);
							}
						})()}
					>
						<strong>
							{task["type"] === "do" && <>{translation("DO", "HACER")}</>}
							{task["type"] === "read" && <>{translation("READ", "LEER")}</>}
							{task["type"] === "browse" && <>{translation("BROWSE", "EXPLORAR")}</>}
							{task["type"] === "test" && <>{translation("REINFORCE", "REFORZAR")}</>} :
						</strong>{" "}
						{translation(task["title"], task["titleEs"])}
					</Link>
				</div>
				<div className="col-xs-4 text-right">
					<div className={"mark-complete " + taskCompleted}>
						<div
							className={"anc " + (utsmLoading && "disabled")}
							onClick={() => updateTask(task, 1)}
						>
							{translation("mark complete", "márcarlo completo")}
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};

export default LpTask;
