import React, { useCallback, useContext } from "react";
import { ChecklistAssignmentTaskTableRowFragment } from "../../../../../generated/graphql";
import { FaExclamation, FaStickyNote } from "react-icons/fa";
import { TbCalendarFilled } from "react-icons/tb";

import { MdCheckCircle } from "react-icons/md";
import { UserContext, UserContextType } from "../../../../../contexts/UserContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { GiCircle } from "react-icons/gi";
import { formatDateTimeYYYY_MM_DD_HH_MM_SS } from "../../../../../utils/utils-typescript";
import { ChecklistTaskModal } from "../checklistTaskModal/ChecklistTaskModal";
import { useWindowBreakpoints } from "../../../../../hooks/useWindowBreakpoints";
import {
	useChecklistAssignmentTaskParsed,
	useFetchChecklistAssignmentTasks,
	useGetChecklistAssignment,
	useUpsertChecklistAssignmentTask,
} from "../../checklistDetail.hooks";
import Loading from "../../../../../components/Loading/Loading";
import ParseHTMLContent from "../../../../../components/ParseHTMLContent/ParseHTMLContent";
import { useModals } from "../../../../../components/modal/modals.recoil";
import { ChecklistSharedType, ChecklistTaskType } from "../../../checklistTypes";

interface Props {
	task: ChecklistAssignmentTaskTableRowFragment;
	checklistAssignmentDate: Date | null;
}

const ChecklistTaskRow: React.FC<Props> = ({ task, checklistAssignmentDate }) => {
	// USER
	const { store } = useContext<UserContextType>(UserContext);
	const { translation, personID } = store;

	// custom hooks
	const { isWindowMobile } = useWindowBreakpoints();
	const { checklistAssignment } = useGetChecklistAssignment(); // @TODO, move to props, simplify this component
	const { pushModal } = useModals();
	const { fetchChecklistAssignmentTasks, isLoading } = useFetchChecklistAssignmentTasks();
	const { upsertChecklistAssignmentTask, isLoading: ucatLoading } =
		useUpsertChecklistAssignmentTask();

	const isUpdating = ucatLoading || isLoading;
	const isShared = checklistAssignment?.sharedType === ChecklistSharedType.Shared;

	// Memos
	const taskParsed = useChecklistAssignmentTaskParsed(task, checklistAssignmentDate);

	// Callbacks
	const handleCheckmarkToggle = useCallback(
		async (item: ChecklistAssignmentTaskTableRowFragment) => {
			let finishedAt = null;
			let finishedBy = null;
			if (item.finishedAt == null || item.finishedAt === "") {
				finishedAt = formatDateTimeYYYY_MM_DD_HH_MM_SS(new Date());
				finishedBy = personID;
			}
			await upsertChecklistAssignmentTask({
				id: item.checklistAssignmentTaskId,
				taskId: parseInt(item.taskId!),
				finishedAt,
				finishedBy,
				notes: item.notes,
			});
			await fetchChecklistAssignmentTasks();
		},
		[fetchChecklistAssignmentTasks, personID, upsertChecklistAssignmentTask],
	);

	const renderChecklistTaskModal = useCallback(() => {
		pushModal({
			render: <ChecklistTaskModal checklistAssignmentTask={task} date={checklistAssignmentDate} />,
		});
	}, [checklistAssignmentDate, task, pushModal]);

	return (
		<tr className="checklist-row">
			{isLoading && <Loading />}
			<td className="col-task">
				<div className="checklist-table-element">
					{/* circle loaders */}
					{taskParsed.isTaskCompleted && isUpdating && (
						<AiOutlineLoading3Quarters className="checklist-check-icon spin gray" />
					)}
					{!taskParsed.isTaskCompleted && isUpdating && (
						<AiOutlineLoading3Quarters className="checklist-check-icon spin yellow" />
					)}

					{/* status icons */}
					{taskParsed.isTaskCompleted && !isUpdating && (
						<MdCheckCircle
							className="checklist-check-icon yellow"
							onClick={() => handleCheckmarkToggle(task)}
						/>
					)}
					{!taskParsed.isTaskCompleted && !isUpdating && (
						<GiCircle
							className="checklist-check-icon gray"
							onClick={() => handleCheckmarkToggle(task)}
						/>
					)}
					<label
						className={"task-label " + (task.taskIsCritical ? " critical " : "")}
						htmlFor={`checkbox-${task.checklistAssignmentTaskId}`}
					>
						<div className="anc" onClick={() => renderChecklistTaskModal()}>
							{task.taskIsCritical && (
								<div className="is-critical-icon">
									<FaExclamation className="task-icon" />
								</div>
							)}
							{task.taskType === ChecklistTaskType.Event && (
								<div className="is-event-icon">
									<TbCalendarFilled className="task-icon" />
								</div>
							)}
							<ParseHTMLContent htmlString={translation(task.taskTitle, task.taskTitleEs)} />{" "}
							{task.notes != null && <FaStickyNote className="task-icon" />}
						</div>
					</label>
				</div>
			</td>
			{checklistAssignmentDate != null && (
				<td className="col-due-date">
					{taskParsed.shouldShowActualDueDate ? (
						<div className="checklist-table-element checklist-actual-due-date-container">
							{taskParsed.taskActualDueDateString} {taskParsed.taskActualDueDateTime}{" "}
							{taskParsed.taskActualDueDateTimezone}
						</div>
					) : (
						<div className="checklist-table-element checklist-due-date-container">
							{!taskParsed.isTaskCompleted && (
								<div className="checklist-circle-container">
									<div
										className="checklist-circle checklist-due-date-container-items"
										style={{
											backgroundColor: taskParsed.countdownToDateColor,
										}}
									></div>
								</div>
							)}
							<div className="checklist-due-date-container-items">
								{taskParsed.countdownToDateMessage}
							</div>
						</div>
					)}
				</td>
			)}
			{!isWindowMobile && (
				<td className="col-completed-date">{taskParsed.completedAtDateString}</td>
			)}
			{!isWindowMobile && isShared && (
				<td className="col-completed-by">{task.finishedByRealName}</td>
			)}
		</tr>
	);
};

export default ChecklistTaskRow;
