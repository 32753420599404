const DEFAULT_LANG = "en";

export const translate = (lang: string) => (content: any, key: any) => {
	if (content[key] && content[key][lang]) {
		return content[key][lang];
	} else if (content[key] && content[key][DEFAULT_LANG]) {
		return content[key][DEFAULT_LANG];
	}
	return "";
};

export const translation =
	(lang: string) =>
	(english?: string | null, spanish?: string | null): string => {
		if (lang === "en") {
			return english || "";
		} else if (lang === "es") {
			if (!spanish || spanish === "-" || spanish === "--") {
				return "<em>No disponible en Español</em>";
			}
			return spanish || "";
		}
		return "";
	};

const UserTranslationService = (userLanguage: string) => {
	return {
		userTranslate: translate(userLanguage),
		userTranslation: translation(userLanguage),
	};
};

export default UserTranslationService;
