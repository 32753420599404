import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Loading from "../../../components/Loading/Loading";
import { printRenderAtom } from "../../../contexts/print.recoil";
import { UserContext } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";
import { in_array, isset } from "../../../utils/utils";
import usePathsFunctions from "../usePathsFunctions";
import LprStoreData from "./components/LprStoreData";
import LprStoreHeaders from "./components/LprStoreHeaders";

const LPR_STORE_VALUES_QUERY = gql`
	query GetLPTabs($personID: String, $storeID: String) {
		getLPRStoreValues(personID: $personID, storeID: $storeID) {
			lprHeaders
			lprHeadersUserCount {
				role_code
				user_count
			}
			lprStoreResults {
				badge
				boa_percentage
				boa_user
				code
				l1_percentage
				l1_user
				l3_percentage
				l2_user
				l2_percentage
				l3_user
				l4_percentage
				l4_user
				l5_percentage
				op_percentage
				l5_user
				op_user
				swr_percentage
				sup_user
				sup_percentage
				swr_user
				title
				title_es
			}
		}
	}
`;

const LearningPathStore = ({ grantLPR }) => {
	//state hooks

	//recoils / context
	const { store } = useContext(UserContext);
	const { translation, permissionGroup, userLanguage, personId } = store;
	const setPrintRender = useSetRecoilState(printRenderAtom);

	//custom hooks
	const query = useQueryParams();
	const { db_count_org_users_store_accessed, db_count_org_task_store_completion } =
		usePathsFunctions();

	//local variables
	const module = query.get("m");
	const moduleSub = query.get("msub");
	const r = query.get("r");
	const storeId = query.get("stid");
	const printRef1 = useRef();
	const printRef2 = useRef();

	//functions
	const printCurrentPage = () => {
		setPrintRender(
			<div className="dt-print-view">
				<h1 className="page-header">
					{translation("Learning Path Progress", "Progreso de Rutas de Aprendizaje")}
				</h1>
				<div
					dangerouslySetInnerHTML={{
						__html: printRef1.current.innerHTML + printRef2.current.innerHTML,
					}}
				/>
			</div>,
		);
	};

	//React hooks
	const { loading, error, data } = useQuery(LPR_STORE_VALUES_QUERY, {
		variables: {
			personId,
			storeId,
		},
	});

	//jsx variables
	if (loading) return <Loading />;
	if (error) return <p>Unable to find lpr store values :(</p>;

	const lprHeaders = data.getLPRStoreValues.lprHeaders || [];
	const lprHeadersUserCount = data.getLPRStoreValues.lprHeadersUserCount || [];
	const lprStoreResults = data.getLPRStoreValues.lprStoreResults || [];

	return (
		<div>
			{/* Show the Learning Path progress report for a restaurant */}
			<div className="row lpr-page">
				<div className="col-xs-12">
					<h2 className="page-header">
						{translation("Learning Path Progress", "Progreso de Rutas de Aprendizaje")}
					</h2>
					<Link
						className="btn-org-progress"
						to={
							"?m=" +
							module +
							"&msub=" +
							moduleSub +
							"&r=org-learning-path-progress&l=" +
							userLanguage +
							""
						}
					>
						{translation("Summary", "Resumen")}
					</Link>
					{">"} {translation("Restaurant #", "Restaurante #") + " " + storeId}
					{in_array(permissionGroup.accessLevel, grantLPR) &&
						in_array(permissionGroup.sso_companyType, grantLPR) &&
						in_array(module, grantLPR) && (
							<ul className="nav nav-tabs navbar-right lpr-tabs">
								<li className={isset(r) === "" || r === "" ? "active" : ""}>
									<Link to={"?m=" + module + "&msub=" + moduleSub + "&r=&l=" + userLanguage + ""}>
										{translation("My Learning Path", "Mi Ruta de Aprendizaje")}
									</Link>
								</li>
								<li className={r !== "" || r !== "" ? "active" : ""}>
									<Link
										to={
											"?m=" +
											module +
											"&msub=" +
											moduleSub +
											"&r=org-learning-path-progress&l=" +
											userLanguage +
											""
										}
									>
										{translation("Learning Path Progress", "Progreso de Rutas de Aprendizaje")}
									</Link>
								</li>
							</ul>
						)}
				</div>

				<div className="col-xs-12">
					<div className="lpr-options">
						<span>
							<button className="btn lpr-print-button" onClick={() => printCurrentPage()}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 34.8">
									<path d="M20.7,1.75l4.8,4.81V14.7H9.5V1.75H20.7M21.52,0H7.5V16.44h20V6l-6-6Z" />
									<path d="M25.5,27.38V32.8H9.5V27.38m18-2H7.5V34.8h20V25.38Z" />
									<polygon points="27.65 7.97 19.7 7.97 19.67 0 21.67 0 21.7 5.97 27.65 6 27.65 7.97" />
									<path d="M31,13.58H27.5v1.86H7.5V13.58H4a4,4,0,0,0-4,4v11H7.5v-2h20v2H35v-11A4,4,0,0,0,31,13.58Zm-.44,6.86a2,2,0,1,1,2-2A2,2,0,0,1,30.56,20.44Z" />
									<rect x="11.5" y="29.59" width="12" height="1" />
									<path d="M20.7,1.75l4.8,4.81V14.7H9.5V1.75H20.7M21.52,0H7.5V16.44h20V6l-6-6Z" />
									<path d="M25.5,27.38V32.8H9.5V27.38m18-2H7.5V34.8h20V25.38Z" />
									<polygon points="27.65 7.97 19.7 7.97 19.67 0 21.67 0 21.7 5.97 27.65 6 27.65 7.97" />
									<path d="M31,13.58H27.5v1.86H7.5V13.58H4a4,4,0,0,0-4,4v11H7.5v-2h20v2H35v-11A4,4,0,0,0,31,13.58Zm-.44,6.86a2,2,0,1,1,2-2A2,2,0,0,1,30.56,20.44Z" />
									<rect x="11.5" y="29.59" width="12" height="1" />
								</svg>
								{translation("Print Page", "Imprimir Página")}
							</button>
						</span>
					</div>
				</div>
				<div className="col-xs-12 add-to-print" ref={printRef1}>
					<h2>{translation("Restaurant #", "Restaurante #") + " " + storeId}</h2>
					<div className="row">
						<div className="col-xs-12 col-sm-6 ">
							<div className="card">
								<h3 className="heading"># Managers Who Accessed MCD T-Kit</h3>
								<div className="data">
									{db_count_org_users_store_accessed(lprHeaders, lprHeadersUserCount)}
								</div>
							</div>
						</div>
						<div className="col-xs-12 col-sm-6">
							<div className="card">
								<h3 className="heading">% Complete</h3>
								<div className="data">
									{db_count_org_task_store_completion(lprHeaders, lprStoreResults)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xs-12" ref={printRef2}>
					<table id="tableMacStoreReport" className="display store-table nowrap">
						<thead>
							<LprStoreHeaders
								lprHeaders={lprHeaders}
								lprHeadersUserCount={lprHeadersUserCount}
								lprStoreResults={lprStoreResults}
							/>
						</thead>
						<tbody>
							<LprStoreData
								lprHeaders={lprHeaders}
								lprHeadersUserCount={lprHeadersUserCount}
								lprStoreResults={lprStoreResults}
							/>{" "}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default LearningPathStore;
