import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import {
	ChecklistAssignmentTableRowFragment,
	ChecklistAssignmentTaskTableRowFragment,
	UpsertChecklistAssignmentTaskInput,
	useGetChecklistAssignmentTasksLazyQuery,
	useGetChecklistAssignmentsLazyQuery,
	useUpsertChecklistAssignmentTaskMutation,
} from "../../../generated/graphql";
import { useParams } from "react-router";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { UserContext, UserContextType } from "../../../contexts/UserContext";
import {
	formatDate_MM_DD_YYYY,
	formatDateFromYYYYMMDD,
	parseTimezone,
} from "../../../utils/utils-typescript";
import { countdownToDate } from "./checklistTasksTable/ChecklistTasksTable.service";
import { ChecklistTaskDateType, ChecklistTaskType } from "../checklistTypes";

export type ChecklistViewStateType = "current" | "completed" | "all";

const _checklistAssignmentAtom = atom<ChecklistAssignmentTableRowFragment | undefined>({
	key: "checklistAssignment",
	default: undefined,
});

const _checklistAssignmentTasksAtom = atom<ChecklistAssignmentTaskTableRowFragment[]>({
	key: "checklistAssignmentTasks",
	default: [],
});

const _checklistViewStateAtom = atom<ChecklistViewStateType>({
	key: "checklistViewState",
	default: "all",
});

const _checklistAssignmentTasksReloaderAtom = atom<boolean>({
	key: "checklistAssignmentTasksReloader",
	default: false,
});

const _checklistAssignmentTasksLoadingAtom = atom<boolean>({
	key: "checklistAssignmentTasksLoading",
	default: false,
});

// GETTER SETTERS
export const useGetChecklistAssignment = () => {
	const checklistAssignment = useRecoilValue(_checklistAssignmentAtom);

	return { checklistAssignment };
};

export const useSetChecklistAssignment = () => {
	const setChecklistAssignment = useSetRecoilState(_checklistAssignmentAtom);

	return { setChecklistAssignment };
};

export const useGetChecklistAssignmentTasks = () => {
	const checklistAssignmentTasks = useRecoilValue(_checklistAssignmentTasksAtom);
	const { checklistViewState } = useGetChecklistViewState();

	const groupedChecklistAssignmentTasks = useMemo(() => {
		const grouped: { [key: string]: ChecklistAssignmentTaskTableRowFragment[] } = {};

		checklistAssignmentTasks.forEach((task) => {
			const taskGroup = task.taskGroup || "";
			const isComplete = task.checklistAssignmentTaskId != null;

			if (checklistViewState === "completed" && !isComplete) {
				return;
			}
			if (checklistViewState === "current" && isComplete) {
				return;
			}

			if (grouped[taskGroup]) {
				grouped[taskGroup].push(task);
			} else {
				grouped[taskGroup] = [task];
			}
		});

		return grouped;
	}, [checklistAssignmentTasks, checklistViewState]);

	return { checklistAssignmentTasks, groupedChecklistAssignmentTasks };
};

export const useSetChecklistAssignmentTasks = () => {
	const setChecklistAssignmentTasks = useSetRecoilState(_checklistAssignmentTasksAtom);

	return { setChecklistAssignmentTasks };
};

export const useGetChecklistViewState = () => {
	const checklistViewState = useRecoilValue(_checklistViewStateAtom);

	return { checklistViewState };
};

export const useSetChecklistViewState = () => {
	const setChecklistViewState = useSetRecoilState(_checklistViewStateAtom);

	return { setChecklistViewState };
};

export const useGetChecklistAssignmentTasksReloader = () => {
	const checklistAssignmentTasksReloader = useRecoilValue(_checklistAssignmentTasksReloaderAtom);

	return { checklistAssignmentTasksReloader };
};

export const useSetChecklistAssignmentTasksReloader = () => {
	const setChecklistAssignmentTasksReloader = useSetRecoilState(
		_checklistAssignmentTasksReloaderAtom,
	);

	return { setChecklistAssignmentTasksReloader };
};

export const useGetChecklistAssignmentTasksLoading = () => {
	const checklistAssignmentTasksLoading = useRecoilValue(_checklistAssignmentTasksLoadingAtom);

	return { checklistAssignmentTasksLoading };
};

export const useSetChecklistAssignmentTasksLoading = () => {
	const setChecklistAssignmentTasksLoading = useSetRecoilState(
		_checklistAssignmentTasksLoadingAtom,
	);

	return { setChecklistAssignmentTasksLoading };
};

// FUNCTIONS
const _fetchChecklistAssignment = () => {
	const { checklistAssignmentId } = useParams<{ checklistAssignmentId: string }>();

	const { setChecklistAssignment } = useSetChecklistAssignment();

	const [getChecklistAssignment, getChecklistAssignmentRes] = useGetChecklistAssignmentsLazyQuery();

	useEffect(() => {
		return () => {
			setChecklistAssignment(undefined);
		};
	}, [setChecklistAssignment]);

	useEffect(() => {
		getChecklistAssignment({
			variables: {
				where: {
					checklistAssignmentId: Number(checklistAssignmentId),
				},
			},
		});
	}, [checklistAssignmentId, getChecklistAssignment]);

	useEffect(() => {
		if (
			getChecklistAssignmentRes.called &&
			!getChecklistAssignmentRes.loading &&
			getChecklistAssignmentRes.data &&
			getChecklistAssignmentRes.data?.getChecklistAssignments?.rows &&
			getChecklistAssignmentRes.data?.getChecklistAssignments?.rows.length > 0
		) {
			setChecklistAssignment(getChecklistAssignmentRes.data.getChecklistAssignments.rows[0]!);
		}
	}, [
		getChecklistAssignmentRes.called,
		getChecklistAssignmentRes.data,
		getChecklistAssignmentRes.loading,
		setChecklistAssignment,
	]);

	const isLoading = getChecklistAssignmentRes.loading;

	return {
		isLoading,
	};
};

const _fetchChecklistAssignmentTasks = () => {
	const { checklistAssignment } = useGetChecklistAssignment();
	const { store } = useContext<UserContextType>(UserContext);
	const {
		personID,
		permissionGroup: { accessLevel, companyType, customRole },
	} = store;

	const { checklistAssignmentTasksReloader } = useGetChecklistAssignmentTasksReloader();
	const { setChecklistAssignmentTasks } = useSetChecklistAssignmentTasks();
	const { setChecklistAssignmentTasksLoading } = useSetChecklistAssignmentTasksLoading();

	const [getChecklistAssignmentTasks, getChecklistAssignmentTasksRes] =
		useGetChecklistAssignmentTasksLazyQuery();

	useEffect(() => {
		return () => {
			setChecklistAssignmentTasks([]);
		};
	}, [setChecklistAssignmentTasks]);

	useEffect(() => {
		if (checklistAssignment?.checklistId == null) {
			return;
		}
		getChecklistAssignmentTasks({
			variables: {
				where: {
					checklistId: checklistAssignment.checklistId,
					initiativeSlug: checklistAssignment.initiative,
					checklistAssignmentId:
						checklistAssignment.type === "Store" || checklistAssignment.sharedType === "Individual"
							? checklistAssignment.checklistAssignmentId
							: undefined,
					acceptAccessRole: accessLevel,
					acceptCompanyType: companyType,
					acceptCustomRoles: customRole,
					userPersonId: checklistAssignment.sharedType === "Individual" ? personID : undefined,
				},
			},
		});
	}, [
		checklistAssignment?.checklistAssignmentId,
		checklistAssignment?.checklistId,
		checklistAssignment?.initiative,
		checklistAssignment?.sharedType,
		checklistAssignment?.type,
		getChecklistAssignmentTasks,
		personID,
		checklistAssignmentTasksReloader,
		accessLevel,
		companyType,
		customRole,
	]);

	useEffect(() => {
		if (
			getChecklistAssignmentTasksRes.called &&
			!getChecklistAssignmentTasksRes.loading &&
			getChecklistAssignmentTasksRes.data &&
			getChecklistAssignmentTasksRes.data?.getChecklistAssignmentTasks?.rows &&
			getChecklistAssignmentTasksRes.data?.getChecklistAssignmentTasks?.rows.length > 0
		) {
			setChecklistAssignmentTasks(
				getChecklistAssignmentTasksRes.data?.getChecklistAssignmentTasks
					.rows as ChecklistAssignmentTaskTableRowFragment[],
			);
		}
	}, [
		getChecklistAssignmentTasksRes.called,
		getChecklistAssignmentTasksRes.data,
		getChecklistAssignmentTasksRes.loading,
		setChecklistAssignmentTasks,
	]);

	useEffect(() => {
		if (getChecklistAssignmentTasksRes.loading) {
			setChecklistAssignmentTasksLoading(true);
		} else {
			setChecklistAssignmentTasksLoading(false);
		}
	}, [getChecklistAssignmentTasksRes.loading, setChecklistAssignmentTasksLoading]);

	const isLoading = getChecklistAssignmentTasksRes.loading;

	return {
		isLoading,
	};
};

export const useFetchChecklistAssignmentTasks = () => {
	const { checklistAssignment } = useGetChecklistAssignment();
	const { store } = useContext<UserContextType>(UserContext);
	const {
		personID,
		permissionGroup: { accessLevel, companyType, customRole },
	} = store;

	const { setChecklistAssignmentTasks } = useSetChecklistAssignmentTasks();

	const [getChecklistAssignmentTasks, getChecklistAssignmentTasksRes] =
		useGetChecklistAssignmentTasksLazyQuery();

	const fetchChecklistAssignmentTasks = useCallback(async () => {
		if (checklistAssignment?.checklistId == null) {
			return;
		}
		await getChecklistAssignmentTasks({
			variables: {
				where: {
					checklistId: checklistAssignment.checklistId,
					initiativeSlug: checklistAssignment.initiative,
					checklistAssignmentId:
						checklistAssignment.type === "Store" || checklistAssignment.sharedType === "Individual"
							? checklistAssignment.checklistAssignmentId
							: undefined,
					acceptAccessRole: accessLevel,
					acceptCompanyType: companyType,
					acceptCustomRoles: customRole,
					userPersonId: checklistAssignment.sharedType === "Individual" ? personID : undefined,
				},
			},
		});
	}, [
		checklistAssignment?.checklistAssignmentId,
		checklistAssignment?.checklistId,
		checklistAssignment?.initiative,
		checklistAssignment?.sharedType,
		checklistAssignment?.type,
		accessLevel,
		companyType,
		customRole,
		getChecklistAssignmentTasks,
		personID,
	]);

	useEffect(() => {
		if (
			getChecklistAssignmentTasksRes.called &&
			!getChecklistAssignmentTasksRes.loading &&
			getChecklistAssignmentTasksRes.data &&
			getChecklistAssignmentTasksRes.data?.getChecklistAssignmentTasks?.rows &&
			getChecklistAssignmentTasksRes.data?.getChecklistAssignmentTasks?.rows.length > 0
		) {
			setChecklistAssignmentTasks(
				getChecklistAssignmentTasksRes.data?.getChecklistAssignmentTasks
					.rows as ChecklistAssignmentTaskTableRowFragment[],
			);
		}
	}, [
		getChecklistAssignmentTasksRes.called,
		getChecklistAssignmentTasksRes.data,
		getChecklistAssignmentTasksRes.loading,
		setChecklistAssignmentTasks,
	]);

	const isLoading = getChecklistAssignmentTasksRes.loading;

	return {
		fetchChecklistAssignmentTasks,
		isLoading,
	};
};

export const useUpsertChecklistAssignmentTask = () => {
	const [_upsertChecklistAssignmentTask, upsertChecklistAssignmentTaskRes] =
		useUpsertChecklistAssignmentTaskMutation();

	const { checklistAssignment } = useGetChecklistAssignment();
	const { store } = useContext<UserContextType>(UserContext);
	const { personID } = store;

	const upsertChecklistAssignmentTask = useCallback(
		async (input: Partial<UpsertChecklistAssignmentTaskInput>) => {
			try {
				if (!checklistAssignment || !input.taskId) {
					return;
				}
				await _upsertChecklistAssignmentTask({
					variables: {
						input: {
							id: input.id,

							checklistId: checklistAssignment.checklistId!,
							initiative: checklistAssignment.initiative!,
							checklistAssignmentId: checklistAssignment.checklistAssignmentId!,
							personId: personID,
							taskId: input.taskId,

							finishedAt: input.finishedAt,
							finishedBy: input.finishedBy,
							notes: input.notes,
						},
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
		[_upsertChecklistAssignmentTask, checklistAssignment, personID],
	);

	const isLoading = upsertChecklistAssignmentTaskRes.loading;

	return {
		upsertChecklistAssignmentTask,
		isLoading,
	};
};

export const useChecklistAssignmentTaskParsed = (
	task: ChecklistAssignmentTaskTableRowFragment,
	checklistAssignmentDate: Date | null,
) => {
	const taskParsed = useMemo(() => {
		// For actual date
		const taskActualDueDate =
			task.taskActualDueDate != null && task.taskActualDueDate !== ""
				? formatDateFromYYYYMMDD(task.taskActualDueDate).date
				: null;
		const taskActualDueDateString =
			task.taskActualDueDate != null && task.taskActualDueDate !== ""
				? formatDateFromYYYYMMDD(task.taskActualDueDate).dateString
				: null;
		const taskActualDueDateTime = task.taskActualDueDateTime ?? "";
		const taskActualDueDateTimezone = parseTimezone(task.taskActualDueDateTimezone ?? "");

		// For countdown to date
		const { message, color } = countdownToDate(
			taskActualDueDate || checklistAssignmentDate,
			task.taskDueDateDays ?? 0,
			task.finishedAt != null && task.finishedAt !== "",
		);

		const completedAtDateString =
			task.finishedAt != null ? formatDate_MM_DD_YYYY(new Date(task.finishedAt)) : "";

		// conditions
		const isTaskCompleted = task.finishedAt != null && task.finishedAt !== "";
		const shouldShowActualDueDate =
			task.taskType === ChecklistTaskType.Event &&
			task.taskDaysOrActualDate === ChecklistTaskDateType.ActualDueDate;

		return {
			taskActualDueDate,
			taskActualDueDateString,
			taskActualDueDateTime,
			taskActualDueDateTimezone,
			countdownToDateMessage: message,
			countdownToDateColor: color,
			completedAtDateString,
			isTaskCompleted,
			shouldShowActualDueDate,
		};
	}, [checklistAssignmentDate, task]);

	return taskParsed;
};

// MAIN SERVICE
export const useChecklistDetailService = () => {
	const { isLoading: caLoading } = _fetchChecklistAssignment();
	const { isLoading: catLoading } = _fetchChecklistAssignmentTasks();

	const isLoading = caLoading || catLoading;

	return { isLoading };
};
