const usePathsFunctions = () => {
	const nameArray = {
		op: {
			title: "Owner/Operator",
			title_es: "Dueño/Operdador",
		},
		boa: {
			en: "Back Office Admin",
			es: "Administrador de la Trastienda",
		},
		swr: {
			en: "Supervisor",
			es: "Supervisor",
		},
		sup: {
			en: "Mid Manager",
			es: "Gerente Intermedio",
		},
		l1: {
			en: "General Manager",
			es: "Gerente General",
			accessLevel: "Level 1",
		},
		l2: {
			en: "Kitchen Manager",
			es: "Gerente de Cocina",
			accessLevel: "Level 2",
		},
		l3: {
			en: "People Manager",
			es: "Gerente de Personal",
			accessLevel: "Level 3",
		},
		l4: {
			en: "Guest Services Manager",
			es: "Gerente de Servicio al Cliente",
			accessLevel: "Level 4",
		},
		l5: {
			en: "Shift Manager",
			es: "Gerente de Turno",
			accessLevel: "Level 5",
		},
		l6: {
			en: "Manager Trainee",
			es: "Aprendiz de Gerente",
			accessLevel: "Level 6",
		},
	};

	function tran_percentage($per) {
		if (isNaN($per)) {
			return "0.0";
		} else {
			return Number($per).toFixed(1);
		}
	}

	function db_count_org_stores(tabValue, $lpr_results) {
		//1 = all 2= my store)
		if (tabValue === 2) {
			const filtered_array = $lpr_results.filter((lprResult) => {
				return lprResult["my_store"] === "1";
			});
			return filtered_array.length;
		} else {
			return $lpr_results.length;
		}
	}

	function db_count_org_users_accessed(tabValue, $lpr_results) {
		//1 = all 2= my store) {

		if (tabValue === 2) {
			const filteredArray = $lpr_results.filter((lprResult) => {
				return lprResult["my_store"] === "1";
			});
			let userCount = 0;
			filteredArray.forEach((value) => {
				userCount += Number(value["tot_user"]);
			});
			return userCount;
		} else {
			let userCount = 0;
			$lpr_results.forEach((value) => {
				userCount += Number(value["tot_user"]);
			});
			return userCount;
		}
	}

	function db_count_org_task_completion(tabValue, $lpr_results) {
		//1 = all 2= my store) {

		if (tabValue === 2) {
			const filteredArray = $lpr_results.filter((lprResult) => {
				return lprResult["my_store"] === "1";
			});
			let count_per = 0;
			filteredArray.forEach((value) => {
				count_per += Number(value["tot_percentage"]);
			});
			return tran_percentage(count_per / filteredArray.length) + "%";
		} else {
			let count_per = 0;
			$lpr_results.forEach((value) => {
				count_per += Number(value["tot_percentage"]);
			});
			return tran_percentage(count_per / $lpr_results.length) + "%";
		}
	}

	function db_count_org_mid_managers_accessed(tabValue, $lpr_results) {
		//1 = all 2= my store) {

		if (tabValue === 2) {
			const filteredArray = $lpr_results.filter((lprResult) => {
				return lprResult["my_store"] === "1";
			});
			let count_user = 0;
			filteredArray.forEach((value) => {
				count_user += Number(value["swr_user"]);
				count_user += Number(value["sup_user"]);
			});
			return count_user;
		} else {
			let count_user = 0;
			$lpr_results.forEach((value) => {
				count_user += Number(value["swr_user"]);
				count_user += Number(value["sup_user"]);
			});
			return count_user;
		}
	}

	function db_count_org_mid_managers_completion(tabValue, $lpr_results) {
		//1 = all 2= my store) {

		if (tabValue === 2) {
			const filteredArray = $lpr_results.filter((lprResult, key) => {
				//key will not work, posible values are numbers from indexed array
				//return (key === 'swr' || key === 'sup') && lprResult['my_store'] === '1';
				return lprResult["my_store"] === "1";
			});
			let percentageCount = 0;
			filteredArray.forEach((value) => {
				percentageCount += Number(value["swr_percentage"]) + Number(value["sup_percentage"]);
			});
			return tran_percentage(percentageCount / filteredArray.length) + "%";
		} else {
			let percentageCount = 0;
			$lpr_results.forEach((value) => {
				percentageCount += Number(value["swr_percentage"]) + Number(value["sup_percentage"]);
			});
			return tran_percentage(percentageCount / $lpr_results.length) + "%";
		}
	}
	const getNameArray = () => {
		return nameArray;
	};

	//LP Store Functions

	const db_count_org_users_store_accessed = (lpr_headers, lpr_headers_user_count) => {
		let userTotal = 0;
		lpr_headers.forEach((value) => {
			if (value !== "swr" && value !== "sup") {
				userTotal += lpr_headers_user_count[value];
			}
		});
		return userTotal;
	}; // value
	const db_count_org_task_store_completion = (lpr_headers, lpr_store_results) => {
		let perTotal = 0;
		let taskCount = 0;
		lpr_headers.forEach((value) => {
			if (value !== "swr" && value !== "sup") {
				lpr_store_results.forEach(($valueResults) => {
					if ($valueResults[value + "_percentage"]) {
						perTotal += $valueResults[value + "_percentage"] * $valueResults[value + "_user"];
						taskCount += 1 * $valueResults[value + "_user"];
					}
				});
			}
		});
		return tran_percentage(perTotal / taskCount) + "%";
	}; // value

	return {
		getNameArray,
		tran_percentage,
		db_count_org_stores,
		db_count_org_users_accessed,
		db_count_org_task_completion,
		db_count_org_mid_managers_accessed,
		db_count_org_mid_managers_completion,
		db_count_org_users_store_accessed,
		db_count_org_task_store_completion,
	};
};

export default usePathsFunctions;
