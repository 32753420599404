import React from "react";
import "./forms.scss";
import { FormsTitle } from "./title/FormsTitle";
import { HealthChecksTable } from "./healthChecksTable/HealthChecksTable";
import { HealthChecksArea } from "./healthChecksArea/HealthChecksArea";
import { ExpectationsAndRoutinesArea } from "./expectaionsAndRoutinesArea/ExpectationsAndRoutinesArea";
import { calculate_top_margin } from "../../utils/utils";
import useQueryParams from "../../hooks/useQueryParams";
import { FormDetail } from "./formDetail/FormDetail";

export const Forms = () => {
	const query = useQueryParams();
	const form = query.get("form");

	return (
		<>
			{form == null && (
				<div className="container-fluid main forms" style={{ ...calculate_top_margin() }}>
					<FormsTitle />
					<ExpectationsAndRoutinesArea />
					<HealthChecksArea />
					<HealthChecksTable />
				</div>
			)}
			{form != null && (
				<div className="container-fluid main formDetail" style={{ ...calculate_top_margin() }}>
					<FormDetail />
				</div>
			)}
		</>
	);
};
