import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../utils/utils-typescript";
import ListItemTaskView from "./listItemTaskView/ListItemTaskView";
import ChecklistTasksTable from "./checklistTasksTable/ChecklistTasksTable";
import { UserContext, UserContextType } from "../../../contexts/UserContext";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";
import { useChecklistDetailService, useGetChecklistAssignment } from "./checklistDetail.hooks";
import Loading from "../../../components/Loading/Loading";
import { calculate_top_margin } from "../../../utils/utils";
import "./checklist-detail.scss";

const ChecklistDetail: React.FC = () => {
	const { isLoading } = useChecklistDetailService();

	const { checklistAssignmentId } = useParams<{ checklistAssignmentId: string }>();
	const { store } = useContext<UserContextType>(UserContext);
	const { translation } = store;

	const { checklistAssignment } = useGetChecklistAssignment();

	const currentGoLiveDate = checklistAssignment?.date;

	return (
		<div className="container-fluid main checklist-detail" style={{ ...calculate_top_margin("") }}>
			{isLoading && <Loading />}
			<div className="checklist-header">
				<div className="checklist-header-left">
					<div className="checklist-header-title">
						<ParseHTMLContent
							htmlString={translation(checklistAssignment?.title, checklistAssignment?.titleEs)}
						/>
					</div>
					<div className="checklist-header-information">
						{checklistAssignment?.storeName}
						{((checklistAssignment?.description != null &&
							checklistAssignment?.description !== "") ||
							(checklistAssignment?.descriptionEs != null &&
								checklistAssignment?.descriptionEs !== "")) && (
							<>
								-{" "}
								<ParseHTMLContent
									htmlString={translation(
										checklistAssignment?.description,
										checklistAssignment?.descriptionEs,
									)}
								/>
							</>
						)}
					</div>
				</div>
				{checklistAssignment?.date != null && (
					<div className="checklist-header-right">
						{checklistAssignment?.targetDateLabel}:{" "}
						{formatDate(new Date(checklistAssignment?.date ?? ""))}
					</div>
				)}
			</div>

			<div className="checklist-view">
				<ListItemTaskView />
			</div>
			{checklistAssignment?.checklistId != null && (
				<ChecklistTasksTable
					goLiveDate={currentGoLiveDate != null ? new Date(currentGoLiveDate) : null}
					checklistAssignmentId={Number(checklistAssignmentId)}
				/>
			)}
		</div>
	);
};

export default ChecklistDetail;
