import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { formatDate_MM_DD_YYYY } from "../../../utils/utils-typescript";
import "./checklist-report.scss";
import { useChecklistDropdown, useChecklistReports } from "./checklistReports.service";
import { ChecklistDropdownFragment } from "../../../generated/graphql";
import Loading from "../../../components/Loading/Loading";
import { useSetRecoilState } from "recoil";
import { modalRenderAtom } from "../../../contexts/modal.recoil";
import ChangeGoLiveDateModal from "./changeGoLiveDateModal/ChangeGoLiveDateModal";
import { UserContext, UserContextType } from "../../../contexts/UserContext";
import { useWindowBreakpoints } from "../../../hooks/useWindowBreakpoints";
import { calculate_top_margin, strip_tags } from "../../../utils/utils";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";

const ChecklistReports: React.FC = () => {
	const history = useHistory();
	const [selectedChecklist, setSelectedChecklist] = useState<ChecklistDropdownFragment>();
	const setModalRender = useSetRecoilState(modalRenderAtom);

	const { store } = useContext<UserContextType>(UserContext);
	const { personID, translation } = store;

	const { isWindowMobile } = useWindowBreakpoints();

	const {
		callGetChecklistAssignmentReports,
		isLoading: checklistReportsLoading,
		groupedChecklistReports,
	} = useChecklistReports();
	const { checklistDropdowns, isLoading: checklistDropdownsLoading } = useChecklistDropdown();

	useEffect(() => {
		if (checklistDropdowns.length > 0) {
			setSelectedChecklist({
				databaseId: checklistDropdowns[0]?.databaseId!,
				checklistFields: checklistDropdowns[0]?.checklistFields,
			});
		}
	}, [checklistDropdowns]);

	useEffect(() => {
		if (selectedChecklist == null) {
			return;
		}
		callGetChecklistAssignmentReports(selectedChecklist.databaseId);
	}, [callGetChecklistAssignmentReports, selectedChecklist]);

	const setChecklistDropdownWithId = useCallback(
		(id: string) => {
			const selectedId = Number(id);
			const selectedChecklistOption = checklistDropdowns.find(
				(cd) => cd?.databaseId === selectedId,
			);
			setSelectedChecklist({
				databaseId: selectedChecklistOption?.databaseId!,
				checklistFields: selectedChecklistOption?.checklistFields,
			});
		},
		[checklistDropdowns],
	);

	const handleChangeGoLiveDate = useCallback(
		(assignmentId: number, defaultDate: Date, assignmentDate: Date | null) => {
			const setGoLiveDate = assignmentDate != null ? assignmentDate : defaultDate;
			setModalRender(
				<ChangeGoLiveDateModal
					currentGoLiveDate={setGoLiveDate}
					originalGoLiveDate={defaultDate}
					personId={personID}
					assignmentId={assignmentId}
					onSaveComplete={() => {
						callGetChecklistAssignmentReports(selectedChecklist?.databaseId!);
					}}
					targetDateLabel={selectedChecklist?.checklistFields?.targetDateLabel || ""}
				/>,
			);
		},
		[
			callGetChecklistAssignmentReports,
			personID,
			selectedChecklist?.checklistFields?.targetDateLabel,
			selectedChecklist?.databaseId,
			setModalRender,
		],
	);

	const handleChangePage = (id: string) => {
		history.push(`/mcd-checklists/reports/${id}`);
	};

	const isLoading = checklistDropdownsLoading || checklistReportsLoading;

	const targetDateLabel = selectedChecklist?.checklistFields?.targetDateLabel;

	return (
		<div className="container-fluid main checklist-reports" style={{ ...calculate_top_margin("") }}>
			{isLoading && <Loading />}
			<div className="checklist-report-header">
				<select
					className="checklist-select"
					disabled={checklistDropdowns.length === 0 && isLoading}
					onChange={(e) => {
						setChecklistDropdownWithId(e.currentTarget.value);
					}}
				>
					{checklistDropdowns.map((checklistDropdown) => {
						return (
							<option value={checklistDropdown?.databaseId}>
								{strip_tags(
									translation(
										checklistDropdown?.checklistFields?.title,
										checklistDropdown?.checklistFields?.titleEs,
									),
								)}
							</option>
						);
					})}
					{checklistDropdowns.length === 0 && !isLoading && <option>No Checklist Available</option>}
				</select>
				<div className="checklist-description">
					<ParseHTMLContent
						htmlString={translation(
							selectedChecklist?.checklistFields?.description,
							selectedChecklist?.checklistFields?.descriptionEs,
						)}
					/>
					{}
				</div>
			</div>
			{!isWindowMobile && (
				<table className="checklist-report-table">
					<tbody>
						<tr className="checklist-report-table-row">
							<th className="checklist-report-table-header">Restaurant (NSN)</th>
							<th className="checklist-report-table-header center">Overdue Tasks</th>
							<th className="checklist-report-table-header center">Incomplete Tasks Due Soon</th>
							<th className="checklist-report-table-header">{targetDateLabel}</th>
							<th className="checklist-report-table-header"></th>
						</tr>
						{Object.values(groupedChecklistReports).map((item) => (
							<tr key={item.id} className="checklist-report-row">
								<td
									className="checklist-report-table-element clickable-element"
									onClick={() => handleChangePage(String(item.id))}
								>
									{item.restaurantName} - {item.restaurantNSN} - {item.initiative}
								</td>
								<td className="checklist-report-table-element center">{item.overdueTasks}</td>

								<td className="checklist-report-table-element center">{item.incompleteTasks}</td>
								{/* {item.customGoLiveDate != null ? (
									<td className="checklist-report-table-element orange">
										{formatDate_MM_DD_YYYY(item.customGoLiveDate)}
									</td>
								) : (
									<td className="checklist-report-table-element">
										{formatDate_MM_DD_YYYY(item.goLiveDate)}
									</td>
								)} */}
								<td className="checklist-report-table-element">
									{formatDate_MM_DD_YYYY(item.assignmentDate)}
								</td>
								<td
									className="checklist-report-table-element clickable-element"
									onClick={() => {
										handleChangeGoLiveDate(item.id, item.checklistDefaultDate, item.assignmentDate);
									}}
								>
									Change {targetDateLabel}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			{isWindowMobile && (
				<div className="mobile-list-container">
					{Object.values(groupedChecklistReports).map((item) => (
						<div className="mobile-list-item">
							<div className="mobile-list-item-row">
								<div className="mobile-list-item-cell label">Restaurant (NSN)</div>
								<div
									className="mobile-list-item-cell value anc"
									onClick={() => handleChangePage(String(item.id))}
								>
									{item.restaurantName} - {item.restaurantNSN} - {item.initiative}
								</div>
							</div>
							<div className="mobile-list-item-row">
								<div className="mobile-list-item-cell label">Overdue Tasks</div>
								<div className="mobile-list-item-cell value">{item.overdueTasks}</div>
							</div>
							<div className="mobile-list-item-row">
								<div className="mobile-list-item-cell label">Incomplete Tasks</div>
								<div className="mobile-list-item-cell value">{item.incompleteTasks}</div>
							</div>
							<div className="mobile-list-item-row">
								<div className="mobile-list-item-cell label">{targetDateLabel}</div>
								{/* {item.customGoLiveDate != null ? (
									<div className="mobile-list-item-cell value orange">
										{formatDate_MM_DD_YYYY(item.customGoLiveDate)}
									</div>
								) : (
									<div className="mobile-list-item-cell value">
										{formatDate_MM_DD_YYYY(item.goLiveDate)}
									</div>
								)} */}
								<div className="mobile-list-item-cell value">
									{formatDate_MM_DD_YYYY(item.assignmentDate)}
								</div>
							</div>
							<div className="mobile-list-item-row">
								<div className="mobile-list-item-cell label"> </div>
								<div
									className="mobile-list-item-cell value anc"
									onClick={() => {
										handleChangeGoLiveDate(item.id, item.checklistDefaultDate, item.assignmentDate);
									}}
								>
									Change {targetDateLabel}
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default ChecklistReports;
