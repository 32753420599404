import React, { useState } from "react";
import "../formContent.scss";
import { FormSection } from "./formSection/FormSection";
import { FormDetailData } from "../../../../../utils/deleteme/formsData";
interface FormSectionsProps {
	onSectionClick: (sectionIndex: number) => void;
}
export const FormSections: React.FC<FormSectionsProps> = ({ onSectionClick }) => {
	const { sections } = FormDetailData;

	const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

	return (
		<div id="form-sections">
			{sections.map((section, index) => (
				<FormSection
					key={index}
					index={index}
					section={section}
					isCompleted={index < selectedSectionIndex}
					isSelected={index === selectedSectionIndex}
					onSectionClick={() => {
						setSelectedSectionIndex(index);
						onSectionClick(index);
					}}
				/>
			))}
		</div>
	);
};
