import React, { useCallback, useState } from "react";
import { useUpdateChecklistAssignmentMutation } from "../../../../generated/graphql";
import { modalRenderAtom } from "../../../../contexts/modal.recoil";
import { useSetRecoilState } from "recoil";
import { formatDate_YYYY_MM_DD } from "../../../../utils/utils-typescript";
import "./change-go-live-date-modal.scss";

interface Props {
	currentGoLiveDate: Date;
	assignmentId: number;
	personId: string;
	onSaveComplete?: () => void;
	originalGoLiveDate: Date;
	targetDateLabel: string;
}
const ChangeGoLiveDateModal: React.FC<Props> = ({
	currentGoLiveDate,
	assignmentId,
	originalGoLiveDate,
	personId,
	onSaveComplete,
	targetDateLabel,
}) => {
	const [inputGoLiveDate, setInputGoLiveDate] = useState<string>(
		formatDate_YYYY_MM_DD(currentGoLiveDate),
	);
	const setModalRender = useSetRecoilState(modalRenderAtom);
	const [updateAssignment, updateAssignmentRes] = useUpdateChecklistAssignmentMutation();

	const onSaveClick: React.MouseEventHandler = useCallback(
		async (e) => {
			await updateAssignment({
				variables: {
					input: {
						id: assignmentId,
						date: inputGoLiveDate,
						updatedBy: personId,
					},
				},
			});
			onSaveComplete && onSaveComplete();
			setModalRender(undefined);
		},
		[updateAssignment, assignmentId, inputGoLiveDate, personId, onSaveComplete, setModalRender],
	);

	const closeModal = useCallback(() => {
		setModalRender(undefined);
	}, [setModalRender]);

	const isLoading = updateAssignmentRes.loading;

	return (
		<div className="change-go-live-date-modal">
			<label htmlFor="original-Go-Live-Date">Original Checklist Date</label>
			<div className="go-live-date">{formatDate_YYYY_MM_DD(originalGoLiveDate)}</div>
			<label htmlFor="goLiveDate">{targetDateLabel}</label>
			<input
				id="goLiveDate"
				type="date"
				value={inputGoLiveDate}
				onChange={(e) => {
					setInputGoLiveDate(e.target.value);
				}}
			/>
			<div className="button-area">
				<button className="outline" disabled={isLoading} onClick={closeModal}>
					Cancel
				</button>
				<button
					type="button"
					className="button default-red"
					disabled={isLoading}
					onClick={onSaveClick}
				>
					{isLoading && <div className="loader"></div>}
					Save
				</button>
			</div>
		</div>
	);
};

export default ChangeGoLiveDateModal;
