import { setupIonicReact } from "@ionic/react";
import { useReducer } from "react";
import { initialUserState, UserContext, userReducer } from "./contexts/UserContext";
import { ApolloProvider } from "@apollo/client";
import client from "./services/apollo-client";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Main from "./apps/Main";
import PrintArea from "./components/PrintArea/PrintArea";
import "animate.css";
import "./styles/global.scss";
import "./styles/mcd-info.scss";

import { Provider, ErrorBoundary } from "@rollbar/react";
import env from "./env";

const rollbarConfig = {
	accessToken: env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
	environment: env.REACT_APP_ENV_TYPE,
};

setupIonicReact();

const App = () => {
	const [store, dispatch] = useReducer(userReducer, initialUserState);

	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary>
				<UserContext.Provider value={{ store, dispatch }}>
					<ApolloProvider client={client}>
						<RecoilRoot>
							<Main />
							<ToastContainer position="top-right" style={{ top: "110px" }} autoClose={3000} />
							<PrintArea />
						</RecoilRoot>
					</ApolloProvider>
				</UserContext.Provider>
			</ErrorBoundary>
		</Provider>
	);
};

export default App;
