import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import env from "../env";

export const useLogout = () => {
	// @TODO this can be more graceful by setting all states to default value then using history.push instead of reload
	const logout = () => {
		localStorage.removeItem(LocalStorageKeys.TOKEN);

		var iframe = document.createElement("iframe");
		iframe.style.display = "none";
		iframe.src = env.REACT_APP_MCD_LOGOUT_URL;
		iframe.addEventListener("load", function () {
			document.body.removeChild(iframe);
			window.location.reload();
		});

		document.body.appendChild(iframe);
	};

	return logout;
};

export default useLogout;
