import React from "react";
import { FormDetailData } from "../../../../utils/deleteme/formsData";
import "./formsHeader.scss";
export const FormHeader = () => {
	const formattedDate = new Date().toLocaleDateString("en-US");
	const estimatedTime = FormDetailData.estimatedTime;

	return (
		<div className="detailHeader-info formHeader">
			<h1>Setting Expectations & Routines</h1>
			<p>
				<span>{FormDetailData.organizationName}</span>
				<span>
					Last Updated: <span className="thin">{formattedDate}</span>
				</span>
				<span>
					Estimated Time to Complete: <span className="thin">{estimatedTime}</span>
				</span>
			</p>
		</div>
	);
};
