import React, { useEffect, useState } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";
import "./pagination.scss";

export interface PaginationProps {
	pages: number;
	currentPage: number;
	onPageClick: (page: number) => void;
	hasJumpToPage?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
	currentPage,
	onPageClick,
	pages,
	hasJumpToPage,
}) => {
	const [paginationItems, setPaginationItems] = useState<string[]>([]);
	const [gotoNumber, setGotoNumber] = useState<string>();

	useEffect(() => {
		if (pages > 0) {
			const pItems: string[] = [];
			const intPages = Math.ceil(pages);
			pItems.push("prev");
			if (intPages < 9) {
				const tempPages = [...Array(intPages)].map((v, i) => {
					return String(i + 1);
				});
				pItems.push(...tempPages);
			} else {
				if (currentPage - 1 > 4) {
					pItems.push("1");
					pItems.push("...");
				}
				currentPage - 2 > 0 && pItems.push(String(currentPage - 2));
				currentPage - 1 > 0 && pItems.push(String(currentPage - 1));
				pItems.push(String(currentPage));
				currentPage + 1 <= intPages && pItems.push(String(currentPage + 1));
				currentPage + 2 <= intPages && pItems.push(String(currentPage + 2));
				if (intPages - currentPage > 4) {
					pItems.push("...");
					pItems.push(String(intPages));
				}
			}
			pItems.push("next");
			setPaginationItems(pItems);
		} else {
			setPaginationItems([]);
		}
	}, [pages, currentPage]);

	if (pages === 0) {
		return <></>;
	}

	return (
		<div className="g3-pagination">
			<div className="pagination-bar">
				{paginationItems.map((v, i) => {
					const intPages = Math.ceil(pages);
					return (
						<React.Fragment key={"pag-" + i}>
							{v === "prev" && (
								<div
									className={"pagination-item " + (currentPage === 1 ? "disabled" : "")}
									onClick={() => onPageClick(currentPage - 1)}
								>
									<AiOutlineDoubleLeft />
								</div>
							)}
							{!(v === "next" || v === "prev" || v === "...") && (
								<div
									className={"pagination-item " + (currentPage === Number(v) ? "active" : "")}
									onClick={() => onPageClick(Number(v))}
								>
									{Number(v)}
								</div>
							)}
							{v === "next" && (
								<div
									className={"pagination-item " + (currentPage === intPages ? "disabled" : "")}
									onClick={() => onPageClick(currentPage + 1)}
								>
									<AiOutlineDoubleRight />
								</div>
							)}
							{v === "..." && <div className="pagination-item disabled">...</div>}
						</React.Fragment>
					);
				})}
			</div>
			{hasJumpToPage && (
				<div className="jump-to-page">
					Jump to Page
					<input
						type="number"
						value={gotoNumber}
						onChange={(e) => {
							setGotoNumber(e.target.value);
						}}
					/>
					<button
						type="button"
						onClick={() => {
							const tempPage = Number(gotoNumber) || 1;
							if (tempPage <= 0) {
								onPageClick(1);
							} else if (tempPage > 0 && tempPage <= pages) {
								onPageClick(tempPage);
							} else {
								onPageClick(Math.ceil(pages));
							}
							setGotoNumber("");
						}}
					>
						<HiArrowRight />
					</button>
				</div>
			)}
		</div>
	);
};
