import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Loading from "../../../components/Loading/Loading";
import { imgPath, PathName } from "../../../constants/PathName";
import { printRenderAtom } from "../../../contexts/print.recoil";
import { UserContext } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";
import { in_array, makeQuery } from "../../../utils/utils";
import usePathsFunctions from "../usePathsFunctions";
import LprTable from "./LprTable/LprTable";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";

const LP_TABS_QUERY = gql`
	query GetLPTabs(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		lpTabs(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				learningPathTabs {
					id
					class
					htmlid
					title
					titleEs
				}
				metaFields {
					sortOrder
				}
			}
		}
	}
`;

const LP_HEADERS_QUERY = gql`
	query GetLPHeaders(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		lpHeaders(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				learningPathHeaders {
					id
					header01
					header02
					header03
					header01Es
					header02Es
					header03Es
					summaryTitle01
					summaryTitle02
					summaryTitle03
					summaryTitle01Es
					summaryTitle02Es
					summaryTitle03Es
					midManagerTitle01
					midManagerTitle02
					midManagerTitle01Es
					midManagerTitle02Es
				}
			}
		}
	}
`;

const LPR_VALUES_QUERY = gql`
	query GetLPRValues($personID: String) {
		getLPRValues(personID: $personID) {
			lprHeaders
			lprResults {
				boa_percentage
				boa_user
				l1_user
				company_type
				l1_percentage
				l2_percentage
				l2_user
				l3_percentage
				l3_user
				l4_percentage
				l4_user
				l5_percentage
				l5_user
				my_store
				op_percentage
				op_user
				store
				sup_percentage
				sup_user
				swr_percentage
				swr_user
				tot_user
				tot_percentage
			}
		}
	}
`;

const LearningPathProgress = ({ grantLPR }) => {
	const query = useQueryParams();
	const m = query.get("m");
	const msub = query.get("msub");
	const r = query.get("r");
	const l = query.get("l");
	const t = query.get("t");
	const {
		db_count_org_stores,
		db_count_org_users_accessed,
		db_count_org_task_completion,
		db_count_org_mid_managers_completion,
		db_count_org_mid_managers_accessed,
	} = usePathsFunctions();

	const { store } = useContext(UserContext);
	const { translation, permissionGroup, personID, userData } = store;
	const setPrintRender = useSetRecoilState(printRenderAtom);
	const printRef1 = useRef();
	const printRef2 = useRef();
	const [lprResults, setLprResults] = useState([]);

	const {
		loading: lpTabsQueryLoading,
		error: lpTabsQueryError,
		data: lpTabsQueryData,
	} = useQuery(LP_TABS_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	const {
		loading: lpHeadersQueryLoading,
		error: lpHeadersQueryError,
		data: lpHeadersQueryData,
	} = useQuery(LP_HEADERS_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	const {
		loading: lprValuesQueryLoading,
		error: lprValuesQueryError,
		data: lprValuesQueryData,
	} = useQuery(LPR_VALUES_QUERY, {
		variables: {
			personID: personID,
		},
	});

	const printCurrentPage = () => {
		setPrintRender(
			<div className="dt-print-view">
				<h1 className="page-header">
					{translation("Learning Path Progress", "Progreso de Rutas de Aprendizaje")}
				</h1>
				<div
					dangerouslySetInnerHTML={{
						__html: printRef1.current.innerHTML + printRef2.current.innerHTML,
					}}
				/>
			</div>,
		);
	};

	useEffect(() => {
		if (lprValuesQueryData && lprValuesQueryData.getLPRValues) {
			setLprResults(lprValuesQueryData.getLPRValues.lprResults);
		}
	}, [lprValuesQueryData]);

	if (lpTabsQueryLoading || lpHeadersQueryLoading || lprValuesQueryLoading) return <Loading />;
	if (lpHeadersQueryError) return <p>Unable to find lp headers :(</p>;
	if (lpTabsQueryError) return <p>Unable to find lp tabs :(</p>;
	if (lprValuesQueryError) return <p>Unable to find lpr headers and results :(</p>;

	const lpTabs = lpTabsQueryData.lpTabs.nodes || [];

	let selectedLpTabIndex = lpTabs.findIndex(
		({ learningPathTabs }) => t === learningPathTabs["class"],
	);
	if (selectedLpTabIndex === -1 && lpTabs.length !== 0) {
		selectedLpTabIndex = 0;
	}
	const selectLpTabIndexP1 = selectedLpTabIndex + 1;

	const lpHeaders = {};
	if (lpHeadersQueryData.lpHeaders.nodes) {
		for (let i = 0; i < lpHeadersQueryData.lpHeaders.nodes.length; ++i) {
			const node = lpHeadersQueryData.lpHeaders.nodes[i];
			lpHeaders[node.learningPathHeaders.id] = node.learningPathHeaders;
		}
	}

	const lprHeaders = lprValuesQueryData.getLPRValues.lprHeaders || [];
	// const lprResults = lprValuesQueryData.getLPRValues.lprResults || [];

	return (
		<div className="row lpr-page">
			<div className="col-xs-12">
				<h2 className="page-header">
					{translation("Learning Path Progress", "Progreso de Rutas de Aprendizaje")}
				</h2>
				{in_array(permissionGroup.accessLevel, grantLPR) &&
					in_array(permissionGroup.companyType, grantLPR) &&
					in_array(m, grantLPR) && (
						<ul className="nav nav-tabs navbar-right lpr-tabs">
							<li className={!r ? "active" : ""}>
								<Link to={"?m=" + m + "&msub=" + msub + "&r=&l=" + l}>
									{translation("My Learning Path", "Mi Ruta de Aprendizaje")}
								</Link>
							</li>
							<li className={r && r !== "" ? "active" : ""}>
								<Link to={"?m=" + m + "&msub=" + msub + "&r=org-learning-path-progress&l=" + l}>
									{translation("Learning Path Progress", "Progreso de Rutas de Aprendizaje")}
								</Link>
							</li>
						</ul>
					)}
			</div>
			<div className="col-xs-12">
				<h5>
					{translation(
						"Here is a summary of your team's progress on their Learning Paths.",
						"Aquí puedes ver un ressúmen del progreso de tu equipo por las Rutas de Aprendizaje.",
					)}
				</h5>
				<p>
					<small>
						{translation(
							"Note: The list of restaurants are obtained from SSO.  If you do not see one of your restaurants listed below, check [GAM] to ensure the restaurants are correctly assigned to your eID.",
							"Nota: la lista de restaurantes son obtenidos de SSO. Si no vez uno de tus restaurantes en la lista, revisa [GAM] para asegurar que el restaurante fue asignado correctamente a tu eID.",
						)}
					</small>
				</p>
			</div>
			<div id="taskSwitcher" className="col-xs-12">
				<ul id="taskTabs" className="nav-pills nav-justified task-tabs">
					{(() => {
						return lpTabs.map(({ learningPathTabs }, i) => {
							return (
								<li role="presentation" className={i === selectedLpTabIndex ? "active" : ""}>
									<Link
										to={PathName.PATHS + "?" + makeQuery(query, { t: learningPathTabs["class"] })}
										aira-controls={learningPathTabs["htmlID"]}
										role="tab"
										data-toggle="tab"
									>
										<ParseHTMLContent
											htmlString={translation(
												learningPathTabs["title"],
												learningPathTabs["titleEs"],
											)}
										/>
									</Link>
								</li>
							);
						});
					})()}
				</ul>
			</div>
			<div className="col-xs-12 tab-content">
				{selectedLpTabIndex !== -1 && (
					<>
						<div id={lpTabs[selectedLpTabIndex]["htmlID"]} role="tabpanel" className="active">
							<div className="col-xs-12">
								<div className="lpr-options">
									<span>
										<button className="btn lpr-print-button" onClick={() => printCurrentPage()}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 34.8">
												<path d="M20.7,1.75l4.8,4.81V14.7H9.5V1.75H20.7M21.52,0H7.5V16.44h20V6l-6-6Z" />
												<path d="M25.5,27.38V32.8H9.5V27.38m18-2H7.5V34.8h20V25.38Z" />
												<polygon points="27.65 7.97 19.7 7.97 19.67 0 21.67 0 21.7 5.97 27.65 6 27.65 7.97" />
												<path d="M31,13.58H27.5v1.86H7.5V13.58H4a4,4,0,0,0-4,4v11H7.5v-2h20v2H35v-11A4,4,0,0,0,31,13.58Zm-.44,6.86a2,2,0,1,1,2-2A2,2,0,0,1,30.56,20.44Z" />
												<rect x="11.5" y="29.59" width="12" height="1" />
												<path d="M20.7,1.75l4.8,4.81V14.7H9.5V1.75H20.7M21.52,0H7.5V16.44h20V6l-6-6Z" />
												<path d="M25.5,27.38V32.8H9.5V27.38m18-2H7.5V34.8h20V25.38Z" />
												<polygon points="27.65 7.97 19.7 7.97 19.67 0 21.67 0 21.7 5.97 27.65 6 27.65 7.97" />
												<path d="M31,13.58H27.5v1.86H7.5V13.58H4a4,4,0,0,0-4,4v11H7.5v-2h20v2H35v-11A4,4,0,0,0,31,13.58Zm-.44,6.86a2,2,0,1,1,2-2A2,2,0,0,1,30.56,20.44Z" />
												<rect x="11.5" y="29.59" width="12" height="1" />
											</svg>
											{translation("Print Page", "Imprimir Página")}
										</button>
									</span>
								</div>
							</div>
							<div className="col-xs-12 add-to-print" ref={printRef1}>
								<h2>
									<ParseHTMLContent
										htmlString={translation(
											lpHeaders["nsn-00" + selectLpTabIndexP1]["header01"],
											lpHeaders["nsn-00" + selectLpTabIndexP1]["header01Es"],
										)}
									/>
								</h2>

								<div className="row">
									<div className="col-xs-12 col-sm-4">
										<div className="card">
											<h3 className="heading">
												{translation(
													lpHeaders["nsn-00" + selectLpTabIndexP1]["summaryTitle01"],
													lpHeaders["nsn-00" + selectLpTabIndexP1]["summaryTitle01Es"],
												)}
											</h3>
											<div className="data">
												{db_count_org_stores(selectLpTabIndexP1, lprResults)}
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-sm-4">
										<div className="card">
											<h3 className="heading">
												{translation(
													lpHeaders["nsn-00" + selectLpTabIndexP1]["summaryTitle02"],
													lpHeaders["nsn-00" + selectLpTabIndexP1]["summaryTitle02Es"],
												)}
											</h3>
											<div className="data">
												{db_count_org_users_accessed(selectLpTabIndexP1, lprResults)}
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-sm-4">
										<div className="card">
											<h3 className="heading">
												{translation(
													lpHeaders["nsn-00" + selectLpTabIndexP1]["summaryTitle03"],
													lpHeaders["nsn-00" + selectLpTabIndexP1]["summaryTitle03Es"],
												)}
											</h3>
											<div className="data ">
												{db_count_org_task_completion(selectLpTabIndexP1, lprResults)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xs-12 add-to-print" ref={printRef2}>
								<h2>
									<img src={imgPath + "/icon-profile.svg"} className="icon-off" alt="" />
									{translation(
										lpHeaders["nsn-00" + selectLpTabIndexP1]["header02"],
										lpHeaders["nsn-00" + selectLpTabIndexP1]["header02Es"],
									)}
								</h2>

								<div className="row">
									<div className="col-xs-12 col-sm-6">
										<div className="card">
											<h3 className="heading">
												{translation(
													lpHeaders["nsn-00" + selectLpTabIndexP1]["midManagerTitle01"],
													lpHeaders["nsn-00" + selectLpTabIndexP1]["midManagerTitle01Es"],
												)}
											</h3>
											<div className="data">
												{db_count_org_mid_managers_accessed(selectLpTabIndexP1, lprResults)}
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-sm-6">
										<div className="card">
											<h3 className="heading">
												{translation(
													lpHeaders["nsn-00" + selectLpTabIndexP1]["midManagerTitle02"],
													lpHeaders["nsn-00" + selectLpTabIndexP1]["midManagerTitle02Es"],
												)}
											</h3>
											<div className="data">
												{db_count_org_mid_managers_completion(selectLpTabIndexP1, lprResults)}
											</div>
										</div>
									</div>
								</div>
								<div className="col-xs-12 no-print">
									<h2>
										<img src={imgPath + "/icon-profile.svg"} className="icon-off" alt="" />
										{translation(
											lpHeaders["nsn-00" + selectLpTabIndexP1]["header03"],
											lpHeaders["nsn-00" + selectLpTabIndexP1]["header03Es"],
										)}
									</h2>
								</div>
								<div className="col-xs-12">
									<LprTable
										tabValue={selectLpTabIndexP1}
										lprHeaders={lprHeaders}
										lprResults={lprResults}
										setLprResults={setLprResults}
									/>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default LearningPathProgress;
