import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import env from "../env";

const httpLink = createHttpLink({
	uri: env.REACT_APP_API_BASE_URL + "/graphql",
});

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem("token");
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const namedLink = new ApolloLink((operation, forward) => {
	operation.setContext(() => ({
		uri: `${env.REACT_APP_API_BASE_URL}/graphql?${operation.operationName}`,
	}));
	return forward ? forward(operation) : null;
});

const client = new ApolloClient({
	link: ApolloLink.from([authLink, namedLink, httpLink]),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "cache-and-network",
		},
		query: {
			fetchPolicy: "network-only",
		},
	},
});

export default client;
