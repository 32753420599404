import { gql, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { PathName } from "../../../constants/PathName";
import { UserContext } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";
import { in_array, isset, makeQuery } from "../../../utils/utils";
import GetBadges from "../PathsCommon/GetBadges";
import DashboardTab from "./LpTab/DashboardTab";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";

const LEARNING_PATHS_QUERY = gql`
	query GetLearningPathQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$initiativeSlug: [String]
		$userEid: String
	) {
		dashboardAccesses(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				dashboardAccessLevelFields {
					content
					contentEs
				}
			}
		}
		dashboardTabs(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				permissionGroupFields {
					acceptcustomroles
				}
				dashboardTabsFields {
					id
					class
					htmlid
					title
					titleEs
				}
			}
		}
		userBadges(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				userBadgeFields {
					id
					parentCard
					title
					titleEs
				}
			}
		}
		# badges(
		# 	where: {
		# 		acceptAccessRole: $acceptAccessRole
		# 		acceptCompanyType: $acceptCompanyType
		# 		acceptCustomRole: $acceptCustomRole
		# 		initiativeSlug: $initiativeSlug
		# 		orderby: { field: SORT_ORDER, order: ASC }
		# 	}
		# ) {
		# 	nodes {
		# 		badgeHeaderFields {
		# 			title
		# 			titleEs
		# 			type
		# 		}
		# 	}
		# }
	}
`;

const LearningPaths = ({ $grantLPR }) => {
	const query = useQueryParams();
	const t = query.get("t"); //@TODO verify combine with p = 'whats new', should get value t = 'all'
	const m = query.get("m");
	const msub = query.get("msub");
	const r = query.get("r");
	const l = query.get("l");

	const { store } = useContext(UserContext);
	const { translation, permissionGroup, userLanguage, userData } = store;

	const { loading, error, data } = useQuery(LEARNING_PATHS_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			initiativeSlug: m,
			userEid: userData.eID,
		},
		fetchPolicy: "cache-and-network",
	});

	const $deniedBadges = ["Operator", "Organization Admin", "None"];
	// const $super_badges = [];

	// const get_badge_headers = (array) => {
	// 	array.forEach((headers) => {
	// 		if (!in_array(permissionGroup.accessLevel, headers["deniedAccessRoles"])) {
	// 			// if (headers["type"] === type) {
	// 			return <h5>{translation(headers["title"], headers["title_es"])}</h5>;
	// 			// }
	// 		}
	// 	});
	// };

	const col9 = () => {
		if (m === "cit" || m === "myidm") {
			if (!isset(r) || r === "" || r === null) {
				if (!in_array(permissionGroup.accessLevel, $deniedBadges)) {
					return "col-md-9 col-lg-9";
				}
			}
		}
	};

	if (loading) return <Loading />;
	if (error) return <p>Unable to load Learning Paths page :(</p>;

	const dashboardAccesses = data.dashboardAccesses.nodes || [];
	const dashboardAccess =
		dashboardAccesses.length !== 0 ? dashboardAccesses[0].dashboardAccessLevelFields : {};

	const dashboardTabs = data.dashboardTabs.nodes || [];
	const userBadges = data.userBadges.nodes || [];
	// const badgeHeaders = data.badges.nodes || [];

	let selectedDashboardTabIndex = dashboardTabs.findIndex(
		({ dashboardTabsFields }) => t === dashboardTabsFields["class"],
	);
	if (selectedDashboardTabIndex === -1 && dashboardTabs.length !== 0) {
		selectedDashboardTabIndex = 0;
	}
	return (
		<>
			<div className={"col-xs-12 " + col9()}>
				<div className="row">
					<div className="col-xs-12">
						<h2 className="page-header">
							{translation("My Learning Path", "Mi Ruta de Aprendizaje")}
						</h2>
						{in_array(permissionGroup.accessLevel, $grantLPR) &&
							in_array(permissionGroup.companyType, $grantLPR) &&
							in_array(m, $grantLPR) && (
								<ul className="nav nav-tabs navbar-right lpr-tabs">
									<li className={!r ? "active" : ""}>
										<Link to={"?m=" + m + "&msub=" + msub + "&r=&l=" + l + ""}>
											{translation("My Learning Path", "Mi Ruta de Aprendizaje")}
										</Link>
									</li>
									<li className={r && r !== "" ? "active" : ""}>
										<Link
											to={"?m=" + m + "&msub=" + msub + "&r=org-learning-path-progress&l=" + l + ""}
										>
											{translation("Learning Path Progress", "Progreso de Rutas de Aprendizaje")}
										</Link>
									</li>
								</ul>
							)}
					</div>
					<div className="col-xs-12">
						<p>
							<ParseHTMLContent
								htmlString={translation(dashboardAccess["content"], dashboardAccess["contentEs"])}
							/>
						</p>
					</div>
					<div id="taskSwitcher" className="col-xs-12">
						<ul
							id="taskTabs"
							className={
								"nav-pills nav-justified task-tabs " + (dashboardTabs.length === 1 && "hidden")
							}
						>
							{(() => {
								return dashboardTabs.map(({ dashboardTabsFields }, i) => {
									return (
										<li
											role="presentation"
											className={i === selectedDashboardTabIndex ? "active" : ""}
											key={i}
										>
											<Link
												to={
													PathName.PATHS +
													"?" +
													makeQuery(query, { t: dashboardTabsFields["class"] })
												}
												aira-controls={dashboardTabsFields["htmlID"]}
												role="tab"
												data-toggle="tab"
											>
												<ParseHTMLContent
													htmlString={translation(
														dashboardTabsFields["title"],
														dashboardTabsFields["titleEs"],
													)}
												/>
											</Link>
										</li>
									);
								});
							})()}
						</ul>
					</div>
					<div className="col-xs-12 tab-content">
						{selectedDashboardTabIndex !== -1 && (
							<DashboardTab
								tabCount={selectedDashboardTabIndex + 1}
								dashboardTab={dashboardTabs[selectedDashboardTabIndex].dashboardTabsFields}
								user_badges={userBadges}
							/>
						)}
					</div>
				</div>
			</div>

			{(m === "cit" || m === "myidm") && !in_array(permissionGroup.accessLevel, $deniedBadges) && (
				<div className="col-xs-12 col-md-3 col-lg-3">
					<div className="row">
						<div className="col-xs-12 my-badges">
							<h4 className="page-header">{translation("My Badges", "Mis Insignias")}</h4>
							<p className="small">
								{translation(
									"Earn badges as you complete all tasks in a card. ",
									"Gana insignias conforme vayas completando todas las tareas en una tarjeta. Haz clic ",
								)}
								<Link
									to={PathName.DASHBOARD + "mcd-paths/?m=" + m + "&p=my-badges&l=" + userLanguage}
								>
									{translation(" Click here ", " aquí ")}
								</Link>
								{translation(
									" to see all badges you can earn.",
									" para ver todas las insignias que puedes ganar.",
								)}
							</p>
						</div>
						{/* @TODO requires Superbadges existence to work */}
						{/* <div className="col-xs-12 my-badges">
							{get_badge_headers($badge_headers, "primary")}
							{db_get_badges($user_badges, $super_badges, "complete", "core")}
						</div>
						<div className="col-xs-12 my-badges padding-top">
							{get_badge_headers($badge_headers, "secondary")}
							{db_get_badges($user_badges, $super_badges, "complete", "additional")}
						</div> */}
						<div className="col-xs-12 my-badges padding-top">
							{/* {get_badge_headers(badgeHeaders)} */}
							<h5>{translation("Completed Badges", "Insignias Completadas")}</h5>
							<GetBadges badges={userBadges} status="complete" />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LearningPaths;
