import React from "react";
import {
	// MdRotateLeft,
	// MdRotateRight,
	MdZoomOut,
	MdZoomIn,
	MdOutlineArrowForwardIos,
	MdOutlineArrowBackIos,
	// MdOutlineFileDownload,
	// MdOutlinePrint,
	MdClose,
} from "react-icons/md";

interface Props {
	pageNumber: number;
	numPages: number;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	scale: number;
	setScale: React.Dispatch<React.SetStateAction<number>>;
	rotate: number;
	setRotate: React.Dispatch<React.SetStateAction<number>>;
	onDownloadClick?: () => void;
	onPrintClick?: () => void;
	onCloseClick?: () => void;
}

const ControlPanel: React.FC<Props> = ({
	pageNumber,
	numPages,
	setPageNumber,
	scale,
	setScale,
	rotate,
	setRotate,
	onDownloadClick,
	onPrintClick,
	onCloseClick,
}) => {
	const isFirstPage = pageNumber === 1;
	const isLastPage = pageNumber === numPages;

	const firstPageClass = isFirstPage ? "pdf-disabled" : "pdf-clickable";
	const lastPageClass = isLastPage ? "pdf-disabled" : "pdf-clickable";

	const goToPreviousPage = () => {
		if (!isFirstPage) setPageNumber(pageNumber - 1);
	};
	const goToNextPage = () => {
		if (!isLastPage) setPageNumber(pageNumber + 1);
	};

	const onPageChange = (e: any) => {
		const { value } = e.target;
		setPageNumber(Number(value));
	};

	const isMinZoom = scale < 0.6;
	const isMaxZoom = scale >= 2.0;

	const zoomOutClass = isMinZoom ? "pdf-disabled" : "pdf-clickable";
	const zoomInClass = isMaxZoom ? "pdf-disabled" : "pdf-clickable";

	const zoomOut = () => {
		if (!isMinZoom) setScale(scale - 0.1);
	};

	const zoomIn = () => {
		if (!isMaxZoom) setScale(scale + 0.1);
	};

	// const rotateCW = () => {
	// 	setRotate(rotate + 90);
	// };
	// const rotateCCW = () => {
	// 	setRotate(rotate - 90);
	// };

	return (
		<div className="pdf-control-panel flex w-full items-baseline justify-between border p-3">
			{/* <div className="flex items-end gap-4"> */}
			{/* <MdOutlinePrint
					className="h-5 w-auto hover:cursor-pointer"
					onClick={() => {
						onPrintClick?.();
					}}
				/>
				<MdOutlineFileDownload
					className="h-5 w-auto hover:cursor-pointer"
					onClick={() => {
						onDownloadClick?.();
					}}
				/> */}
			{/* <div className="rotate-control flex items-center justify-between">
					<MdRotateLeft className="ccw-icon h-5 w-auto hover:cursor-pointer" onClick={rotateCCW} />
					<MdRotateRight className="cw-icon h-5 w-auto hover:cursor-pointer" onClick={rotateCW} />
				</div> */}
			{/* </div> */}
			<div className="page-control flex items-center justify-between">
				<MdOutlineArrowBackIos
					className={`back-icon h-5 w-auto ${firstPageClass}`}
					onClick={goToPreviousPage}
				/>
				<span className="input-c flex items-center">
					<input
						name="pageNumber"
						type="number"
						min={1}
						max={numPages || 1}
						className="page-input mx-2 !w-14 p-0 pl-1"
						value={pageNumber}
						onChange={onPageChange}
					/>
					<div className="pages">/ {numPages}</div>
				</span>
				<MdOutlineArrowForwardIos
					className={`next-icon h-5 w-auto ${lastPageClass}`}
					onClick={goToNextPage}
				/>
			</div>

			<div className="zoom-control flex items-center justify-between">
				<MdZoomOut className={`zoom-out-icon h-7 w-auto ${zoomOutClass}`} onClick={zoomOut} />
				<span className="zoom-level">{(scale * 100).toFixed()}%</span>
				<MdZoomIn className={`zoom-in-icon h-7 w-auto ${zoomInClass}`} onClick={zoomIn} />
			</div>
			<div className="close-control">
				<MdClose
					className="close-icon h-5 w-auto hover:cursor-pointer"
					onClick={() => {
						onCloseClick?.();
					}}
				/>
			</div>
		</div>
	);
};

export default ControlPanel;
