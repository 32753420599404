import React from "react";
import "./faqFilter.scss";

interface Props {
	itemsSelector?: string;
	hasHighlight?: boolean;
	className?: string;
	placeholder?: string;
}
export const FaqFilter: React.FC<Props> = ({
	hasHighlight,
	itemsSelector,
	className,
	placeholder,
}) => {
	function removeHighlights(element: Element) {
		element.innerHTML = element.innerHTML.replace(/<\/?mark>/g, "");
	}

	function highlightMatches(node: Node, value: string) {
		if (!node.hasChildNodes()) {
			const textNode = node as Element;
			const parentNode = textNode.parentNode as Element;
			const text = textNode.textContent || "";
			const regex = new RegExp(value, "gi");
			const highlightedText = text.replace(regex, (match) => `<mark>${match}</mark>`);
			parentNode.innerHTML = parentNode.innerHTML.replace(text, highlightedText);
		} else {
			node.childNodes.forEach((childNode) => highlightMatches(childNode, value));
		}
	}

	const getValue = (value: string) => {
		document.querySelectorAll(itemsSelector ?? ".panel-default").forEach((item: Element) => {
			const div = item as HTMLDivElement;
			if (hasHighlight) {
				removeHighlights(div);
			}

			if (value === "") {
				div.style.display = "block";
				return;
			}

			if (div.textContent?.toLowerCase().includes(value.toLowerCase())) {
				div.style.display = "block";
				if (hasHighlight) {
					highlightMatches(div, value);
				}
			} else {
				div.style.display = "none";
			}
		});
	};

	const clearFilters = () => {
		const filter = document.getElementById("faqFilter") as HTMLInputElement;
		filter.value = "";
		document.querySelectorAll(itemsSelector ?? ".panel-default").forEach((item: Element) => {
			const div = item as HTMLDivElement;
			div.style.display = "block";
			if (hasHighlight) {
				removeHighlights(div);
			}
		});
	};

	return (
		<div className="input-group" id="faqFilterContainer">
			<input
				type="text"
				id="faqFilter"
				onKeyUp={(e) => {
					getValue(e.currentTarget.value);
				}}
				className={className ?? "form-control filter"}
				placeholder={placeholder ?? "Find in FAQs..."}
			/>
			<button
				className="btn bg-transparent filter-clear-btn"
				onClick={() => {
					clearFilters();
				}}
			>
				Clear
			</button>
		</div>
	);
};
