// import { XIcon } from "@heroicons/react/outline";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { modalRenderAtom, modalTitleAtom } from "../../contexts/modal.recoil";
import "./topModal.scss";

const TopModal: React.FC = () => {
	const [render, setRender] = useRecoilState(modalRenderAtom);
	const [title, setTitle] = useRecoilState(modalTitleAtom);

	useEffect(() => {
		return () => {
			setTitle(undefined);
		};
	}, [setTitle]);

	const onCloseClick = () => {
		setRender(undefined);
	};

	if (!render) {
		return null;
	}

	return (
		<div className="top-modal" onClick={() => onCloseClick()}>
			<div
				className="tm-card animate__animated animate__fadeInUp animate__faster"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="tm-card-header">
					<div className="title">{title}</div>
					<i
						className="material-icons close-icon  w-5 h-5 self-end ml-auto hover:bg-gray-300 rounded-full cursor-pointer"
						onClick={() => onCloseClick()}
					>
						close
					</i>
				</div>
				<div className="tm-content">{render}</div>
			</div>
		</div>
	);
};

export default TopModal;
