import React from "react";
import { useState, useCallback, useContext } from "react";
import "./expectationsAndRoutinesArea.scss";
import { useSetRecoilState } from "recoil";
import { modalRenderAtom, modalTitleAtom } from "../../../contexts/modal.recoil";
import { ExpectationsAndRoutinesHistoryModal } from "./expectationsAndRoutinesHistoryModal/ExpectationsAndRoutinesHistoryModal";
import { healthChecksDataTable } from "../../../utils/deleteme/formsData";
export const ExpectationsAndRoutinesArea = () => {
	const [showFeatureText, setShowFeatureText] = useState(false);
	const [lastCompletedDate, setLastCompletedDate] = useState("[MM/DD/YYYY]");
	const [completedByName, setCompletedByName] = useState("[First Last Name]");

	const setModalRender = useSetRecoilState(modalRenderAtom);
	const setModalTitle = useSetRecoilState(modalTitleAtom);

	const handleButtonClick = () => {
		if (!showFeatureText) {
			window.location.href = "mcd-forms/?m=fsl&form=2&submission=2";
		} else {
			setShowFeatureText(true);
		}
	};
	const handleViewHistoryClick = useCallback(() => {
		setModalTitle("Expectations & Routines History");
		setModalRender(<ExpectationsAndRoutinesHistoryModal data={healthChecksDataTable} />);
	}, [setModalTitle, setModalRender]);
	return (
		<div className="expectationsAndRoutinesArea">
			<div className="header">Expectations & Routines</div>
			<div className="completed-section">
				<div className="completed-info">
					<div>Last Completed: {lastCompletedDate}</div>
					<div>
						{" "}
						Completed By: {completedByName} | {""}
						<span className="view-history-link" onClick={handleViewHistoryClick}>
							View History
						</span>
					</div>
				</div>
				<button
					className={`review-update-button ${showFeatureText ? "feature-text-show" : ""}`}
					onClick={handleButtonClick}
					disabled={showFeatureText}
				>
					{showFeatureText ? "Feature Coming Soon!" : "Review/Update"}
				</button>{" "}
			</div>
		</div>
	);
};
