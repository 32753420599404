import React, { useContext } from "react";
import { imgPath } from "../../../../constants/PathName";
import { UserContext } from "../../../../contexts/UserContext";
import ParseHTMLContent from "../../../../components/ParseHTMLContent/ParseHTMLContent";

const FlashCardList = ({ flashcards, onClick, taskStatusState }) => {
	const { store } = useContext(UserContext);
	const { translation } = store;

	return (
		<div className="row">
			<div className="col-xs-12">
				{flashcards &&
					flashcards.length > 0 &&
					flashcards.map((f) => {
						const fields = f.flashcardFields;
						return (
							<div className={`col-xs-12 col-sm-6 col-md-3 `} key={fields.id}>
								{taskStatusState && taskStatusState[fields.taskId] === "1" && (
									<img src={imgPath + "/icon-check1.svg"} className="quiz-completed" alt="" />
								)}
								<a
									href={fields.url}
									className={`flashcard ${fields.class}`}
									onClick={(e) => {
										e.preventDefault();
										onClick(f);
									}}
								>
									<ParseHTMLContent htmlString={translation(fields.title, fields.titleEs)} />
								</a>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default FlashCardList;
