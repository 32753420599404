export enum EnvTypes {
	Production = "production",
	Staging = "staging",
	Development = "development",
}

export enum BuildTypes {
	Web = "web",
	Android = "android",
	iOS = "ios",
}

export interface Env {
	REACT_APP_ENV_TYPE: EnvTypes;
	PUBLIC_URL: string;
	REACT_APP_API_BASE_URL: string;
	REACT_APP_BUILD_TYPE: BuildTypes;
	REACT_APP_MCD_LOGIN_URL: string;
	REACT_APP_MCD_LOGOUT_URL: string;
	REACT_APP_ROLLBAR_ACCESS_TOKEN: string;
}

let env: Env;

const loadEnv = <TKey extends keyof Env, TVal extends Env[TKey]>(
	envKey: TKey,
	options: {
		isOptional?: boolean;
		isNumber?: boolean;
		defaultValue?: TVal;
		validValues?: TVal[];
	} = {
		isOptional: false,
		isNumber: false,
	},
) => {
	const envVal = process.env[envKey];

	if (envVal === undefined) {
		if (options.defaultValue !== undefined) {
			env![envKey] = options.defaultValue as never;
			return;
		} else if (!options.isOptional) {
			throw Error(`env ${envKey} is required`);
		} else {
			return;
		}
	}

	if (options.isNumber) {
		const numEnvVal = parseInt(envVal as string);
		if (isNaN(numEnvVal)) {
			throw Error(`env ${envKey}=${envVal} must be a number`);
		}

		if (
			options.validValues &&
			!options.validValues.some((validValue) => validValue === (numEnvVal as never))
		) {
			throw Error(
				`Invalid env ${envKey}=${envVal}, valid values are ${options.validValues.join(", ")}`,
			);
		}

		env![envKey] = numEnvVal as never;
	} else {
		if (options.validValues && !options.validValues.some((validValue) => validValue === envVal)) {
			throw Error(
				`Invalid env ${envKey}=${envVal}, valid env are ${options.validValues.join(", ")}`,
			);
		}

		env![envKey] = envVal as never;
	}
};

const init = () => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (env !== undefined) return;
	env = {} as any;

	loadEnv("REACT_APP_ENV_TYPE", {
		validValues: Object.values(EnvTypes),
	});
	loadEnv("PUBLIC_URL");
	loadEnv("REACT_APP_BUILD_TYPE", {
		validValues: Object.values(BuildTypes),
	});
	loadEnv("REACT_APP_API_BASE_URL");
	loadEnv("REACT_APP_MCD_LOGIN_URL");
	loadEnv("REACT_APP_MCD_LOGOUT_URL");
	loadEnv("REACT_APP_ROLLBAR_ACCESS_TOKEN", {
		isOptional: true,
	});
};

init();

export default env!;
