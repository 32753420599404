import React from "react";
import { FormHeader } from "./formHeader/FormHeader";
import { FormContent } from "./formContent/FormContent";
import { FormFooter } from "./formFooter/FormFooter";
export const FormDetail = () => {
	return (
		<div className="formDetail">
			<FormHeader />
			<FormContent></FormContent>
			<FormFooter></FormFooter>
		</div>
	);
};
