import { gql, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import Loading from "../../../../components/Loading/Loading";
import { UserContext } from "../../../../contexts/UserContext";
import useQueryParams from "../../../../hooks/useQueryParams";
import { in_array } from "../../../../utils/utils";
import LpCard from "./LpCard/LpCard";
import ParseHTMLContent from "../../../../components/ParseHTMLContent/ParseHTMLContent";

const DASHBOARD_CARDS_QUERY = gql`
	query GetDashboardCards(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$initiativeSlug: [String]
		$userEid: String
	) {
		dashboardCards(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				dashboardCardFields {
					parent
					class
					title
					titleEs
					description
					descriptionEs
					cardId
				}
			}
		}
		intros(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				introFields {
					content
					contentEs
					id
					parent
					title
					titleEs
					url
				}
			}
		}
	}
`;

const DashboardTab = ({ dashboardTab, tabCount, user_badges }) => {
	const query = useQueryParams();
	const t = query.get("t"); //@TODO verify combine with p = 'whats new', should get value t = 'all'

	const { store } = useContext(UserContext);
	const { translation, permissionGroup, userData } = store;

	const { loading, error, data } = useQuery(DASHBOARD_CARDS_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	if (loading) return <Loading />;
	if (error) return <p>Unable to find dashboard cards :(</p>;

	const dashboardCards = data.dashboardCards.nodes || [];
	const $dashboardNoCardsContent = data.intros.nodes || [];

	return (
		<div id={dashboardTab["htmlid"]} role="tabpanel" className="tab-pane active">
			{(() => {
				const $numOfCols = 3;
				const $bootstrapColWidth = 12 / $numOfCols;
				return (
					<div className="row">
						{dashboardCards.map(({ dashboardCardFields }, key) => {
							return (
								<React.Fragment key={"dcf-" + key}>
									{in_array(dashboardTab["id"], dashboardCardFields["parent"]) && (
										<LpCard
											card={dashboardCardFields}
											bootstrapColWidth={$bootstrapColWidth}
											user_badges={user_badges}
										/>
									)}
								</React.Fragment>
							);
						})}
						{$dashboardNoCardsContent.map(({ introFields }, key) => {
							return (
								<React.Fragment key={"if-" + key}>
									{in_array(dashboardTab["id"], introFields["parent"]) && (
										<>
											<div className="col-xs-12">
												<h3>
													<ParseHTMLContent
														htmlString={translation(introFields["title"], introFields["titleEs"])}
													/>
												</h3>
												<div>
													<ParseHTMLContent
														htmlString={translation(
															introFields["content"],
															introFields["contentEs"],
														)}
													/>
												</div>
											</div>
										</>
									)}
								</React.Fragment>
							);
						})}
					</div>
				);
			})()}
		</div>
	);
};

export default DashboardTab;
