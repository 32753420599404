import { useState, useContext, useEffect } from "react";
import FlashCardList from "./components/list";
import Quiz from "./components/quiz/Quiz";

import "../../styles/mcd-flashcards.scss";
import { UserContext } from "../../contexts/UserContext";
import Loading from "../../components/Loading/Loading";
import { calculate_top_margin, setPageTitle } from "../../utils/utils";
import useProduct from "../../hooks/useProduct";
import useQueryParams from "../../hooks/useQueryParams";
import {
	Flashcard,
	useGetFlashCardsQuery,
	useGetTaskStatusLazyQuery,
	useUpdateMyUserTaskMutation,
} from "../../generated/graphql";

const FlashCards: React.FC = () => {
	const [currentFlashcard, setCurrentFlashcard] = useState<Flashcard | null>(null);
	const [taskStatusState, setTaskStatusState] = useState<string | null>(null);
	const { store } = useContext(UserContext);
	const { translation, permissionGroup, personID, userData } = store;
	const product = useProduct();
	const query = useQueryParams();
	const module = query.get("m");

	const { loading, error, data } = useGetFlashCardsQuery({
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
			initiativeSlug: module,
		},
	});

	const [getTaskStatus, taskStatusRes] = useGetTaskStatusLazyQuery();
	const [updateUserTask, updateUserTaskRes] = useUpdateMyUserTaskMutation();

	const updateTaskComplete = async () => {
		if (currentFlashcard?.flashcardFields?.taskId != null) {
			const taskToUpdate = currentFlashcard.flashcardFields.taskId.replaceAll("-", "_");
			await updateUserTask({
				variables: {
					personID,
					userTask: taskToUpdate,
					taskValue: 1,
				},
			});
		}

		//clear current task
		setCurrentFlashcard(null);
		taskStatusRes.refetch();
	};

	useEffect(() => {
		setPageTitle(product);
	}, []);

	useEffect(() => {
		if (data?.flashcards?.nodes == null) {
			return;
		}
		if (data?.flashcards.nodes.length > 0) {
			//get tasks to get statuses from
			const filteredFlashcardsWithIds = data.flashcards.nodes.filter((fc: Flashcard | any) => {
				return fc.flashcardFields.taskId !== null;
			});
			const flashcardsIDs = filteredFlashcardsWithIds.map((fc: Flashcard | any) => {
				return fc.flashcardFields.taskId.replaceAll("-", "_");
			});
			if (flashcardsIDs.length > 0) {
				getTaskStatus({
					variables: {
						personID,
						taskIDs: flashcardsIDs,
					},
				});
			}
		}
	}, [data]);

	useEffect(() => {
		if (taskStatusRes.data?.getTaskStatus == null) {
			return;
		}
		if (taskStatusRes.data) {
			const fixHyphenCase = taskStatusRes.data.getTaskStatus.replaceAll("_", "-");
			const resJson = JSON.parse(fixHyphenCase);
			setTaskStatusState(resJson);
		}
	}, [taskStatusRes.data]);

	if (loading) return <Loading />;
	if (error) return <p>Unable to load flashcards :(</p>;

	const flashCards = data?.flashcards?.nodes || [];

	return (
		<div className="container-fluid main" style={{ ...calculate_top_margin(product) }}>
			{!currentFlashcard && (
				<>
					<h1>{translation("Reinforce your knowledge", "Reforza tus conocimientos")}</h1>
					<p>
						{translation(
							"Select a card set and see how you do!",
							"Selecciona una tarjeta para probar!",
						)}
					</p>
					{flashCards && flashCards.length > 0 && (
						<FlashCardList
							flashcards={flashCards}
							onClick={(flashCard: Flashcard) => {
								setCurrentFlashcard(flashCard);
							}}
							taskStatusState={taskStatusState}
						/>
					)}
				</>
			)}
			{currentFlashcard && (
				<Quiz
					flashcard={currentFlashcard}
					exit={() => {
						setCurrentFlashcard(null);
					}}
					updateTask={() => updateTaskComplete()}
					completedTaskLoading={updateUserTaskRes.loading}
				/>
			)}
		</div>
	);
};

export default FlashCards;
