import { gql, useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { imgPath, PathName } from "../../constants/PathName";
import { UserContext } from "../../contexts/UserContext";
import useProduct from "../../hooks/useProduct";
import useQueryParams from "../../hooks/useQueryParams";
import { calculate_top_margin, setPageTitle } from "../../utils/utils";
import "../../styles/mcd-laygo.scss";
import ParseHTMLContent from "../../components/ParseHTMLContent/ParseHTMLContent";
import ParseUrlTarget from "../../components/ParseUrlTarget/ParseUrlTarget";
import { NotAccessible } from "../../components/NotAccessible/NotAccessible";

const LAYGO_QUERY = gql`
	query GetLAYGoQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$initiativeSlug: [String]
		$userEid: String
	) {
		laygoCategories(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				categoryField {
					class
					featured
					url
					title
					titleEs
					description
					descriptionEs
					categoryID: id
				}
				metaFields {
					sortOrder
				}
			}
		}
		laygoHomeHeaders(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				laygoHomepageHeaderFields {
					en
					es
					fieldGroupName
					homeHeaderID: id
				}
				metaFields {
					arrayKey
					sortOrder
				}
			}
		}
		laygoLinks(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				linkFields {
					fieldGroupName
					linkID: id
					target
					title
					titleEs
					url
				}
				metaFields {
					sortOrder
				}
			}
		}
		laygos(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				learnAsYouGoTopicFields {
					class
					fieldGroupName
					laygoID: id
					parent
					title
					titleEs
					url
				}
				metaFields {
					sortOrder
				}
			}
		}
	}
`;
const LAYGO_QUESTIONS_QUERY = gql`
	query GetLAYGoQuestionsQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$initiativeSlug: [String]
		$questionParent: [String]
		$userEid: String
	) {
		laygoQuestions(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				questionParent: $questionParent
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				learnAsYouGoQuestionsFields {
					class
					content
					contentEs
					description
					descriptionEs
					featured
					fieldGroupName
					id
					key
					parent
					product
					tags
					title
					titleEs
					url
				}
				metaFields {
					sortOrder
				}
			}
		}
	}
`;

const LAYGo = () => {
	//state hooks

	//recoils - context
	const { store } = useContext(UserContext);
	const { translation, userLanguage, permissionGroup, userData } = store;

	//custom hooks
	const query = useQueryParams();
	const product = useProduct();
	const qModule = query.get("m");
	const qPageID = query.get("p");
	const q = query.get("q"); //@todo figure out what q might be
	const qSection = query.get("s");
	const qSubSection1 = query.get("ss1");

	const { loading, error, data } = useQuery(LAYGO_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			initiativeSlug: qModule,
			userEid: userData.eID,
		},
	});

	const [getLaygoQuestions, { loading: quesLoading, error: quesError, data: quesData }] =
		useLazyQuery(LAYGO_QUESTIONS_QUERY, {
			variables: {
				acceptAccessRole: permissionGroup.accessLevel,
				acceptCompanyType: permissionGroup.companyType,
				acceptCustomRole: permissionGroup.customRole,
				initiativeSlug: qModule,
				userEid: userData.eID,
				questionParent: [qPageID, qSection, qSubSection1],
			},
		});

	//local variables

	const homepath = PathName.DASHBOARD;

	//functions
	// this function returns an integer if it finds featured categories, otherwise it returns false
	function array_search_multidim($array, $key) {
		let hasFeatured = false;

		const hasFeaturedArray = $array.find(({ categoryField }) => categoryField.featured === $key);
		if (hasFeaturedArray) {
			hasFeatured = true;
		}

		return hasFeatured;
	}

	function getHeaderParts($array) {
		let headerAll, headerFeatured, headerLinks;
		$array.forEach(({ laygoHomepageHeaderFields, metaFields }) => {
			if (metaFields.arrayKey === "all") {
				headerAll = laygoHomepageHeaderFields;
			}
			if (metaFields.arrayKey === "featured") {
				headerFeatured = laygoHomepageHeaderFields;
			}
			if (metaFields.arrayKey === "links") {
				headerLinks = laygoHomepageHeaderFields;
			}
		});
		return { headerAll, headerFeatured, headerLinks };
	}

	const search = () => {};

	//React hooks
	useEffect(() => {
		setPageTitle(product);
	}, []);

	useEffect(() => {
		getLaygoQuestions();
	}, [qPageID, qSection, qSubSection1]);

	//jsx variables
	if (loading || quesLoading) return <Loading />;
	if (error || quesError) return <NotAccessible />;

	const homepageAllCategories = data.laygoCategories.nodes || [];
	const homepageHeaders = data.laygoHomeHeaders.nodes || [];
	const homepageLinks = data.laygoLinks.nodes || [];
	const laygoQuestions = quesData?.laygoQuestions.nodes || [];
	const LaygoTopics = data.laygos.nodes || [];

	// use it
	const has_featured_section = array_search_multidim(homepageAllCategories, "yes");
	const { headerAll, headerFeatured, headerLinks } = getHeaderParts(homepageHeaders);
	return (
		<div className="col-xs-12" style={{ padding: 0 }}>
			{qPageID === "home" ||
				qPageID === "" ||
				(qPageID === null && (
					<div className="container-fluid banner-home">
						<div className="row">
							<div className="col-xs-12 text-center">
								<p className="banner-text-secondary">
									<i>
										{translation(
											"How-To, Troubleshooting, and Best Practices",
											" Guía básica, identificación y solución de problemas, y mejores prácticas",
										)}
									</i>
								</p>
							</div>
						</div>
					</div>
				))}
			<div
				id="main"
				className="container-fluid main"
				style={{ ...calculate_top_margin(product, qModule) }}
			>
				{qPageID === "home" || qPageID === "" || qPageID === null ? ( //if q is empty and qProduct is empty or set to home, then build the homepage
					<>
						{has_featured_section && (
							<div className="row">
								<div className="col-xs-12">
									{headerFeatured && (
										<h5 className="section-title-homepage">
											<ParseHTMLContent
												htmlString={translation(headerFeatured["en"], headerFeatured["es"])}
											/>
										</h5>
									)}
								</div>
								{/* loop through and show featured categories */}
								{homepageAllCategories.map(({ categoryField: featured }, key) => {
									return (
										<React.Fragment key={key}>
											{featured["featured"] === "yes" && (
												<div className="col-xs-6 col-md-3">
													<Link
														to={homepath + product + "/" + featured["url"] + "&l=" + userLanguage}
													>
														<div className={"card-featured  " + featured["class"]}>
															<div className="title-desc">
																<h1>
																	<ParseHTMLContent
																		htmlString={translation(featured["title"], featured["titleEs"])}
																	/>
																</h1>
																<p>
																	<ParseHTMLContent
																		htmlString={translation(
																			featured["description"],
																			featured["descriptionEs"],
																		)}
																	/>
																</p>
															</div>
															<div className="card-chevron">
																<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.71 35">
																	<path d="M3.51.37L.37 3.51a1.25 1.25 0 0 0 0 1.77L12.58 17.5.37 29.72a1.25 1.25 0 0 0 0 1.77l3.14 3.14a1.25 1.25 0 0 0 1.77 0L21.22 18.7a1.7 1.7 0 0 0 0-2.4L5.28.37a1.25 1.25 0 0 0-1.77 0z" />
																</svg>
															</div>
														</div>
													</Link>
												</div>
											)}
										</React.Fragment>
									);
								})}
							</div>
						)}

						<div className="row">
							<div className="col-xs-12">
								<h5 className="section-title-homepage">
									{headerAll && (
										<span>
											<ParseHTMLContent
												htmlString={translation(headerAll["en"], headerAll["es"])}
											/>
										</span>
									)}
								</h5>
							</div>

							{homepageAllCategories.map(({ categoryField: category }, key) => {
								return (
									<React.Fragment key={key}>
										{category["featured"] !== "yes" && (
											<div className="col-xs-12 col-sm-6">
												<Link
													to={homepath + product + "/" + category["url"] + "&l=" + userLanguage}
												>
													<div className={"card-primary " + category["class"]}>
														<img
															src={
																imgPath + "/laygo-icons/icon-laygo-" + category["class"] + ".svg"
															}
															alt=""
														/>
														<p className="card-text-primary">
															<ParseHTMLContent
																htmlString={translation(category["title"], category["titleEs"])}
															/>
														</p>
														<span className="icon">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.71 35">
																<path d="M3.51.37L.37 3.51a1.25 1.25 0 0 0 0 1.77L12.58 17.5.37 29.72a1.25 1.25 0 0 0 0 1.77l3.14 3.14a1.25 1.25 0 0 0 1.77 0L21.22 18.7a1.7 1.7 0 0 0 0-2.4L5.28.37a1.25 1.25 0 0 0-1.77 0z" />
															</svg>
														</span>
													</div>
												</Link>
											</div>
										)}
									</React.Fragment>
								);
							})}
						</div>

						<div className="row last">
							<div className="col-xs-12">
								<h5 className="section-title-homepage">
									{headerLinks && (
										<span>
											<ParseHTMLContent
												htmlString={translation(headerLinks["en"], headerLinks["es"])}
											/>
										</span>
									)}
								</h5>
							</div>
							{homepageLinks.map(({ linkFields: link }, key) => {
								return (
									<div className="col-xs-12 col-md-3" key={key}>
										<ul className="links">
											<li>
												<ParseUrlTarget url={link["url"]} target={link["target"]}>
													<ParseHTMLContent
														htmlString={translation(link["title"], link["titleEs"])}
													/>
												</ParseUrlTarget>
											</li>
										</ul>
									</div>
								);
							})}
						</div>
					</>
				) : q ? ( //try to build search
					<>
						{/*Let's call the search function to perform a search **/}
						{search(laygoQuestions, q, userLanguage)}
					</>
				) : (
					<div className="row">
						{qSection === qPageID ? ( //if these are equal, that means pull from the $laygo_topics array
							<>
								{laygoQuestions.map(({ learnAsYouGoQuestionsFields: question }, key) => {
									return (
										<React.Fragment key={key}>
											{qPageID === question["parent"] && (
												<div className="col-xs-12">
													<ul className={"checklist-" + question["class"]}>
														<Link to={question["url"] + "&l=" + userLanguage}>
															<li>
																<ParseHTMLContent
																	htmlString={translation(question["title"], question["titleEs"])}
																/>
															</li>
														</Link>
													</ul>
												</div>
											)}
										</React.Fragment>
									);
								})}
								{
									LaygoTopics.map(({ learnAsYouGoTopicFields: topic, key }) => {
										return (
											<React.Fragment key={key}>
												{qPageID === topic["parent"] && ( //@TODO see if need parent or id attribute
													<div className="col-xs-12">
														<Link
															to={
																PathName.DASHBOARD +
																product +
																"/" +
																topic["url"] +
																"&l=" +
																userLanguage
															}
														>
															<div className="card-primary subcat">
																<img
																	src={
																		imgPath + "/laygo-icons/icon-laygo-" + topic["class"] + ".svg"
																	}
																	alt=""
																/>
																<p className={"card-text-secondary " + topic["class"]}>
																	<ParseHTMLContent
																		htmlString={translation(topic["title"], topic["titleEs"])}
																	/>
																</p>
																<span className="card-chevron subcat">
																	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.71 35">
																		<path d="M3.51.37L.37 3.51a1.25 1.25 0 0 0 0 1.77L12.58 17.5.37 29.72a1.25 1.25 0 0 0 0 1.77l3.14 3.14a1.25 1.25 0 0 0 1.77 0L21.22 18.7a1.7 1.7 0 0 0 0-2.4L5.28.37a1.25 1.25 0 0 0-1.77 0z" />
																	</svg>
																</span>
															</div>
														</Link>
													</div>
												)}
											</React.Fragment>
										);
									}) //end loop
								}
							</>
						) : qSubSection1 === qPageID ? ( //then pull from the $laygo_questions, but only to create a list of questions
							laygoQuestions.map(({ learnAsYouGoQuestionsFields: question }, key) => {
								return (
									<React.Fragment key={key}>
										{qPageID === question["parent"] && ( //@TODO see if need parent or id attribute
											<div className="col-xs-12 ">
												<ul className={"checklist-" + question["class"] + " clearfix"}>
													<img
														src={
															imgPath +
															"/laygo-icons/icon-laygo-" +
															question["class"] +
															"-secondary" +
															".svg"
														}
														alt=""
														width={32}
														height={32}
													/>
													<Link
														to={
															PathName.DASHBOARD +
															product +
															"/" +
															question["url"] +
															"&l=" +
															userLanguage
														}
													>
														<ParseHTMLContent
															htmlString={translation(question["title"], question["titleEs"])}
														/>
													</Link>
												</ul>
											</div>
										)}
									</React.Fragment>
								);
							}) //end loop
						) : (
							// otherwise try to build the question page

							laygoQuestions.map(({ learnAsYouGoQuestionsFields: questions }, key) => {
								return (
									<React.Fragment key={key}>
										{questions["url"].endsWith(qPageID) &&
											(qSubSection1 === questions["parent"] ||
												qSection === questions["parent"]) && (
												<div className="col-xs-12">
													<h5 className="topics-heading">
														<ParseHTMLContent
															htmlString={translation(questions["title"], questions["titleEs"])}
														/>
													</h5>
													<div>
														<ParseHTMLContent
															htmlString={translation(questions["content"], questions["contentEs"])}
														/>
													</div>
												</div>
											)}
									</React.Fragment>
								);
							})
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default LAYGo;
