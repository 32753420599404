import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { imgPath, videoPath } from "../../../../constants/PathName";
import { UserContext } from "../../../../contexts/UserContext";
import useQueryParams from "../../../../hooks/useQueryParams";
import { empty, isset, substr, ucfirst } from "../../../../utils/utils";
import ParseHTMLContent from "../../../../components/ParseHTMLContent/ParseHTMLContent";

const ResourceItem: React.FC<any> = ({ resource, isFeatured }) => {
	const { store }: any = useContext(UserContext);
	const { translation } = store;
	const query = useQueryParams();
	const view = query.get("view");
	const videoStyle = {
		background:
			resource["resourcetype"] === "video" || resource["resourcetype"] === "webcast"
				? "url('" + videoPath + "/" + resource["thumbnail"] + "') #dedede no-repeat center center"
				: "url('" + imgPath + "/" + resource["icon"] + ".png') no-repeat center center",
		backgroundSize:
			resource["resourcetype"] === "video" || resource["resourcetype"] === "webcast"
				? "cover"
				: "contain",
	};
	return (
		<div
			id="resourceItem"
			data-type={resource["category"] === "" ? "null" : resource["category"]}
			data-initiative={resource["initiative"] === "" ? "null" : resource["initiative"]}
			data-lang={resource["language"] === "" ? "null" : resource["language"]}
			className={"col-xs-12  " + (!isset(view) || view === "card" ? "col-sm-4 col-lg-3 " : "")}
			style={{ height: view === "list" ? "200px" : "350px" }}
		>
			<div className="row">
				<div className={!isset(view) || view === "card" ? "col-xs-12" : "col-xs-3 col-sm-2"}>
					{isFeatured && (
						<svg
							className="featured-resource"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 35 35"
						>
							<path
								className="cls-1"
								d="M17.13.87,20.8,8.74a1.49,1.49,0,0,0,1.14.85l8.34,1.29a1.5,1.5,0,0,1,.85,2.52L25,19.74a1.49,1.49,0,0,0-.4,1.29L26,29.89a1.5,1.5,0,0,1-2.21,1.55l-7.3-4.06a1.46,1.46,0,0,0-1.46,0L7.75,31.44a1.5,1.5,0,0,1-2.22-1.55L7,21a1.49,1.49,0,0,0-.4-1.29L.42,13.4a1.5,1.5,0,0,1,.85-2.52L9.61,9.59a1.49,1.49,0,0,0,1.14-.85L14.42.87A1.49,1.49,0,0,1,17.13.87Z"
							/>
							<path d="M17.13.87,20.8,8.74a1.49,1.49,0,0,0,1.14.85l8.34,1.29a1.5,1.5,0,0,1,.85,2.52L25,19.74a1.49,1.49,0,0,0-.4,1.29L26,29.89a1.5,1.5,0,0,1-2.21,1.55l-7.3-4.06a1.46,1.46,0,0,0-1.46,0L7.75,31.44a1.5,1.5,0,0,1-2.22-1.55L7,21a1.49,1.49,0,0,0-.4-1.29L.42,13.4a1.5,1.5,0,0,1,.85-2.52L9.61,9.59a1.49,1.49,0,0,0,1.14-.85L14.42.87A1.49,1.49,0,0,1,17.13.87Z" />
						</svg>
					)}
					<Link to={resource["url"]}>
						<div className="resource-thumbnail" style={videoStyle}>
							{(resource["resourcetype"] === "video" || resource["resourcetype"] === "webcast") && (
								<img src={imgPath + "/play.png"} alt="" />
							)}
						</div>
					</Link>
				</div>
				<div className={!isset(view) || view === "card" ? "col-xs-12" : "col-xs-9 col-sm-10"}>
					<h4>
						<Link to={resource["url"]}>
							<ParseHTMLContent htmlString={resource["title"] || ""} />
						</Link>
					</h4>
					<p className="description">
						<ParseHTMLContent
							htmlString={((): string => {
								if (!empty(resource["excerpt"])) {
									return resource["excerpt"];
								} else {
									const description = resource["content"] || "";
									const exerpt = substr(description, 0, 100);
									if (exerpt.length < 100) {
										return exerpt;
									} else {
										return exerpt + "...";
									}
								}
							})()}
						/>
					</p>
					<ul style={{ listStyle: "none", paddingLeft: 0 }}>
						<li>
							{translation("Date: ", "Fecha: ")}
							{resource["date"]}
						</li>
						<li>
							{translation("Languages: ", "Idiomas: ")}
							{ucfirst(resource["language"])}
						</li>
						{(resource["resourcetype"] === "video" || resource["resourcetype"] === "webcast") && (
							<li>
								{translation("Duration: ", "Duración: ")}
								{resource["duration"]}
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ResourceItem;
