import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import { UserContext } from "../../../contexts/UserContext";
import useProduct from "../../../hooks/useProduct";
import useQueryParams from "../../../hooks/useQueryParams";
import { calculate_top_margin, empty, in_array, isset, strtotime } from "../../../utils/utils";
import ResourceItem from "./ResourceItem/ResourceItem";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";

const ResourceItems: React.FC<any> = ({ resourceLibrary, dashboardModules }) => {
	const { store }: any = useContext(UserContext);
	const { translation, userData } = store;
	const query = useQueryParams();
	const module = query.get("m");
	const subModule = query.get("msub");
	const product = useProduct();

	const view = query.get("view") || "list";
	const sort = query.get("sort") || "new";
	const initiative = query.get("initiative") || "all";
	const type = query.get("type") || "all";
	const language = query.get("language") || "all";

	const filterLink = (key: string, value: string) => {
		const linkView = key === "view" ? value : view;
		const linkSort = key === "sort" ? value : sort;
		const linkInitiative = key === "initiative" ? value : initiative;
		const linkType = key === "type" ? value : type;
		const linkLanguage = key === "language" ? value : language;

		return (
			PathName.RESOURCES +
			"?m=" +
			module +
			"&msub=" +
			subModule +
			"&view=" +
			linkView +
			"&sort=" +
			linkSort +
			"&initiative=" +
			linkInitiative +
			"&type=" +
			linkType +
			"&language=" +
			linkLanguage
		);
	};

	const filterResourceLibrary = (rl: any, sort: string) => {
		let rltemp = [...rl];
		//INITIATIVE BOX
		if (initiative !== "all") {
			rltemp = rltemp.filter((el) => {
				return el.resourceLibraryFields["initiative"].includes(initiative);
			});
		}

		//TYPE BOX
		if (type !== "all") {
			rltemp = rltemp.filter((el) => {
				return el.resourceLibraryFields["resourcetype"].includes(type);
			});
		}

		//LANGUAGE BOX
		if (language !== "all") {
			rltemp = rltemp.filter((el) => {
				return el.resourceLibraryFields["language"].includes(language);
			});
		}

		//SORT BOX
		if (sort === "new") {
			rltemp.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
				const t1 = strtotime(a.resourceLibraryFields["date"]);
				const t2 = strtotime(b.resourceLibraryFields["date"]);
				return t2 - t1;
			});
		} else if (sort === "old") {
			rltemp.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
				const t1 = strtotime(a.resourceLibraryFields["date"]);
				const t2 = strtotime(b.resourceLibraryFields["date"]);
				return t1 - t2;
			});
		} else if (sort === "AZ") {
			rltemp.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
				return a.resourceLibraryFields["id"].localeCompare(b.resourceLibraryFields["id"]);
			});
		} else {
			//sort ZA
			rltemp.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
				return b.resourceLibraryFields["id"].localeCompare(a.resourceLibraryFields["id"]);
			});
		}

		return rltemp;
	};

	const filteredResouces = filterResourceLibrary(resourceLibrary, sort);
	return (
		<div>
			<div id="main" className="container-fluid main" style={{ ...calculate_top_margin(product) }}>
				<div className="row">
					<div className="col-xs-12 col-sm-3 resource-sub-nav">
						<div id="toggleFilters" className="anc toggle-filters ">
							<svg
								id="iconMinus"
								className="icon"
								xmlns="http:// www.w3.org/2000/svg"
								viewBox="0 0 35 35"
							>
								<path d="M1.08,20.87H33.92A1.24,1.24,0,0,0,35,19.67V15.33a1.24,1.24,0,0,0-1.23-1.23H1.23A1.23,1.23,0,0,0,0,15.33v4.34A1.23,1.23,0,0,0,1.08,20.87Z" />
								<path d="M1.08,20.87H33.92A1.24,1.24,0,0,0,35,19.67V15.33a1.24,1.24,0,0,0-1.23-1.23H1.23A1.23,1.23,0,0,0,0,15.33v4.34A1.23,1.23,0,0,0,1.08,20.87Z" />
							</svg>
							<svg
								id="iconPlus"
								className="icon hidden"
								xmlns="http:// www.w3.org/2000/svg"
								viewBox="0 0 35 35"
							>
								<path
									className="cls-1"
									d="M20.89,14.11H33.77A1.24,1.24,0,0,1,35,15.33v4.34a1.24,1.24,0,0,1-1.24,1.23H20.89V33.77A1.23,1.23,0,0,1,19.67,35H15.33a1.24,1.24,0,0,1-1.23-1.23V20.89H1.23A1.24,1.24,0,0,1,0,19.67V15.33A1.23,1.23,0,0,1,1.23,14.1H14.1V1.23A1.23,1.23,0,0,1,15.33,0h4.34A1.24,1.24,0,0,1,20.9,1.23Z"
								/>
								<path d="M20.89,14.11H33.77A1.24,1.24,0,0,1,35,15.33v4.34a1.24,1.24,0,0,1-1.24,1.23H20.89V33.77A1.23,1.23,0,0,1,19.67,35H15.33a1.24,1.24,0,0,1-1.23-1.23V20.89H1.23A1.24,1.24,0,0,1,0,19.67V15.33A1.23,1.23,0,0,1,1.23,14.1H14.1V1.23A1.23,1.23,0,0,1,15.33,0h4.34A1.24,1.24,0,0,1,20.9,1.23Z" />
							</svg>
							Filters
						</div>
						<hr />
						<div className="filters">
							<ul className="resource-view">
								<li className="title">{translation("View:", "Vista:")}</li>
								<Link
									id="viewList"
									to={filterLink("view", "list")}
									className={view === "list" ? "selected" : ""}
								>
									<li>
										<svg xmlns="http:// www.w3.org/2000/svg" viewBox="0 0 35 30.63">
											<rect width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" width="26.83" height="5.79" rx="1.15" />
											<rect y="8.34" width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" y="8.34" width="26.83" height="5.79" rx="1.15" />
											<rect y="16.5" width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" y="16.5" width="26.83" height="5.79" rx="1.15" />
											<rect y="24.84" width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" y="24.84" width="26.83" height="5.79" rx="1.15" />
											<rect width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" width="26.83" height="5.79" rx="1.15" />
											<rect y="8.34" width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" y="8.34" width="26.83" height="5.79" rx="1.15" />
											<rect y="16.5" width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" y="16.5" width="26.83" height="5.79" rx="1.15" />
											<rect y="24.84" width="5.79" height="5.79" rx="1.15" />
											<rect x="8.17" y="24.84" width="26.83" height="5.79" rx="1.15" />
										</svg>{" "}
										{translation("List", "Lista")}
									</li>
								</Link>
								<Link
									id="viewList"
									to={filterLink("view", "card")}
									className={view === "card" || empty(view) || !isset(view) ? "selected " : ""}
								>
									<li>
										<svg xmlns="http:// www.w3.org/2000/svg" viewBox="0 0 35 30.63">
											<rect width="15.75" height="13.68" rx="2.37" />
											<rect x="19.25" width="15.75" height="13.68" rx="2.37" />
											<rect y="16.95" width="15.75" height="13.68" rx="2.37" />
											<rect x="19.25" y="16.95" width="15.75" height="13.68" rx="2.37" />
											<rect width="15.75" height="13.68" rx="2.37" />
											<rect x="19.25" width="15.75" height="13.68" rx="2.37" />
											<rect y="16.95" width="15.75" height="13.68" rx="2.37" />
											<rect x="19.25" y="16.95" width="15.75" height="13.68" rx="2.37" />
										</svg>{" "}
										{translation("Cards", "Tarjetas")}
									</li>
								</Link>
							</ul>
							<ul className="resource-sort">
								<li className="title">{translation("Sort:", "Orden:")}</li>
								<Link
									id="sortNew"
									to={filterLink("sort", "new")}
									className={sort === "new" || sort === "" || sort === null ? "selected" : ""}
								>
									<li>Newest</li>
								</Link>
								<Link
									id="sortOld"
									to={filterLink("sort", "old")}
									className={sort === "old" ? "selected " : ""}
								>
									<li>Oldest</li>
								</Link>
								<Link
									id="sortAZ"
									to={filterLink("sort", "AZ")}
									className={sort === "AZ" ? "selected" : ""}
								>
									<li>A-Z</li>
								</Link>
								<Link
									id="sortZA"
									to={filterLink("sort", "ZA")}
									className={sort === "ZA" ? "selected " : ""}
								>
									<li>Z-A</li>
								</Link>
							</ul>

							<ul className="resource-filters">
								<li className="title">{translation("Initiative:", "Iniciativa:")}</li>

								<Link
									id="clearInitiatives"
									className={initiative === "all" ? "selected " : ""}
									to={filterLink("initiative", "all")}
								>
									<li>{translation("all", "todo")}</li>
								</Link>
								{dashboardModules.map(
									(module: { dashboardModulesFields: { [x: string]: any } }, key: any) => {
										if (empty(module.dashboardModulesFields["allowedEIDs"])) {
											return (
												<Link
													id="filterInitiative"
													className={
														initiative === module.dashboardModulesFields["id"] ? "selected " : ""
													}
													to={filterLink("initiative", module.dashboardModulesFields["id"])}
													key={module.dashboardModulesFields["id"] + key}
												>
													<li>
														<ParseHTMLContent
															htmlString={translation(
																module.dashboardModulesFields["title"],
																module.dashboardModulesFields["title_es"],
															)}
														/>
													</li>
												</Link>
											);
										} else if (
											in_array(userData.eID, module.dashboardModulesFields["allowedEIDs"])
										) {
											return (
												<Link
													id="filterInitiative"
													className={
														initiative === module.dashboardModulesFields["id"] ? "selected " : ""
													}
													to={filterLink("initiative", module.dashboardModulesFields["id"])}
													key={module.dashboardModulesFields["id"] + key}
												>
													<li>
														<ParseHTMLContent
															htmlString={translation(
																module.dashboardModulesFields["title"],
																module.dashboardModulesFields["title_es"],
															)}
														/>
													</li>
												</Link>
											);
										}
										return null;
									},
								)}
							</ul>
							<ul className="resource-filters">
								<li className="title">{translation("Type:", "Tipo:")}</li>

								<Link
									id="clearTypes"
									className={type === "all" ? "selected " : ""}
									to={filterLink("type", "all")}
								>
									<li>{translation("all", "todo")}</li>
								</Link>
								<Link
									id="filterType"
									className={type === "deck" ? "selected " : ""}
									to={filterLink("type", "deck")}
								>
									<li>{translation("deck", "presentación")}</li>
								</Link>
								<Link
									id="filterType"
									className={type === "document" ? "selected " : ""}
									to={filterLink("type", "document")}
								>
									<li>{translation("document", "documento")}</li>
								</Link>
								<Link
									id="filterType"
									className={type === "video" ? "selected " : ""}
									to={filterLink("type", "video")}
								>
									<li>{translation("video", "video")}</li>
								</Link>
							</ul>
							<ul className="resource-filters">
								<li className="title">{translation("Language:", "Lenguaje:")}</li>

								<Link
									id="clearLang"
									className={language === "all" ? "selected " : ""}
									to={filterLink("language", "all")}
								>
									<li>{translation("all", "todo")}</li>
								</Link>
								<Link
									id="filterLang"
									className={language === "english" ? "selected " : ""}
									to={filterLink("language", "english")}
								>
									<li>{translation("english", "inglés")}</li>
								</Link>
								<Link
									id="filterLang"
									className={language === "spanish" ? "selected " : ""}
									to={filterLink("language", "spanish")}
								>
									<li>{translation("spanish", "español")}</li>
								</Link>
							</ul>
						</div>
					</div>
					<div className="col-xs-12 col-sm-9 ">
						{(() => {
							/** Let's check if there are any featured items  */
							let counter = 0;
							filteredResouces.forEach(
								(featured_counter: { resourceLibraryFields: { [x: string]: string } }) => {
									if (featured_counter.resourceLibraryFields["featured"] === "yes") {
										counter++;
									}
								},
							);

							if (counter > 0) {
								return (
									<div id="featuredResources" className="row">
										<div className="col-xs-12">
											<h2>Featured Resources</h2>
											{
												filteredResouces.map(
													(
														resource: { resourceLibraryFields: { [x: string]: string } },
														key: any,
													) => {
														if (resource.resourceLibraryFields["featured"] === "yes") {
															return (
																<ResourceItem
																	resource={resource.resourceLibraryFields}
																	isFeatured
																	key={"resourceLibraryFields" + key}
																/>
															);
														} // end featured check
														return null;
													},
												) // end foreach loop
											}
											<div id="noResults" className="hidden">
												No featured items found
											</div>
										</div>
									</div>
								);
							} else {
								return (
									<div className="row">
										<div className="col-xs-12">No featured resources found</div>
									</div>
								);
							} // end show featured
						})()}
						<div id="allResources" className="row">
							<div className="col-xs-12">
								<h2>All Resources</h2>
								{
									filteredResouces.map(
										(resource: { resourceLibraryFields: { [x: string]: string } }, key: any) => {
											if (resource.resourceLibraryFields["featured"] === "no") {
												return (
													<ResourceItem
														resource={resource.resourceLibraryFields}
														isFeatured={false}
														key={"rlf" + key}
													/>
												);
											} // end featured check
											return null;
										},
									) // end foreach loop
								}
								<div id="noResults" className="hidden">
									No resources found
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResourceItems;
