import React, { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { empty, isset } from "../../../../utils/utils";
import GetCheckXorPer from "../../PathsCommon/GetCheckXorPer";
import usePathsFunctions from "../../usePathsFunctions";

const LprStoreHeaders = ({ lprHeaders, lprHeadersUserCount, lprStoreResults }) => {
	const { store } = useContext(UserContext);
	const { translation } = store;

	const { getNameArray } = usePathsFunctions();
	const nameArray = getNameArray();

	return (
		<>
			<tr className="tr-first">
				<th colspan="2" width="200"></th>
				{lprHeaders.map((value, key) => {
					return (
						<React.Fragment key={key}>
							{value !== "swr" && value !== "sup" && (
								<th colspan="1" width="200">
									{translation(nameArray[value]["en"], nameArray[value]["es"])}
									{isset(nameArray[value]["accessLevel"]) && (
										<>
											<br />
											<small>{nameArray[value]["accessLevel"]}</small>
										</>
									)}
								</th>
							)}
						</React.Fragment>
					);
				})}
			</tr>
			<tr className="tr-second">
				<th data-visible="false">#</th>
				<th data-orderable="false">{translation("Topic Cards", "Tarjetas de Temas")}</th>
				{lprHeaders.map((value, key) => {
					return (
						<React.Fragment key={key}>
							{(() => {
								if (value !== "swr" && value !== "sup") {
									let $userTotal = 0;
									if (!empty(lprHeadersUserCount[value])) {
										$userTotal = lprHeadersUserCount[value];
									}
									let $perTotal = 0;
									let $taskCount = 0;
									lprStoreResults.forEach((valueResults) => {
										if (!empty(valueResults[value + "_percentage"])) {
											$taskCount += 1;
											$perTotal += valueResults[value + "_percentage"];
										}
									});
									return (
										<th data-orderable="false" data-searchable="false" className="table-th-uc">
											{$userTotal} Users
											<br />
											<GetCheckXorPer per={$perTotal / $taskCount} />
											{translation(" Complete", " Completo")}
										</th>
									);
								}
							})()}
						</React.Fragment>
					);
				})}
			</tr>
		</>
	);
};

export default LprStoreHeaders;
