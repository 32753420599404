import React from "react";
import "./formFooter.scss";
import { FormProgressBar } from "./formProgressBar/FormProgressBar";
import { FormControls } from "./formControls/FormControls";
export const FormFooter = () => {
	const completionValue = 0.35;
	const handleClose = () => {
		console.log("Close button clicked");
	};

	const handleNext = () => {
		console.log("Next button clicked");
	};

	const handlePrevious = () => {
		console.log("Previous button clicked");
	};
	return (
		<div className="formFooter">
			<FormProgressBar value={completionValue} />
			<FormControls onClose={handleClose} onNext={handleNext} onPrevious={handlePrevious} />
		</div>
	);
};
