import React from "react";
import { empty } from "../../../utils/utils";
import usePathsFunctions from "../usePathsFunctions";

const GetCheckXorPer = ({ per }) => {
	const { tran_percentage } = usePathsFunctions();
	if (empty(per)) {
		return <>"NA"</>;
	} else if (per === 100) {
		return (
			<svg className="icon-check icon-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
				<path d="M30.48.44a1.49,1.49,0,0,0-2.12,0L25.72,3.08,13.51,15.29,5.35,7.14a1.51,1.51,0,0,0-2.12,0L.44,9.94a1.49,1.49,0,0,0,0,2.12l9,9h0l.19.19,2.64,2.64h0l.32.32a1.25,1.25,0,0,0,1.64.11l19-19a1.49,1.49,0,0,0,0-2.12Z" />
				<path d="M30.48.44a1.49,1.49,0,0,0-2.12,0L25.72,3.08,13.51,15.29,5.35,7.14a1.51,1.51,0,0,0-2.12,0L.44,9.94a1.49,1.49,0,0,0,0,2.12l9,9h0l.19.19,2.64,2.64h0l.32.32a1.25,1.25,0,0,0,1.64.11l19-19a1.49,1.49,0,0,0,0-2.12Z" />
			</svg>
		);
	} else if (tran_percentage(per) === "0.0") {
		return (
			<svg className="icon-x icon-red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
				<path d="M23.39 17.5l11.17 11.17a1.51 1.51 0 0 1 0 2.14l-3.76 3.75a1.51 1.51 0 0 1-2.14 0L17.5 23.39 6.33 34.56a1.5 1.5 0 0 1-2.13 0L.44 30.81a1.51 1.51 0 0 1 0-2.14L11.61 17.5.44 6.34a1.51 1.51 0 0 1 0-2.14L4.2.44a1.5 1.5 0 0 1 2.13 0L17.5 11.61 28.66.44a1.51 1.51 0 0 1 2.14 0l3.76 3.76a1.51 1.51 0 0 1 0 2.14z" />
			</svg>
		);
	} else {
		return <span className="color-orange">{tran_percentage(per)}%</span>;
	}
};

export default GetCheckXorPer;
