import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { BsChevronRight } from "react-icons/bs";
import { useHistory, useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import { PathName } from "../../constants/PathName";
import {
	activeDashboardMenuAtom,
	BreadcrumbItem,
	useBreadcrumbs,
	useDashboardMenu,
	useDashboardPath,
	useLaygoBreadcrumbs,
} from "../../contexts/DashboardMenus.recoil";
import { UserContext, UserContextType } from "../../contexts/UserContext";
import Loading from "../Loading/Loading";

const Breadcrumbs: React.FC = () => {
	const { getDashboardMenus, getDashboardMenusRes, dashboardMenuSections } = useDashboardMenu();
	const { getLaygoBreadcrumbs, laygoBreadcrumbPath } = useLaygoBreadcrumbs();
	const { dashboardPath, parentColor } = useDashboardPath();
	const { breadcrumbs, getBreadcrumbsRes } = useBreadcrumbs();
	const setDashboardActiveMenu = useSetRecoilState(activeDashboardMenuAtom);
	const { store } = useContext<UserContextType>(UserContext);
	const { translation } = store;
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		getDashboardMenus();
		getLaygoBreadcrumbs();
	}, []);

	const findSlugTitle = useCallback(
		(pathName: string) => {
			if (getBreadcrumbsRes.loading) {
				return;
			}
			if (breadcrumbs) {
				const breadcrumb = breadcrumbs.find((el) => el.breadcrumbFields?.id === pathName);
				if (breadcrumb) {
					return (
						translation(breadcrumb.breadcrumbFields?.title, breadcrumb.breadcrumbFields?.titleEs) ||
						pathName
					);
				} else {
					return pathName;
				}
			} else {
				return pathName;
			}
		},
		[breadcrumbs, getBreadcrumbsRes],
	);

	const handleClick = (path: BreadcrumbItem) => {
		if (path.id) {
			if (Object.keys(dashboardMenuSections).includes(path.id)) {
				setDashboardActiveMenu(path.id);
			}
			if (location.pathname !== PathName.DASHBOARD) {
				history.push(PathName.DASHBOARD);
			}
		}
		if (path.url) {
			history.push(path.url);
		}
	};
	const breadcrumbPath = [...dashboardPath, ...laygoBreadcrumbPath] || [];

	const getStyle = useMemo(() => {
		if (breadcrumbPath.length > 1) {
			const styles: React.CSSProperties = {};
			styles.backgroundColor = parentColor;
			return styles;
		}
		return undefined;
	}, [breadcrumbPath]);

	return (
		<div className="top-breadcrumbs" style={getStyle}>
			{getDashboardMenusRes.loading && <Loading />}
			{breadcrumbPath.length <= 1 && (
				<h1 className="page-header">{`${translation("Welcome", "Bienvenido")} ${
					store.realName
				}`}</h1>
			)}
			{breadcrumbPath.length > 1 && (
				<div className="breadcrumbs-items">
					{breadcrumbPath.map((path, i) => {
						if (i < breadcrumbPath.length - 1) {
							return (
								<div
									className={path.url === "no-click" ? "not-clickable" : "clickable"}
									key={"bc-" + i}
								>
									<div
										className="text"
										onClick={() => path.url !== "no-click" && handleClick(path)}
									>
										{findSlugTitle(path.name)}
									</div>
									<BsChevronRight />
								</div>
							);
						} else {
							return (
								<div className="not-clickable" key={"bc-" + i}>
									{findSlugTitle(path.name)}
								</div>
							);
						}
					})}
				</div>
			)}
		</div>
	);
};

export default Breadcrumbs;
