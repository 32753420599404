import React from "react";
import { StoreForm } from "../../../../types/formTypes";

interface HistoryTableProps {
	data: StoreForm[];
}

const HistoryTable: React.FC<HistoryTableProps> = ({ data }) => {
	return (
		<table className="history-table">
			<thead>
				<tr>
					<th>Completed At</th>
					<th>Completed By</th>
				</tr>
			</thead>
			<tbody>
				{data.map(({ createdAt, completedBy }, index) => (
					<tr key={index}>
						<td>{createdAt}</td>
						<td>{completedBy}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export const ExpectationsAndRoutinesHistoryModal: React.FC<HistoryTableProps> = ({ data }) => {
	return (
		<div>
			<HistoryTable data={data} />
		</div>
	);
};
