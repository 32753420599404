import { gql, useQuery } from "@apollo/client";
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import { UserContext } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";

const WHATS_NEWS_QUERY = gql`
	query GetWhatsNews(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		whatsNews(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
			}
		) {
			nodes {
				whatsNewField {
					id
					title
					titleEs
					initiative
					app
					content
					contentEs
				}
			}
		}
	}
`;

const WhatsNew = () => {
	const query = useQueryParams();
	const { store } = useContext(UserContext);
	const { translation, userData } = store;

	const { loading, error, data } = useQuery(WHATS_NEWS_QUERY, {
		variables: {
			acceptAccessRole: store.permissionGroup.accessLevel,
			acceptCompanyType: store.permissionGroup.companyType,
			acceptCustomRole: store.permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	const whatsNews = useMemo(() => {
		if (!loading && !error && data && data.whatsNews) {
			return data.whatsNews.nodes;
		} else {
			return [];
		}
	}, [loading, error, data]);

	let selectedFilter = query.get("t");
	if (!selectedFilter) {
		selectedFilter = "all";
	}

	const whatsNewFilterSet = new Set();

	whatsNews.forEach((whatsNew) => {
		whatsNewFilterSet.add(whatsNew.whatsNewField["initiative"]);
	});

	let whatsNewUniqueFilters = [];
	whatsNewFilterSet.forEach((filter) => {
		whatsNewUniqueFilters.push(filter);
	});

	whatsNewUniqueFilters = whatsNewUniqueFilters.sort((a, b) => {
		if (a > b) {
			return 1;
		} else if (a < b) {
			return -1;
		} else {
			return 0;
		}
	});

	return (
		<>
			{loading ? (
				"Loading"
			) : (
				<>
					<div className="col-xs-12">
						<h1 className="page-header">{translation("What's New", "Actualizaciones")}</h1>
						<ul className="whats-new-filters">
							<Link
								id="all"
								to={PathName.WHATS_NEW + "&t=all"}
								className={selectedFilter === "all" ? "selected" : ""}
							>
								<li>{translation("All Updates", "Todas las Actualizaciones")}</li>
							</Link>
							{whatsNewUniqueFilters.map((filter) => {
								if (!filter) {
									return null;
								}
								return (
									<Link
										id={filter}
										to={PathName.WHATS_NEW + "&t=" + filter.toLowerCase()}
										className={filter && selectedFilter === filter.toLowerCase() ? "selected" : ""}
									>
										<li>{filter}</li>
									</Link>
								);
							})}
						</ul>
					</div>

					<div className="col-xs-12">
						{whatsNews.map(({ whatsNewField }) => {
							const initiative = whatsNewField["initiative"];
							return (
								<div
									className={`update-container ${initiative} animated ${
										(!initiative || selectedFilter !== initiative.toLocaleLowerCase()) &&
										selectedFilter !== "all"
											? "hidden"
											: ""
									}`}
								>
									{/* @TODO */}
									<a className="anchor" name={whatsNewField["id"]}></a>
									<h6 className="update-date">
										<ParseHTMLContent
											htmlString={translation(whatsNewField["title"], whatsNewField["titleEs"])}
										/>
									</h6>
									<div className="update-product">
										{`${translation("Update to ", "Actualizaciónes a ")} ${whatsNewField["app"]}`}
									</div>
									<div className="update-content">
										<ParseHTMLContent
											htmlString={translation(whatsNewField["content"], whatsNewField["contentEs"])}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</>
			)}
		</>
	);
};

export default WhatsNew;
