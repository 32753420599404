import React from "react";
import ParseUrlTarget from "../../../../../../components/ParseUrlTarget/ParseUrlTarget";
import { useSetRecoilState } from "recoil";
import { modalRenderAtom } from "../../../../../../contexts/modal.recoil";
import "./checklistReportTaskModal.scss";
import ParseHTMLContent from "../../../../../../components/ParseHTMLContent/ParseHTMLContent";

interface Props {
	taskName: string;
	taskDescription: string;
	additionalResourcesUrl: string;
	parentChecklistName: string;
	isCritical?: boolean;
	formatDueDate: {
		color: string;
		message: string;
	};
	isCompleted: boolean;
}

export const ChecklistReportTaskModal: React.FC<Props> = ({
	taskName,
	taskDescription,
	additionalResourcesUrl,
	parentChecklistName,
	isCritical,
	formatDueDate,
	isCompleted,
}) => {
	const setModalRender = useSetRecoilState(modalRenderAtom);

	return (
		<div className="checklistReportTaskModal">
			<div className="item">
				<div className="label">Task Name</div>
				<div className="value">
					<ParseHTMLContent htmlString={taskName} />
				</div>
			</div>
			<div className="item">
				<div className="label">Description</div>
				<div className="value">
					<ParseHTMLContent htmlString={taskDescription} />
				</div>
			</div>
			<div className="item">
				<div className="label">Additional Resources</div>
				<div className="value">
					<i
						onClick={() => {
							setModalRender(undefined);
						}}
					>
						<ParseUrlTarget url={additionalResourcesUrl}>{additionalResourcesUrl}</ParseUrlTarget>
					</i>
				</div>
			</div>
			<div className="item">
				<div className="label">Parent Checklist</div>
				<div className="value blue-title">{parentChecklistName}</div>
			</div>
			<div className="status-area">
				<div className="col">
					<div className="label">Status</div>
					<div className="value">{isCompleted ? "Completed Task" : "Current Task"}</div>
				</div>
				<div className="col">
					<div className="label">Is Critical</div>
					<div className="value">{isCritical ? "Yes" : "No"}</div>
				</div>
				<div className="col">
					<div className="label">Due Date</div>
					<div className="value">
						<div className="checklist-table-element checklist-due-date-container">
							<div className="checklist-circle-container">
								<div
									className="checklist-circle checklist-due-date-container-items"
									style={{
										backgroundColor: formatDueDate.color,
									}}
								></div>
							</div>
							{formatDueDate.message}
						</div>
					</div>
				</div>
			</div>
			<div className="button-area">
				<button type="button" className="outline" onClick={() => setModalRender(undefined)}>
					Close
				</button>
			</div>
		</div>
	);
};
