export const countdownToDate = (
	targetDate: Date | null,
	daysToTargetDate: number,
	isComplete?: boolean,
): { message: string; color: string } => {
	if (targetDate == null) {
		return { message: "", color: "" };
	}

	const today = new Date();
	targetDate.setDate(targetDate.getDate() + daysToTargetDate);

	if (isComplete) {
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		};
		const message = targetDate.toLocaleDateString("en-US", options);
		const color = "#27ae60";
		return { message, color };
	}

	// Difference between today and target date
	const diffTime = targetDate.getTime() - today.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	let message: string = "test";
	let color: string = "#f2c94c";

	if (diffDays >= 0 && diffDays <= 5) {
		// Due in less than 5 days
		message = `Due in ${diffDays} days`;
		color = "#f2c94c";
	} else if (diffDays < 0) {
		// Overdue
		message = `${Math.abs(diffDays)} days overdue`;
		color = "#eb5757";
	} else {
		// Neither
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		};
		message = targetDate.toLocaleDateString("en-US", options);
		color = "#27ae60";
	}

	return { message, color };
};
