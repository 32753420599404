import { useContext, useEffect, useState } from "react";
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";
import useGetCurrentUser from "../../hooks/useGetCurrentUser";
import { useHistory } from "react-router";
import { imgPath, PathName } from "../../constants/PathName";
import Portals from "@ionic/portals";
import { usePortalsSubscribe } from "../../portals/portalsUtils";
import { isMobile } from "../../utils/utils";
import { UserContext } from "../../contexts/UserContext";
import env from "../../env";
import "../../styles/login.scss";
import useStates from "../../hooks/useStates";
import axios from "axios";

const Login: React.FC = () => {
	const { getCurrentUser, getCurrentUserRes } = useGetCurrentUser();
	const history = useHistory();
	const [{ username, password }, setForm] = useStates({
		username: "",
		password: "",
	});
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [loader, setLoader] = useState(false);

	const { store: userStore } = useContext(UserContext) as any;

	usePortalsSubscribe<string>("onTokenUpdated", (data) => {
		if (data) {
			localStorage.setItem(LocalStorageKeys.TOKEN, data);
			getCurrentUser();
		} else {
			localStorage.removeItem(LocalStorageKeys.TOKEN);
		}
	});

	const goToMcdLogin = () => {
		if (isMobile()) {
			Portals.publish({
				topic: "mcdLogin",
				data: env.REACT_APP_MCD_LOGIN_URL,
			});
		} else {
			window.location.replace(env.REACT_APP_MCD_LOGIN_URL);
		}
	};

	const internalLogin = (e: any) => {
		setLoader(true);
		e.preventDefault();
		//get username and password
		const json = JSON.stringify({ username, password });
		const encoded = btoa(json);
		//post or use graphql to validate user
		//generate jwt in the backend
		//return jwt frontend set token
		//if token get current user
		axios
			.get(env.REACT_APP_API_BASE_URL + "/wp-json/auth/v1/internalLogin?internalcode=" + encoded)
			.then((res) => {
				//clean
				setErrorMsg("");
				setSuccessMsg("");
				if (res.data === "Invalid") {
					setErrorMsg("Wrong Username or Password");
					return;
				}
				//set token, get or create user
				const data: string = res.data;
				if (data.includes("token")) {
					setSuccessMsg("Success... Please Wait.");
					const token = data.replace("token=", "");
					localStorage.setItem(LocalStorageKeys.TOKEN, token);
					getCurrentUser();
				} else {
					setErrorMsg("Something went wrong, please contact System Admin");
				}
			})
			.catch((err) => {
				setErrorMsg("Something went wrong, please contact System Admin");
				console.log(err);
			})
			.finally(() => {
				setLoader(false);
			});
	};

	useEffect(() => {
		const token = localStorage.getItem(LocalStorageKeys.TOKEN);

		if (token) {
			getCurrentUser();
		} else {
			// goToMcdLogin();
		}
	}, []);

	useEffect(() => {
		if (userStore.personID) {
			history.push(PathName.DASHBOARD);
		} else if (getCurrentUserRes.error) {
			// goToMcdLogin();
		}
	}, [getCurrentUserRes, userStore]);

	// if (getCurrentUserRes.error) {
	// 	return <p>Unable to Load User... Please Reload the page or contact Admin</p>;
	// }

	// if (getCurrentUserRes.isLoading || !userStore.personID) {
	// 	return <Loading />;
	// }

	return (
		<div className="login">
			<div className="background">
				<div className="yellow-blocks">
					<div className="row">
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
					</div>
					<div className="row">
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
						<div className="block"></div>
					</div>
				</div>
			</div>
			<div className="log-area">
				<div className="img-container">
					<img src={imgPath + "/nuggetkms-logo.svg"} alt="nugget kms logo" />
					<div>
						Nugget<b>KMS</b>
					</div>
				</div>
				<form className="card" onSubmit={internalLogin}>
					<label>User ID</label>
					<input
						type="text"
						onChange={(e) => {
							setForm({ username: e.currentTarget.value, password });
						}}
					></input>
					<label>Password</label>
					<input
						type="password"
						onChange={(e) => {
							setForm({ username, password: e.currentTarget.value });
						}}
					></input>
					<div className="errorMsg">{errorMsg}</div>
					<div className="successMsg">{successMsg}</div>
					<button type="submit" className="login-btn" disabled={loader || successMsg !== ""}>
						{loader ? <div className="loader"></div> : "Log In"}
					</button>
					<div className="separator"></div>
					<button
						disabled={loader || successMsg !== ""}
						type="button"
						className="eid-btn"
						onClick={(e) => {
							e.preventDefault();
							goToMcdLogin();
						}}
					>
						Log In with eID
					</button>
					<div className="after-text">
						Don't have a user ID or eID?{" "}
						<a href="https://nuggetkms.com/support/" target="_blank" rel="noopener noreferrer">
							<b>Contact us</b>
						</a>{" "}
						to set up access to Nugget KMS.
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
