import React from "react";
import { useLocation } from "react-router";

const Footer = () => {
	const location = useLocation();
	if (location.pathname === "/login") {
		return null;
	}
	return (
		<>
			<footer className="">
				<div className="row">
					<div className="col-xs-12 text-center">
						<p>&copy; Copyright. McDonald's Corporation</p>
					</div>
				</div>
			</footer>

			{/* {(() => {
				if (!environment("production") && SHOW_ERRORS) {
					// const $repo = new \Cz\Git\GitRepository(base_path());

					return (
						<div className="footer-debugger">
							<p>Current Branch: {$repo->getCurrentBranchName()}</p>
							<p>Latest Commit: {$repo->getLastCommitId()}</p>
						</div>
					);
				}
			})()} */}
		</>
	);
};

export default Footer;
