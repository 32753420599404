import { useContext, useEffect, useMemo } from "react";
import { useGetChecklistAssignmentReportsLazyQuery } from "../../../../generated/graphql";
import { UserContext, UserContextType } from "../../../../contexts/UserContext";
import useQueryParams from "../../../../hooks/useQueryParams";
import { useParams } from "react-router";

export type ChecklistTaskRow = {
	taskId: number;
	taskTitle: string;
	taskTitleEs: string;
	taskDescription: string;
	taskDescriptionEs: string;
	taskDueDateDays: number;
	taskIsCritical: boolean;
	taskIsEvent: boolean;
	completedTasks: string[];
	incompleteTasks: string[];
	moreDetailsUrl: string;
	taskActualDueDate: string;
	taskActualDueDateTime: string;
	taskActualDueDateTimezone: string;
	taskDaysOrActualDate: string;
	taskGroup: string;
};
interface ChecklistReportDetail {
	checklistName: string;
	assignmentDate: Date;
	targetDateLabel: string;
	checklistDescription: string;
	storeName: string;
	storeNsn: string;
	checklistTasks: ChecklistTaskRow[];
}

export const useChecklistReportDetails = () => {
	const { checklistAssignmentId } = useParams<{ checklistAssignmentId: string }>();
	const { store } = useContext<UserContextType>(UserContext);
	const {
		userData: { storeNumber },
		permissionGroup: { accessLevel, companyType, customRole },
		translation,
	} = store;
	const query = useQueryParams();
	const module = query.get("m"); // this is the initiative

	const [getChecklistAssignmentReports, getChecklistAssignmentReportsRes] =
		useGetChecklistAssignmentReportsLazyQuery();

	useEffect(() => {
		if (checklistAssignmentId == null || checklistAssignmentId === "") {
			return;
		}
		const id = Number(checklistAssignmentId);
		getChecklistAssignmentReports({
			variables: {
				where: {
					acceptAccessRole: accessLevel,
					acceptCompanyType: companyType,
					acceptCustomRoles: customRole,
					checklistAssignmentId: id,
					storeNSNs: storeNumber.split(";"),
					initiativeSlug: module,
				},
			},
		});
	}, [
		accessLevel,
		checklistAssignmentId,
		companyType,
		customRole,
		getChecklistAssignmentReports,
		module,
		storeNumber,
	]);

	const checklistAssignmentReports = useMemo(
		() => getChecklistAssignmentReportsRes.data?.getChecklistAssignmentReports?.rows ?? [],
		[getChecklistAssignmentReportsRes.data?.getChecklistAssignmentReports?.rows],
	);

	const checklistReportDetail = useMemo(() => {
		if (checklistAssignmentReports.length <= 0) {
			return null;
		}

		const checklistName = translation(
			checklistAssignmentReports[0]?.title!,
			checklistAssignmentReports[0]?.titleEs,
		);
		const checklistDescription = translation(
			checklistAssignmentReports[0]?.description!,
			checklistAssignmentReports[0]?.descriptionEs!,
		);
		const assignmentDate = new Date(checklistAssignmentReports[0]?.date!);
		const storeName = checklistAssignmentReports[0]?.storeName!;
		const storeNsn = checklistAssignmentReports[0]?.nsn!;

		const groupedChecklistTask: {
			[key: number]: ChecklistTaskRow;
		} = {};

		checklistAssignmentReports.forEach((checklistAssignmentReport) => {
			const currentItem = groupedChecklistTask[checklistAssignmentReport?.taskDbId!];

			const isTaskCompletedName =
				checklistAssignmentReport?.checklistAssignmentTaskFinishedAt != null
					? checklistAssignmentReport.realName!
					: null;
			const isTaskIncompleteName =
				checklistAssignmentReport?.checklistAssignmentTaskFinishedAt == null
					? checklistAssignmentReport?.realName!
					: null;

			if (currentItem === undefined) {
				groupedChecklistTask[checklistAssignmentReport?.taskDbId!] = {
					taskId: checklistAssignmentReport?.taskDbId!,
					taskTitle: checklistAssignmentReport?.taskTitle!,
					taskTitleEs: checklistAssignmentReport?.taskTitleEs ?? "",
					taskDescription: checklistAssignmentReport?.taskDescription ?? "",
					taskDescriptionEs: checklistAssignmentReport?.taskDescriptionEs ?? "",
					taskDueDateDays: checklistAssignmentReport?.taskDueDateDays || 0,
					taskIsCritical: checklistAssignmentReport?.taskIsCritical ?? false,
					taskIsEvent: checklistAssignmentReport?.taskType === "event",
					moreDetailsUrl: checklistAssignmentReport?.taskMoreDetailsUrl ?? "",
					completedTasks: isTaskCompletedName !== null ? [isTaskCompletedName] : [],
					incompleteTasks: isTaskIncompleteName !== null ? [isTaskIncompleteName] : [],
					taskActualDueDate: checklistAssignmentReport?.taskActualDueDate ?? "",
					taskDaysOrActualDate: checklistAssignmentReport?.taskDaysOrActualDate ?? "",
					taskActualDueDateTime: checklistAssignmentReport?.taskActualDueDateTime ?? "",
					taskActualDueDateTimezone: checklistAssignmentReport?.taskActualDueDateTimezone ?? "",
					taskGroup: checklistAssignmentReport?.taskGroup ?? "",
				};
			} else {
				isTaskCompletedName !== null && currentItem.completedTasks.push(isTaskCompletedName);
				isTaskIncompleteName !== null && currentItem.incompleteTasks.push(isTaskIncompleteName);
			}
		});

		const checklistTasks: ChecklistTaskRow[] = Object.values(groupedChecklistTask);

		const checklistReportDetail: ChecklistReportDetail = {
			checklistName,
			checklistDescription,
			assignmentDate,
			storeName,
			storeNsn,
			checklistTasks,
			targetDateLabel: checklistAssignmentReports[0]?.targetDateLabel!,
		};
		return checklistReportDetail;
	}, [checklistAssignmentReports, translation]);

	const isLoading = getChecklistAssignmentReportsRes.loading;

	return {
		checklistAssignmentReports,
		isLoading,
		checklistReportDetail,
	};
};
