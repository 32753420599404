import useQueryParams from "../../hooks/useQueryParams";
import MySettings from "./mySettings/MySettings";
import MySettingsUpdate from "./mySettingsUpdate/MySettingsUpdate";
import WhatsNew from "./whatsNew/WhatsNew";
import "../../styles/mcd-transitionkit.scss";
import useProduct from "../../hooks/useProduct";
import { calculate_top_margin, setPageTitle } from "../../utils/utils";
import { useEffect } from "react";
import DashBoardMenus from "./dashboard/DashBoardMenus";

const TransitionKit = () => {
	const query = useQueryParams();
	const product = useProduct();

	const getPage = () => {
		const p = query.get("p");

		if (p === "whats-new") {
			return <WhatsNew />;
		} else if (p === "my-settings-update") {
			return <MySettingsUpdate />;
		} else if (p === "my-settings") {
			return <MySettings />;
		} else {
			return <DashBoardMenus />;
		}
	};

	useEffect(() => {
		setPageTitle(product);
	}, []);

	return (
		<div id="main" className="container-fluid main" style={{ ...calculate_top_margin(product) }}>
			<div className="row">{getPage()}</div>
		</div>
	);
};

export default TransitionKit;
