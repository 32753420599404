import React from "react";
import { dashboardMenuSectionsAtom } from "../../../contexts/DashboardMenus.recoil";
import { useRecoilValue } from "recoil";
import { useSidebarMenu } from "../../../contexts/SidebarMenus.recoil";
import SideMenu from "./SideMenu";
import { BsChevronLeft } from "react-icons/bs";

const SideMenus = () => {
	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const { sidebarActiveMenu, sidebarTitle, sidebarParentItem, setSidebarActiveMenu } =
		useSidebarMenu();
	return (
		<div className={"sidebar-menus " + sidebarActiveMenu}>
			<div
				className={"dash-title " + (sidebarActiveMenu !== "root" ? "clickable" : "root")}
				onClick={() => setSidebarActiveMenu(sidebarParentItem?.parent || "root")}
			>
				{sidebarActiveMenu !== "root" && <BsChevronLeft />}
				{sidebarTitle}
			</div>
			{dashboardMenuSections && dashboardMenuSections[sidebarActiveMenu] && (
				<SideMenu menuItems={dashboardMenuSections[sidebarActiveMenu]} menuId={sidebarActiveMenu} />
			)}
		</div>
	);
};

export default SideMenus;
