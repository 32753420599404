import React, { useContext } from "react";
import { imgPath } from "../../../../constants/PathName";
import { UserContext } from "../../../../contexts/UserContext";
import { empty } from "../../../../utils/utils";
import GetCheckXorPer from "../../PathsCommon/GetCheckXorPer";
import ParseHTMLContent from "../../../../components/ParseHTMLContent/ParseHTMLContent";

const LprStoreData = ({ lprStoreResults, lprHeaders, lprHeadersUserCount }) => {
	const { store } = useContext(UserContext);
	const { translation } = store;
	return (
		<>
			{lprStoreResults.map((value, key) => {
				return (
					<tr key={key}>
						<td>{key}</td>
						<td data-card-id={value["card_id"]}>
							<img
								src={imgPath + "/badges/" + value["badge"] + ".png"}
								style={{ width: "25px", height: "25px" }}
								className="off"
								alt=""
							/>{" "}
							<span>
								<ParseHTMLContent htmlString={translation(value["title"], value["title_es"])} />
							</span>
						</td>
						{lprHeaders.map((valueList, key) => {
							return (
								<React.Fragment key={key}>
									{valueList !== "swr" &&
									valueList !== "sup" &&
									empty(lprHeadersUserCount[valueList]) ? (
										<td>&#8213;</td>
									) : (
										<td>
											<GetCheckXorPer per={value[valueList + "_percentage"]} />
										</td>
									)}
								</React.Fragment>
							);
						})}
					</tr>
				);
			})}
		</>
	);
};

export default LprStoreData;
