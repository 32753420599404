import React, { useState } from "react";
import { FormItem } from "./formItems/FormItem";
import { FormSections } from "./formSections/FormSections";
import "./formContent.scss";
import { FormDetailData } from "../../../../utils/deleteme/formsData";
import { atom, useRecoilState } from "recoil";
import { useSetRecoilState } from "recoil";
import { modalRenderAtom, modalTitleAtom } from "../../../../contexts/modal.recoil";

export const selectedSectionState = atom<number | null>({
	key: "selectedSectionState",
	default: null,
});

export const FormContent = () => {
	const setModalRender = useSetRecoilState(modalRenderAtom);
	const setModalTitle = useSetRecoilState(modalTitleAtom);
	const [items, setItems] = useRecoilState(selectedSectionState);
	const [selectedSection, setSelectedSection] = useRecoilState(selectedSectionState);

	// console.log("Selected Section Index:", selectedSection);

	return (
		<div className="form-content formContent">
			<FormSections
				onSectionClick={(sectionIndex: number) => {
					setSelectedSection(sectionIndex);
					//setItems(selectedSection ? FormDetailData.sections[selectedSection].items : "");
				}}
			/>
			<div className="form-item-content">
				{selectedSection && (
					<h1 id="titleForHeader">{FormDetailData.sections[selectedSection].name}</h1>
				)}
				{selectedSection && (
					<div className="form-items">
						{FormDetailData.sections[selectedSection].items.map((item) => (
							<FormItem
								key={item.name}
								name={item.name}
								description={item.description}
								answerFields={item.answerFields}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
