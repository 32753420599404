import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import { UserContext } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";
import { in_array } from "../../../utils/utils";
import SuperSelect from "./SuperSelect/SuperSelect";
import useLogout from "../../../hooks/useLogout";

const ProfileLinks = ({ isActive, closeNavOpt }) => {
	const { store } = useContext(UserContext);
	const { translation, permissionGroup } = store;
	const query = useQueryParams();
	const m = query.get("m");

	//@todo check variables
	const $deniedBadges = ["Operator", "Organization Admin", "None"];

	const logout = useLogout();

	return (
		<div
			id="profileLinks"
			className={"profile-links animated " + (isActive ? " slideInRight " : " hidden ")}
		>
			<div className="panel-header">
				{translation("My Profile", "Mi Perfil")}
				<div className="anc dismiss" onClick={closeNavOpt}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
						<path d="M23.39 17.5l11.17 11.17a1.51 1.51 0 0 1 0 2.14l-3.76 3.75a1.51 1.51 0 0 1-2.14 0L17.5 23.39 6.33 34.56a1.5 1.5 0 0 1-2.13 0L.44 30.81a1.51 1.51 0 0 1 0-2.14L11.61 17.5.44 6.34a1.51 1.51 0 0 1 0-2.14L4.2.44a1.5 1.5 0 0 1 2.13 0L17.5 11.61 28.66.44a1.51 1.51 0 0 1 2.14 0l3.76 3.76a1.51 1.51 0 0 1 0 2.14z" />
					</svg>
				</div>
			</div>

			<div className="links">
				<ul>
					<li>
						<Link to={PathName.DASHBOARD} onClick={closeNavOpt}>
							{translation("My Dashboard", "Mi Tablero")}
						</Link>
					</li>

					{!in_array(permissionGroup.accessLevel, $deniedBadges) && (
						<li>
							<Link to={PathName.PATHS + "/?m=" + m + "&p=my-badges"} onClick={closeNavOpt}>
								{translation("My Badges", "Mis Insignias")}
							</Link>
						</li>
					)}

					{
						/* Only show my settings to users who have settings to change */
						(permissionGroup.accessLevel !== "Level 6" ||
							permissionGroup.accessLevel !== "Level 5") && (
							<li>
								<Link to={PathName.MY_SETTINGS} onClick={closeNavOpt}>
									{translation("My Settings", "Mis Ajustes")}
								</Link>
							</li>
						)
					}

					<li>
						<button
							className="anc logout"
							onClick={() => {
								logout();
							}}
						>
							{translation("Logout", "Cerrar Sesión")}
						</button>
					</li>
				</ul>
			</div>
			{store.isSuperUser && <SuperSelect />}
		</div>
	);
};

export default ProfileLinks;
