import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import useQueryParams from "../../../../../hooks/useQueryParams";
import { in_array, print_r } from "../../../../../utils/utils";
import LpTask from "./LpTask/LpTask";
import useStates from "../../../../../hooks/useStates";
import Loading from "../../../../../components/Loading/Loading";
import { imgPath, PathName } from "../../../../../constants/PathName";
import useProduct from "../../../../../hooks/useProduct";
import ShowNotification from "../../../../../components/ShowNotification/ShowNotification";
import { Link } from "react-router-dom";
import { useMyBadges } from "../../../../../contexts/MyBadges.recoil";
import ParseHTMLContent from "../../../../../components/ParseHTMLContent/ParseHTMLContent";

const USER_TASKS_QUERY = gql`
	query GetUserTasks(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$initiativeSlug: [String]
		$personID: String!
		$userEid: String
	) {
		userTasks(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
			first: 100
		) {
			nodes {
				userTasksFields {
					parent
					id
					product
					title
					pageUrl
					type
					title
					titleEs
					completionTime
					description
					descriptionEs
				}
			}
		}
		userBadges(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				initiativeSlug: $initiativeSlug
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
			first: 100
		) {
			nodes {
				userBadgeFields {
					id
					parentCard
					title
					titleEs
				}
			}
		}
		getMyBadges(personID: $personID) {
			ba_001
			ba_002
			ba_003
			ba_004
			ba_005
			ba_006
			ba_007
			ba_008
			ba_009
			ba_010
			ba_011
			ba_013
			ba_014
			ba_012
			ba_016
			ba_015
			ba_018
			ba_017
			ba_019
			ba_021
			ba_022
			ba_020
			ba_023
			ba_024
		}
	}
`;
const COUNT_COMPLETED_TASKS_QUERY = gql`
	query GetCountCompletedTasksQuery($personID: String!, $tasks: [String]!) {
		getCountCompletedTasks(personID: $personID, tasks: $tasks)
	}
`;

const GET_TASK_STATUS_QUERY = gql`
	query getTaskStatus($personID: String!, $taskIDs: [String]!) {
		getTaskStatus(personID: $personID, taskIDs: $taskIDs)
	}
`;

const UPDATE_TASK_STATUS_MUTATION = gql`
	mutation updateTaskStatus($personID: String!, $userTask: String!, $taskValue: Int!) {
		updateMyUserTask(input: { personID: $personID, userTask: $userTask, taskValue: $taskValue }) {
			message
			didUpdate
		}
	}
`;

const UPDATE_BADGE_STATUS_MUTATION = gql`
	mutation updateBadgeStatus($personID: String!, $badgeName: String!, $badgeValue: Int!) {
		updateMyBadges(input: { personID: $personID, badgeName: $badgeName, badgeValue: $badgeValue }) {
			message
			didUpdate
		}
	}
`;

const LpCard = ({ card, bootstrapColWidth, user_badges }) => {
	//VARIABLES
	const query = useQueryParams();
	const errors = query.get("errors");
	const module = query.get("m");
	const { store } = useContext(UserContext);
	const { translation, permissionGroup, personID, userLanguage, userData } = store;
	const deniedBadges = ["Operator", "Organization Admin", "None"];
	const homepath = PathName.DASHBOARD;
	const product = useProduct();

	//STATES
	const [{ shouldShowTasks, cardBadgeStatus }, setState] = useStates({
		shouldShowTasks: false,
		cardBadgeStatus: undefined,
	});
	const [notifState, setNotifState] = useStates({
		updatedTask: undefined,
		notificationRef: undefined,
	});
	const [isQueriesLoading, setQueriesLoading] = useState(false);

	//CARD QUERY
	const { loading, error, data } = useQuery(USER_TASKS_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			initiativeSlug: module,
			userEid: userData.eID,
			personID,
		},
	});

	const userTasks = useMemo(() => {
		if (!loading && !error && data?.userTasks) {
			return data.userTasks.nodes;
		} else {
			return [];
		}
	}, [loading, error, data]);

	const cardBadgeID = useMemo(() => {
		if (!loading && !error && data?.userBadges?.nodes.length > 0) {
			const badgesFiltered = data.userBadges.nodes.filter((el) =>
				in_array(card.cardId, el.userBadgeFields.parentCard),
			);
			let badgeID = "";
			if (badgesFiltered.length > 0) {
				badgeID = badgesFiltered[0].userBadgeFields.id;
			}
			return badgeID.replaceAll("_", "-");
		} else {
			return "";
		}
	}, [loading, error, data]);

	useEffect(() => {
		if (cardBadgeID && data?.getMyBadges) {
			const cbs = data.getMyBadges[cardBadgeID.replaceAll("-", "_")];
			setState({ cardBadgeStatus: cbs });
		}
	}, [cardBadgeID, data?.getMyBadges]);

	const filteredTasks = userTasks.filter((el) => el.userTasksFields.parent?.includes(card.cardId));
	const taskArray = filteredTasks.map((el) => el.userTasksFields.id.replaceAll("-", "_"));

	//CARD LAZY QUERIES
	const [getCountCompletedTasks, getCountCompletedTasksRes] = useLazyQuery(
		COUNT_COMPLETED_TASKS_QUERY,
	);

	const [getTaskStatus, taskStatusRes] = useLazyQuery(GET_TASK_STATUS_QUERY);

	const taskStatuses = useMemo(() => {
		if (!taskStatusRes.loading && !taskStatusRes.error && taskStatusRes.data?.getTaskStatus) {
			return JSON.parse(taskStatusRes.data?.getTaskStatus);
		} else {
			return {};
		}
	}, [taskStatusRes.loading, taskStatusRes.error, taskStatusRes.data]);

	//USE MUTATIONS

	const [updateTaskStatus, { loading: utsmLoading }] = useMutation(UPDATE_TASK_STATUS_MUTATION);
	const [updateBadgeStatus, { loading: ubsmLoading }] = useMutation(UPDATE_BADGE_STATUS_MUTATION);

	//REACT HOOKS
	const { getUserBadges } = useMyBadges();
	const reloadData = () => {
		getCountCompletedTasks({
			variables: {
				personID,
				tasks: taskArray,
			},
		});
		getTaskStatus({
			variables: {
				personID,
				taskIDs: taskArray,
			},
		});
	};

	useEffect(() => {
		if (userTasks.length > 0) {
			reloadData();
		}
	}, [userTasks]);

	useEffect(() => {
		//badge update
		if (
			cardBadgeID &&
			cardBadgeStatus !== undefined &&
			// eslint-disable-next-line eqeqeq
			cardBadgeStatus == "0" &&
			data.getMyBadges &&
			filteredTasks.length > 0 &&
			getCountCompletedTasksRes?.data?.getCountCompletedTasks === filteredTasks.length
		) {
			setQueriesLoading(true);
			updateBadgeStatus({
				variables: {
					personID,
					badgeName: cardBadgeID.replaceAll("-", "_"),
					badgeValue: 1,
				},
			})
				.then(() => {
					setState({ cardBadgeStatus: 1 });
					getUserBadges();
				})
				.finally(() => {
					setQueriesLoading(false);
				});
		}
	}, [cardBadgeID, getCountCompletedTasksRes?.data, filteredTasks.length, cardBadgeStatus]);

	// useEffect(() => {
	// 	if (
	// 		// getCountCompletedTasksRes?.loading ||
	// 		// getCountCompletedTasksRes.networkStatus === NetworkStatus.refetch ||
	// 		// taskStatusRes?.loading ||
	// 		// taskStatusRes.networkStatus === NetworkStatus.refetch ||
	// 		utsmLoading ||
	// 		ubsmLoading
	// 	) {
	// 		console.log("true: " + getCountCompletedTasksRes);
	// 		console.log(taskStatusRes.loading);
	// 		console.log(utsmLoading);
	// 		console.log(ubsmLoading);
	// 		setQueriesLoading(true);
	// 	} else {
	// 		console.log("false: " + getCountCompletedTasksRes);
	// 		console.log(taskStatusRes.loading);
	// 		console.log(utsmLoading);
	// 		console.log(ubsmLoading);
	// 		setQueriesLoading(false);
	// 	}
	// }, [
	// 	// getCountCompletedTasksRes,
	// 	// taskStatusRes,
	// 	utsmLoading,
	// 	ubsmLoading,
	// 	// getCountCompletedTasksRes.networkStatus,
	// 	// taskStatusRes.networkStatus,
	// ]);

	//RENDER CONDITIONALS
	if (loading) return <Loading />;
	if (error) return <p>Unable to find user tasks :(</p>;

	//FUNCTIONS
	const isTaskCompleted = (taskID) => {
		const tID = taskID.replaceAll("-", "_");
		if (taskStatuses[tID] === "1") {
			return "completed";
		} else {
			return "";
		}
	};

	const updateTask = (task, taskValue) => {
		setQueriesLoading(true);
		let fixedTaskID = task.id.replaceAll("-", "_");
		updateTaskStatus({
			variables: {
				personID,
				userTask: fixedTaskID,
				taskValue,
			},
		})
			.then(async (res) => {
				//expected res
				// res.data.updateFavoriteStore
				// 		.didUpdate: "yes"
				// 		.message: "Updated Succesfully"

				// reloadData();
				await Promise.all([getCountCompletedTasksRes.refetch(), taskStatusRes.refetch()]);
			})
			.finally(() => {
				setNotifState({ updatedTask: task });
				setQueriesLoading(false);
			});
	};

	const currentTask = () => {
		const jsx = [];

		if (
			filteredTasks?.length === 0 ||
			Object.keys(taskStatuses).length === 0 ||
			getCountCompletedTasksRes?.data === undefined
		) {
			return;
		}
		// const currentTask = Object.keys()

		if (getCountCompletedTasksRes?.data?.getCountCompletedTasks === filteredTasks.length) {
			/** write to db for badge completion for each card*/
			jsx.push(
				<div className="card-done" key="card-done">
					{/* only show image to those who get badges */}
					{!in_array(permissionGroup.accessLevel, deniedBadges) ? (
						<>
							{/* {db_update_badges(badges, card["card_id"])} */}
							<img
								className="card-badge"
								src={imgPath + "/badges/" + cardBadgeID + ".svg"}
								alt=""
							/>
							<p>
								{translation(
									"Congrats! you've completed all the tasks in this card and earned a badge.",
									"¡Felicidades! Haz completado todas las tareas en esta tarjeta y haz ganado una insignia.",
								)}
							</p>
						</>
					) : (
						<p>
							{translation(
								"Congrats! you've completed all the tasks in this card.",
								"¡Felicidades! Haz completado todas las tareas en esta tarjeta.",
							)}
						</p>
					)}
				</div>,
			);
		} else {
			// db_get_current_task(array, card);
			const taskID = Object.keys(taskStatuses).find((key) => taskStatuses[key] === "0");
			if (taskID) {
				const currTaskID = taskID.replaceAll("_", "-");
				const currTask = filteredTasks.filter((el) => el.userTasksFields.id === currTaskID)[0]
					?.userTasksFields;

				const $ref = homepath + product + "/";

				jsx.push(
					<React.Fragment key="current-task">
						<h5 className="task-title">
							<div
								className={" mark-complete anc " + (isQueriesLoading && " disabled ")}
								onClick={() => updateTask(currTask, 1)}
							>
								<svg
									aria-hidden="true"
									data-icon="square"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path
										fill="currentColor"
										d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
									></path>
								</svg>
							</div>
							{translation(currTask["title"], currTask["titleEs"])}
						</h5>
						<p className="task-time">{currTask["completionTime"]}</p>
						<p className="taskDescription">
							{translation(currTask["description"], currTask["descriptionEs"])}
						</p>

						<Link
							to={
								homepath +
								currTask["product"] +
								"/" +
								currTask["pageUrl"] +
								"&l=" +
								userLanguage +
								"&ref=" +
								$ref
							}
							className={"btn " + card["class"]}
						>
							{
								{
									do: translation("DO WALKTHROUGH", "HACER RECORRIDO"),
									read: translation("READ PAGE", "LEER PAGINA"),
									browse: translation("BROWSE TOPIC", "EXPLORAR TEMA"),
									test: translation("BEGIN TEST", "EMPEZAR PRUEBA"),
								}[currTask["type"]]
							}
						</Link>
					</React.Fragment>,
				);
			}
		}
		return jsx;
	};

	return (
		<div className={"col-xs-12 col-sm-" + bootstrapColWidth}>
			{isQueriesLoading && <Loading />}
			{notifState.updatedTask && (
				<ShowNotification
					updatedTask={notifState.updatedTask}
					notificationRef={notifState.notificationRef || ""}
				/>
			)}
			<div className="card">
				<h1 className={card["class"]}>{translation(card["title"], card["titleEs"])}</h1>
				<div className="task-group-progress">
					{(
						(getCountCompletedTasksRes?.data?.getCountCompletedTasks / filteredTasks.length) *
						100
					).toFixed(0)}
					{" %"}
					<br />
					<span>{translation("complete", "completo")}</span>
				</div>
				<div className="card-content">
					<div className="current-task">{currentTask()}</div>
				</div>
				<div className={"card-footer " + card["class"]}>
					<span className="counter">
						{translation("Task", "Tarea") +
							" " +
							(getCountCompletedTasksRes?.data?.getCountCompletedTasks === filteredTasks.length
								? filteredTasks.length
								: getCountCompletedTasksRes?.data?.getCountCompletedTasks + 1) +
							" " +
							translation("of", "de") +
							" " +
							filteredTasks.length}
					</span>
					<div
						className="anc view-all"
						onClick={() => setState({ shouldShowTasks: !shouldShowTasks })}
					>
						{!shouldShowTasks && <span>{translation("View", "Ver")}</span>}
						{shouldShowTasks && <span>{translation("Hide", "Esconder")}</span>}
						{translation(" all Tasks", " Tareas")}
					</div>
				</div>
				{shouldShowTasks && (
					<div className={"all-tasks " + card["class"]}>
						<p>
							<ParseHTMLContent
								htmlString={translation(card["description"], card["descriptionEs"])}
							/>
						</p>
						<ul>
							{userTasks.map(({ userTasksFields }, key) => {
								return (
									<React.Fragment key={key}>
										{errors === "true" && (
											<>
												card id= {card["cardId"]}
												<br />
												taks parent = {print_r(userTasksFields["parent"])}
												<br />
											</>
										)}

										{in_array(card["cardId"], userTasksFields["parent"]) && (
											<LpTask
												task={userTasksFields}
												taskCompleted={isTaskCompleted(userTasksFields["id"])}
												updateTask={updateTask}
												utsmLoading={isQueriesLoading}
											/>
										)}
									</React.Fragment>
								);
							})}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default LpCard;
