import React, { useContext } from "react";
import "./checklist-card.scss";
import { Link } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import { ChecklistAssignmentTableRowFragment } from "../../../generated/graphql";
import { UserContext, UserContextType } from "../../../contexts/UserContext";
import { MdGroup, MdPerson, MdStore } from "react-icons/md";
import { formatDate } from "../../../utils/utils-typescript";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";

interface Props {
	checklist: ChecklistAssignmentTableRowFragment;
}

export const ChecklistCard: React.FC<Props> = ({ checklist }) => {
	const { store } = useContext<UserContextType>(UserContext);

	const { translation } = store;

	return (
		<Link
			to={PathName.CHECKLIST(`${checklist.checklistAssignmentId}`)}
			className="checklist-card"
			style={{ backgroundColor: checklist.color || "" }}
		>
			{checklist.date != null && (
				<div className="top">
					{checklist.targetDateLabel}: <b>{formatDate(new Date(checklist?.date ?? ""))}</b>
				</div>
			)}
			<div className="text-slot">
				<div className="title">
					<ParseHTMLContent htmlString={translation(checklist.title, checklist.titleEs)} />
				</div>
				<div>({checklist.initiative})</div>
			</div>
			<div className="bottom">
				<div className="store">
					{checklist.storeName != null && (
						<>
							<MdStore className="store-icon" />
							<div>{checklist.storeName}</div>
						</>
					)}
				</div>
				<div className="shared-type">
					{checklist.sharedType === "Shared" ? (
						<>
							<MdGroup className="group-icon" />
						</>
					) : (
						<>
							<MdPerson className="person-icon" />
						</>
					)}
				</div>
			</div>
		</Link>
	);
};
