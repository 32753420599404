import { cloneArrPush, cloneArrSplice } from "gate3-utils";
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { ModalProps } from "./Modal";

const modalsAtom = atom<ModalProps[]>({
	key: "modals",
	default: [],
});

export const useModals = () => {
	const [modals, setModals] = useRecoilState(modalsAtom);

	const pushModal = useCallback(
		(props: ModalProps) => {
			setModals((state) => {
				return cloneArrPush(state, props);
			});
		},
		[setModals],
	);
	const popModal = useCallback(() => {
		setModals((state) => {
			return cloneArrSplice(state, state.length - 1, 1);
		});
	}, [setModals]);

	return {
		modals,
		pushModal,
		popModal,
	};
};
