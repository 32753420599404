import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { notificationCountersAtom } from "../../../contexts/Notifications.recoil";
import { UserContext } from "../../../contexts/UserContext";
import { useRecoilState } from "recoil";
import { gql, useQuery } from "@apollo/client";
import Loading from "../../Loading/Loading";
import ParseHTMLContent from "../../ParseHTMLContent/ParseHTMLContent";

const NOTIFICATION_QUERY = gql`
	query GetNotificationQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		notifications(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				notificationsField {
					content
					critical
					expiresOn
					fieldGroupName
					id
					modals
					publishedOn
					title
				}
			}
		}
	}
`;

const NotificationsPanel = ({ isActive, closeNavOpt }) => {
	//state hooks
	const [showPrevious, setShowPrevious] = useState(false);
	const [activeNotifications, setActiveNotifications] = useState([]);
	const [hiddenNotifications, setHiddenNotifications] = useState([]);

	//recoils - contexts
	const { store } = useContext(UserContext);
	const { translation, permissionGroup, userData } = store;
	const [notification, setNotification] = useRecoilState(notificationCountersAtom);

	//custom hooks
	const { loading, error, data } = useQuery(NOTIFICATION_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	//local variables

	//functions

	//React hooks
	useEffect(() => {
		if (data && data.notifications.nodes.length > 0) {
			let aCount = 0;
			let hCount = 0;
			const hNotifications = [];
			const aNotifications = [];
			data.notifications.nodes.forEach((el) => {
				const notif = el.notificationsField;
				const today = new Date();
				const publishedOn = new Date(notif["publishedOn"]);
				const expiresOn = new Date(notif["expiresOn"]);
				const twoMonthsAgo = new Date();
				twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
				if (publishedOn < today && expiresOn <= today && publishedOn > twoMonthsAgo) {
					hCount++;
					hNotifications.push(el);
				} else if (publishedOn < today && publishedOn > twoMonthsAgo) {
					aCount++;
					aNotifications.push(el);
				}
			});
			setNotification({ activeCount: aCount, hiddenCount: hCount });
			setActiveNotifications(aNotifications);
			setHiddenNotifications(hNotifications);
		}
	}, [data]);

	//jsx variables
	if (loading) return <Loading />;
	if (error) return <p>Unable to find dashboard :(</p>;

	return (
		<div
			id="notificationsPanel"
			className={"notifications-header animated slideInRight " + (isActive ? "  " : " hidden ")}
		>
			<div className="box">
				<div className="row panel-header">
					<div className="col-xs-8 text-left">
						<h1 className="panel-title">{translation("Notifications", "Notificaciones")}</h1>
					</div>
					<div className="col-xs-4 text-right">
						<div className="anc dismiss" onClick={closeNavOpt}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
								<path d="M23.39 17.5l11.17 11.17a1.51 1.51 0 0 1 0 2.14l-3.76 3.75a1.51 1.51 0 0 1-2.14 0L17.5 23.39 6.33 34.56a1.5 1.5 0 0 1-2.13 0L.44 30.81a1.51 1.51 0 0 1 0-2.14L11.61 17.5.44 6.34a1.51 1.51 0 0 1 0-2.14L4.2.44a1.5 1.5 0 0 1 2.13 0L17.5 11.61 28.66.44a1.51 1.51 0 0 1 2.14 0l3.76 3.76a1.51 1.51 0 0 1 0 2.14z" />
							</svg>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-xs-12 scrollable">
						{activeNotifications.map((inNotification, key) => {
							return (
								<React.Fragment key={key}>
									{(() => {
										const notification = inNotification.notificationsField;
										const notificationDate = new Date(notification["publishedOn"]);
										return (
											<div className={"notification-item row "}>
												<div className="col-xs-9">
													<h1 className="title">
														{notification["critical"] === "yes" && <b>CRITICAL NOTIFICATION</b>}
														{notification["title"]}
													</h1>
												</div>
												<div className="col-xs-3">
													<h2 className="date text-right">
														{notificationDate.toLocaleDateString()}
													</h2>
												</div>
												<div className="col-xs-12">
													<div className="description">
														<ParseHTMLContent htmlString={notification["content"] || ""} />
													</div>
												</div>
											</div>
										);
									})()}
								</React.Fragment>
							);
						})}

						{showPrevious && (
							<>
								{hiddenNotifications.map((inNotification, key) => {
									return (
										<React.Fragment key={key}>
											{(() => {
												const notification = inNotification.notificationsField;
												const notificationDate = new Date(notification["publishedOn"]);
												return (
													<div className={"notification-item row "}>
														<div className="col-xs-9">
															<h1 className="title">
																{notification["critical"] === "yes" && <b>CRITICAL NOTIFICATION</b>}
																{notification["title"]}
															</h1>
														</div>
														<div className="col-xs-3">
															<h2 className="date text-right">
																{notificationDate.toLocaleDateString()}
															</h2>
														</div>
														<div className="col-xs-12">
															<div className="description">
																<ParseHTMLContent htmlString={notification["content"] || ""} />
															</div>
														</div>
													</div>
												);
											})()}
										</React.Fragment>
									);
								})}
							</>
						)}

						{notification.hiddenCount >= 0 && (
							<>
								{notification.activeCount === 0 && !showPrevious && (
									<>
										<div className="notification-item no-new row">
											<div className="col-xs-12">
												<p className="description text-center">
													{translation("No new notifications", "Ninguna Notificación Nueva")}
												</p>
											</div>
										</div>
									</>
								)}

								<div className="show-all">
									<div className="anc label" onClick={() => setShowPrevious(!showPrevious)}>
										{showPrevious ? translation("Hide", "Esconder") : translation("Show", "Ver")}
										{" " + notification.hiddenCount + " "}
										{translation("Previous", "Previas")}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotificationsPanel;
