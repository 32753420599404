import { useReducer } from "react";

const useStates = (defaultState) => {
	const reducer = (prev, next) => ({ ...prev, ...next });
	const [state, setState] = useReducer(reducer, defaultState);

	return [state, setState];
};

export default useStates;
