import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

export const _navActiveAtom = atom<string>({
	key: "navActive",
	default: "",
});

export const useGetNavActive = () => {
	const navActive = useRecoilValue(_navActiveAtom);

	return { navActive };
};

export const useSetNavActive = () => {
	const setNavActive = useSetRecoilState(_navActiveAtom);

	return { setNavActive };
};

export const useResetNavActive = () => {
	const resetNavActive = useResetRecoilState(_navActiveAtom);

	return { resetNavActive };
};
