import React from "react";
import { formatDate } from "../../../../utils/utils-typescript";
import "./checklist-report-detail.scss";
import CheckListReportTasks from "./CheckListReportTasks/CheckListReportTasks";
import { useChecklistReportDetails } from "./checklistReportDetail.service";
import Loading from "../../../../components/Loading/Loading";
import { calculate_top_margin } from "../../../../utils/utils";
import { useWindowBreakpoints } from "../../../../hooks/useWindowBreakpoints";
import ChecklistReportDetailTasksMobile from "./checklistReportDetailTasksMobile/ChecklistReportDetailTasksMobile";

const ChecklistReportDetail: React.FC = () => {
	const { checklistReportDetail, isLoading } = useChecklistReportDetails();
	const { isWindowMobile } = useWindowBreakpoints();

	if (checklistReportDetail == null) {
		return <></>;
	}

	const currentGoLiveDate = checklistReportDetail.assignmentDate;

	return (
		<div
			className="container-fluid main checklist-report-detail"
			style={{ ...calculate_top_margin("") }}
		>
			{isLoading && <Loading />}
			<div className="checklist-report-detail-header">
				<div className="checklist-report-detail-header-left">
					<div className="checklist-report-detail-header-title">
						{checklistReportDetail?.checklistName}
					</div>
					<div className="checklist-report-detail-header-information">
						{checklistReportDetail?.storeName} - {checklistReportDetail?.storeNsn} -{" "}
						{checklistReportDetail?.checklistDescription}
					</div>
				</div>
				<div className="checklist-report-detail-header-right">
					{checklistReportDetail.targetDateLabel}: {formatDate(currentGoLiveDate)}
				</div>
			</div>
			<div className="checklist-report-table-container">
				{!isWindowMobile && (
					<table className="checklist-report-table">
						<tbody>
							<CheckListReportTasks
								items={checklistReportDetail.checklistTasks}
								goLiveDate={currentGoLiveDate}
								checklistName={checklistReportDetail.checklistName}
							/>
						</tbody>
					</table>
				)}
				{isWindowMobile && (
					<ChecklistReportDetailTasksMobile
						items={checklistReportDetail.checklistTasks}
						goLiveDate={currentGoLiveDate}
						checklistName={checklistReportDetail.checklistName}
					/>
				)}
			</div>
		</div>
	);
};

export default ChecklistReportDetail;
