import React from "react";
import { useSetRecoilState } from "recoil";
import { topPdfViewerUrlAtom } from "../../contexts/topPdfViewer.recoil";
import parse, { HTMLReactParserOptions, domToReact } from "html-react-parser";
import { isMobile, isValidUrl } from "../../utils/utils";
import { Link } from "react-router-dom";
import { FaqFilter } from "../FaqFilter/FaqFilter";

interface Props {
	htmlString: string;
}

const ParseHTMLContent: React.FC<Props> = ({ htmlString }) => {
	const setPDFUrl = useSetRecoilState(topPdfViewerUrlAtom);

	const options: HTMLReactParserOptions = {
		replace: ({ name, attribs, children }: any) => {
			if (!attribs) {
				return;
			}
			if (name === "a" && attribs.role !== "button" && !attribs["data-toggle"]) {
				try {
					const to = attribs.href;

					if (isValidUrl(to)) {
						// from cdn
						if (to.includes("https://files-cdn") && to.includes(".pdf") && isMobile()) {
							return (
								<div className={"anc " + attribs.class} onClick={() => setPDFUrl(to)}>
									{domToReact(children, options)}
								</div>
							);
						}

						// in the same system just get from pathname
						const toUrl = new URL(to);
						if (
							(toUrl.hostname === window.location.hostname ||
								toUrl.hostname.includes("localhost") ||
								toUrl.hostname.includes("nuggetkms.com") ||
								toUrl.hostname.includes("mcdtkit.com")) &&
							!toUrl.hostname.includes("files-cdn")
						) {
							return (
								<Link
									to={toUrl.pathname + toUrl.search + toUrl.hash}
									target={isMobile() ? undefined : attribs.target}
									className={attribs.class}
								>
									{domToReact(children, options)}
								</Link>
							);
						}

						// for external links, last fallback
						return (
							<a href={to} target={attribs.target} rel="noopener" className={attribs.class}>
								{domToReact(children, options)}
							</a>
						);
					}
					return (
						<Link
							to={to}
							target={isMobile() ? undefined : attribs.target}
							className={attribs.class}
						>
							{domToReact(children, options)}
						</Link>
					);
				} catch (e) {
					// console.log(e);
				}
			}
			if (name === "input" && attribs.id === "faqFilter") {
				return (
					<FaqFilter
						hasHighlight={attribs["no-highlight"] !== "true"}
						itemsSelector={attribs["items-selector"] ?? undefined}
						className={attribs.class}
						placeholder={attribs.placeholder}
					/>
				);
			}
		},
	};
	return <>{parse(htmlString, options)}</>;
};

export default ParseHTMLContent;
