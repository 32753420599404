import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../../contexts/UserContext";
import useQueryParams from "../../../../hooks/useQueryParams";
import { in_array, isset, preg_match } from "../../../../utils/utils";

const UPDATE_USER_SETTINGS_MUTATION = gql`
	mutation updateUserAdditionalRoles(
		$personID: String!
		$cit_lead: Int!
		$oa: Int!
		$otp: Int!
		$support: String!
	) {
		updateTKITUserSettings(
			input: {
				tKITUserSettingsFieldsInput: {
					personID: $personID
					cit_lead: $cit_lead
					oa: $oa
					otp: $otp
					support: $support
				}
			}
		) {
			message
			didUpdate
		}
	}
`;

const UPDATE_USER_MUTATION = gql`
	mutation updateUser(
		$company_name: String!
		$company_type: String!
		$dn: String!
		$eID: String!
		$email: String!
		$last_access: String!
		$personID: String!
		$phone: String!
		$real_name: String!
		$store_number: String!
		$user_role: String!
		$access_level: String!
	) {
		updateTKITUser(
			input: {
				tKITUserFieldsInput: {
					company_name: $company_name
					company_type: $company_type
					dn: $dn
					eID: $eID
					email: $email
					last_access: $last_access
					personID: $personID
					phone: $phone
					real_name: $real_name
					store_number: $store_number
					user_role: $user_role
					access_level: $access_level
				}
			}
		) {
			message
			didUpdate
		}
	}
`;

const RESET_USER_DB_MUTATION = gql`
	mutation resetUserDB($personID: String!) {
		resetTKITUserDB(input: { personID: $personID }) {
			didUpdate
			message
		}
	}
`;

const permissionsMatrix = {
	franchisee: {
		None: ["OA"],
		"Level 1": ["CIT Lead", "OTP"],
		"Level 2": ["CIT Lead", "OTP"],
		"Level 3": ["CIT Lead", "OTP"],
		"Level 4": ["CIT Lead", "OTP"],
		"Level 5": [],
		"Level 6 ": [],
		Supervisor: ["CIT Lead", "OTP"],
		"Supervisor w/ Role Assignment": ["CIT Lead", "OTP"],
		"Organization Admin": [],
		Operator: [],
	},
	mcopco: {
		None: ["OA"],
		"Level 1": [],
		"Level 2": [],
		"Level 3": [],
		"Level 4": [],
		"Level 5": [],
		"Level 6": [],
	},
};

// Group 1: CIT Lead, OTP
// Group 2: OA
const showAdditonalRolesGroup1 = [
	"Level 1",
	"Level 2",
	"Level 3",
	"Level 4",
	"Supervisor",
	"Supervisor w/ Role Assignment",
];
const showAdditionalRolesGroup2 = ["None"];

const SuperSelect = () => {
	const query = useQueryParams();
	const { store, dispatch } = useContext(UserContext);
	const { translation, permissionGroup, personID, userSettings, userData, realName } = store;
	const qry = {
		m: query.get("m"),
		msub: query.get("msub"),
		s: query.get("s"),
		ss1: query.get("ss1"),
		p: query.get("p"),
		l: query.get("l"),
		cat: query.get("cat"),
		quiz: query.get("quiz"),
		view: query.get("view"),
		sort: query.get("sort"),
	};
	const location = useLocation();

	const [updateUserAdditionalRoles, { loading: UpdateRolesLoading }] = useMutation(
		UPDATE_USER_SETTINGS_MUTATION,
	);
	const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER_MUTATION);
	const [resetTKITUserDB, { loading: resetDBLoading }] = useMutation(RESET_USER_DB_MUTATION);

	const mutationsLoading = () => {
		return UpdateRolesLoading || updateUserLoading || resetDBLoading;
	};

	const userFieldsInput = {
		personID: personID,
		real_name: realName,
		company_type: permissionGroup.companyType,
		access_level: permissionGroup.accessLevel,

		company_name: userData.companyName,
		dn: userData.dn,
		eID: userData.eID,
		email: userData.email,
		last_access: userData.lastAccess,
		phone: userData.phone,
		store_number: userData.storeNumber,
		user_role: userData.userRole,
	};

	const citTeamIDs = [
		/* these are all personID's that get the reset database link in production */
		"163134", //Kathy Lopez
		"165398", //Bridget Weber
		"306657", //Greg Balakian
		"153380", //Regina Adams
		"1762319", //Jamie Prendergast
		/* these are the test IDs for staging */
		"SIMez712329",
		"SIMez712330",
		"SIMez712333", //stg user
		//@TODO REMOVE THIS< TESTING PURPOSE ONLY
		"2317651",
	];

	const $SHOW_ERRORS = false;

	//functions
	function get_companyType_title(role) {
		let companyType_title = "";
		switch (role) {
			case "franchisee":
				companyType_title = "Franchisee";
				break;
			case "mcopco":
				companyType_title = "McOpCo";
				break;
			default:
		}
		return <b>{companyType_title}, </b>;
	}

	function get_accessLevel_title(level) {
		let accessLevelTitle = "";
		switch (level) {
			case "None":
				accessLevelTitle = "McD Staff";
				break;
			case "Level 1":
				accessLevelTitle = "General Manager";
				break;
			case "Level 2":
				accessLevelTitle = "Kitchen Manager";
				break;
			case "Level 3":
				accessLevelTitle = "People Manager";
				break;
			case "Level 4":
				accessLevelTitle = "Guest Services Manager";
				break;
			case "Level 5":
				accessLevelTitle = "Shift Manager";
				break;
			case "Level 6":
				accessLevelTitle = "Manager Trainee";
				break;
			default:
				accessLevelTitle = level;
		}
		return <b>{accessLevelTitle}</b>;
	}

	function get_customRole_title(roles) {
		const roleTitles = {
			OTP: "Tech Lead",
			"CIT Lead": "CIT Lead",
			OA: "Operations Associate",
		};

		const customRoleTitles = roles
			.map((role) => roleTitles[role])
			.filter((title) => title !== undefined);

		return (
			<span>
				, and{" "}
				<b>{customRoleTitles.length > 0 ? customRoleTitles.join(", ") : "no additional roles"}</b>
			</span>
		);
	}

	const defaultCompanyType = () => {
		const realSupport = userSettings.support === "None" ? "franchisee" : userSettings.support;
		return realSupport || permissionGroup.companyType || "franchisee";
	};

	const resetDB = () => {
		resetTKITUserDB({
			variables: {
				personID,
			},
		}).then(() => {
			//reload Page
			window.location.reload(false);
		});
	};

	const updateCompanyType = async (newCompanyType) => {
		const newAccessLevel = validateAccessLevel(permissionGroup.accessLevel, newCompanyType);
		const newCustomRole = validateCustomRoles(
			newCompanyType,
			newAccessLevel,
			permissionGroup.customRole,
		);

		dispatch({
			type: "setPermissionGroup",
			data: {
				companyType: newCompanyType,
				accessLevel: newAccessLevel,
				customRole: newCustomRole,
			},
		});

		dispatch({
			type: "setUserSettings",
			data: {
				...userSettings,
				citLead: newCustomRole.includes("CIT Lead") ? 1 : 0,
				otp: newCustomRole.includes("OTP") ? 1 : 0,
				oa: newCustomRole.includes("OA") ? 1 : 0,
				support: newCompanyType,
			},
		});

		await Promise.all([
			updateUser({
				variables: {
					...userFieldsInput,
					personID,
					company_type: newCompanyType,
					access_level: newAccessLevel,
				},
			}),
			updateUserAdditionalRoles({
				variables: {
					personID,
					oa: newCustomRole.includes("OA") ? 1 : 0,
					support: newCompanyType,
					cit_lead: newCustomRole.includes("CIT Lead") ? 1 : 0,
					otp: newCustomRole.includes("OTP") ? 1 : 0,
				},
			}),
		]);
	};

	const updateAccessLevel = async (newAccessLevel) => {
		newAccessLevel = validateAccessLevel(newAccessLevel, permissionGroup.companyType);

		try {
			// Update user access level in the database
			await updateUser({
				variables: {
					...userFieldsInput,
					personID,
					access_level: newAccessLevel,
				},
			});

			// Reset custom roles to none (empty array)
			await updateUserAdditionalRoles({
				variables: {
					personID,
					oa: 0,
					support: userSettings.support,
					cit_lead: 0,
					otp: 0,
				},
			});

			// Update local state
			dispatch({
				type: "setPermissionGroup",
				data: {
					...permissionGroup,
					accessLevel: newAccessLevel,
					customRole: ["None"],
				},
			});

			dispatch({
				type: "setUserSettings",
				data: {
					...userSettings,
					citLead: 0,
					otp: 0,
					oa: 0,
				},
			});
		} catch (error) {
			console.error("Failed to update access level and reset roles:", error);
			// Handle error (e.g., show error message to user)
		}
	};

	const updateCustomRoles = async (role, isChecked) => {
		let newCustomRoles;

		if (role === "None" && isChecked) {
			newCustomRoles = ["None"];
		} else if (role === "None" && !isChecked) {
			newCustomRoles = [];
		} else if (isChecked) {
			newCustomRoles = permissionGroup.customRole.filter((r) => r !== "None");
			newCustomRoles.push(role);
		} else {
			newCustomRoles = permissionGroup.customRole.filter((r) => r !== role && r !== "None");
		}

		if (newCustomRoles.length === 0) {
			newCustomRoles = ["None"];
		}

		// Validate the new custom roles
		newCustomRoles = validateCustomRoles(
			permissionGroup.companyType,
			permissionGroup.accessLevel,
			newCustomRoles,
		);

		// Calculate the new values for API update
		const newCITLead = newCustomRoles.includes("CIT Lead") ? 1 : 0;
		const newOTP = newCustomRoles.includes("OTP") ? 1 : 0;
		const newOA = newCustomRoles.includes("OA") ? 1 : 0;

		// Update the context
		dispatch({
			type: "setPermissionGroup",
			data: {
				...permissionGroup,
				customRole: newCustomRoles,
			},
		});

		// Update the API
		try {
			await updateUserAdditionalRoles({
				variables: {
					personID,
					oa: newOA,
					support: userSettings.support,
					cit_lead: newCITLead,
					otp: newOTP,
				},
			});

			dispatch({
				type: "setUserSettings",
				data: {
					...userSettings,
					citLead: newCITLead,
					otp: newOTP,
					oa: newOA,
				},
			});
		} catch (error) {
			console.error("Failed to update role:", error);
			// Handle error (e.g., show error message to user)
		}
	};

	// Takes a combination of permissions and returns only the allowed roles
	const validateCustomRoles = (companyType, accessLevel, customRole) => {
		const allowedRoles = permissionsMatrix[companyType]?.[accessLevel] || [];
		const validatedRoles = customRole.filter(
			(role) => allowedRoles.includes(role) || role === "None",
		);

		return validatedRoles.length > 0 ? validatedRoles : ["None"];
	};

	const validateAccessLevel = (accessLevel, companyType) => {
		const validatedAccessLevels = Object.keys(permissionsMatrix[companyType] || {});
		return validatedAccessLevels.includes(accessLevel) ? accessLevel : "None";
	};

	const RoleCheckbox = ({ role, label }) => {
		const isChecked = permissionGroup.customRole.includes(role);
		const isDisabled = role === "None" && isChecked;

		return (
			<div className="role-switch">
				<label>
					<input
						type="checkbox"
						value={role}
						checked={isChecked}
						onChange={(e) => updateCustomRoles(role, e.target.checked)}
						disabled={isDisabled}
					/>
					{label}
				</label>
			</div>
		);
	};

	return (
		<div id="superSelect">
			<div className="row">
				<div className="col-xs-12">
					<p id="minMaxSuperSelect" className="minimize">
						{translation("Currently viewing as ", "Viendo como: ")}
						{get_companyType_title(permissionGroup.companyType)}
						{permissionGroup.companyType === "mcopco" && <> and </>}
						{get_accessLevel_title(permissionGroup.accessLevel)}
						{permissionGroup.companyType === "franchisee" ? (
							<>{get_customRole_title(permissionGroup.customRole)}</>
						) : (
							<>.</>
						)}
					</p>
				</div>
				<div className="col-xs-12">
					<form
						id="superSelectForm"
						className=""
						name="superSelectForm"
						method="get"
						//@TODO verify if same effect
						// action={$_SERVER["REQUEST_URI"]}
						action={location.pathname + location.search}
					>
						{isset(qry.m) ? (
							<input type="hidden" name="m" value={qry.m} />
						) : (
							<input type="hidden" name="m" value="" />
						)}

						{isset(qry.msub) ? (
							<input type="hidden" name="msub" value={qry.msub} />
						) : (
							<input type="hidden" name="msub" value="" />
						)}

						{isset(qry.s) && <input type="hidden" name="s" value={qry.s} />}

						{isset(qry.ss1) && <input type="hidden" name="ss1" value={qry.ss1} />}
						{isset(qry.p) && <input type="hidden" name="p" value={qry.p} />}
						{isset(qry.l) && <input type="hidden" name="l" value={qry.l} />}
						{isset(qry.cat) && <input type="hidden" name="cat" value={qry.cat} />}
						{isset(qry.quiz) && <input type="hidden" name="quiz" value={qry.quiz} />}
						{isset(qry.view) && <input type="hidden" name="view" value={qry.view} />}
						{isset(qry.sort) && <input type="hidden" name="sort" value={qry.sort} />}
						{isset(qry.t) && <input type="hidden" name="t" value={qry.t} />}
						{isset(qry.q) && (
							<>
								{/* @TODO investigate what this $q was supposed to to */}
								{() => {
									if (preg_match('/"/', qry.q)) {
										/* remove quotes */
										// $q = str_replace('"', "'", qry.q);
									} else if (preg_match("/'/", qry.q)) {
										// $q = str_replace("'", "'", qry.q);
									}
									return <input type="hidden" name="q" value={qry.q} />;
								}}
							</>
						)}
						{$SHOW_ERRORS && (
							<div>
								sso_supportType is set to {userSettings.support} <br />
								sso_companyType is set to {permissionGroup.companyType} <br />
							</div>
						)}

						<ul>
							<li>
								<label htmlFor="superSelectCompanyType">
									{translation("Company Type:", "Tipo de Restaurante:")}
								</label>
								<select
									className={mutationsLoading() ? "disabled" : ""}
									name="superSelectCompanyType"
									id="superSelectCompanyType"
									onChange={(e) => updateCompanyType(e.target.value)}
									value={defaultCompanyType()}
								>
									<option value="franchisee">{translation("Franchisee", "Franquisia")}</option>
									<option value="mcopco">McOpCo</option>
								</select>
							</li>
							<li>
								<label htmlFor="superSelectAccessLevel">
									{translation("Access Level:", "Nivel de Acceso:")}
								</label>
								<select
									className={mutationsLoading() ? "disabled" : ""}
									name="superSelectAccessLevel"
									id="superSelectAccessLevel"
									onChange={(e) => updateAccessLevel(e.target.value)}
									value={permissionGroup.accessLevel}
								>
									<option value="None">{translation("McD Staff", "Personal de McD")}</option>
									<option value="Level 1">
										{translation("General Manager", "Gerente General")}
									</option>
									<option value="Level 2">
										{translation("Kitchen Manager", "Gerente de Cocina")}
									</option>
									<option value="Level 3">
										{translation("People Manager", "Gerente de Personal")}
									</option>
									<option value="Level 4">
										{translation("Guest Services Manager", "Gerente de Servicios al Cliente")}
									</option>
									<option value="Level 5">
										{translation("Shift Manager", "Gerente de Turno")}
									</option>
									<option value="Level 6">
										{translation("Manager Trainee", "Aprendíz de Gerente")}
									</option>

									{permissionGroup.companyType === "franchisee" && (
										<>
											<option value="Supervisor">
												{translation("Mid Manager", "Gerente Intermedio")}
											</option>
											<option value="Supervisor w/ Role Assignment">
												{translation("Supervisor w/ Role Assignment", "Supervisor con Función")}
											</option>
											<option value="Organization Admin">
												{translation("Organization Admin", "Administrador de Organización")}
											</option>
											<option value="Operator">
												{translation("Owner/Operator", "Propietario/Operador")}
											</option>
										</>
									)}
								</select>
							</li>
							<label htmlFor="superSelectCustomRole">
								{translation("Additional Roles:", "Funciones Adicionales:")}
							</label>
							<div name="superSelectCustomRole" id="superSelectCustomRole">
								<RoleCheckbox role="None" label={translation("None", "Ninguno")} />

								{in_array(permissionGroup.accessLevel, showAdditonalRolesGroup1) &&
									in_array(permissionGroup.companyType, ["franchisee"]) && (
										<>
											<RoleCheckbox
												role="CIT Lead"
												label={translation("CIT Lead", "Líder de CIT")}
											/>
											<RoleCheckbox
												role="OTP"
												label={translation("Tech Lead", "Líder de Technología")}
											/>
										</>
									)}

								{in_array(permissionGroup.accessLevel, showAdditionalRolesGroup2) && (
									<RoleCheckbox
										role="OA"
										label={translation("Operations Associate", "Asociada de Operaciones")}
									/>
								)}
							</div>
							<li>
								{(in_array(personID, citTeamIDs) ||
									location.pathname === "https://mcdtkit.test/") && (
									<div
										className={"anc " + (mutationsLoading() ? "disabled" : "")}
										onClick={() => resetDB()}
									>
										{translation("Reset Databases", "Reiniciar Base de Datos")}
									</div>
								)}
							</li>
						</ul>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SuperSelect;
