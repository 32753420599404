import React from "react";
import "./formItem.scss";
interface FormItemProps {
	name: string;
	description: string;
	answerFields: { type: string }[];
}

export const FormItem: React.FC<FormItemProps> = ({ name, description, answerFields }) => {
	return (
		<div className="form-item">
			<div>
				<h1 id="FormItem-header">{name}</h1>
				<p>{description}</p>
			</div>
			<div className="input-container">
				{answerFields.map((field, index) => {
					return <InputField key={index} type={field.type} />;
				})}
			</div>
		</div>
	);
};

interface InputFieldProps {
	type: string;
}

export const InputField: React.FC<InputFieldProps> = ({ type }) => {
	switch (type) {
		case "SELECT1":
			return (
				<>
					<select className="select-dropdown">
						<option>Select person</option>
					</select>
				</>
			);
		case "SELECT2":
			return (
				<>
					<select className="select-dropdown">
						<option className="option">Select person</option>
					</select>
					<select className="select-dropdown">
						<option>Select frequency</option>
					</select>
				</>
			);
		case "DATE":
			return <input type="date" />;
		case "INPUT":
			return (
				<>
					<input className="formItem-inputBox" type="input" placeholder="Enter your answer" />
				</>
			);
		case "TEXTAREA":
			return <textarea id="textarea-inputbox" placeholder="Enter process" />;
		case "RADIO":
			return (
				<div>
					<input type="select" value="option1" />
					<input type="radio" value="option1" />
					<label>Option 1</label>
					<input type="radio" value="option2" />
					<label>Option 2</label>
				</div>
			);
		case "CHECKBOX":
			return <input type="checkbox" />;
		default:
			return null;
	}
};
