import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import { imgPath } from "../../../constants/PathName";
import { useMyBadges } from "../../../contexts/MyBadges.recoil";
import { UserContext } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";
import { in_array, isset } from "../../../utils/utils";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";

const GetBadges = ({ badges, status }) => {
	//state
	const [completeBadges, setCompleteBadges] = useState([]);
	const [incompleteBadges, setIncompleteBadges] = useState([]);

	//recoils / context
	const { store } = useContext(UserContext);
	const { translation } = store;

	//custom hooks
	const query = useQueryParams();
	const { myBadges, getUserBadges, loading, error } = useMyBadges();

	//local variables
	const page = query.get("p");

	useEffect(() => {
		if (myBadges) {
			let comp = Object.keys(myBadges).filter((badgeID) => myBadges[badgeID] === 1);
			let incomp = Object.keys(myBadges).filter((badgeID) => myBadges[badgeID] === 0);
			comp = comp.map((el) => el.replace("_", "-"));
			incomp = incomp.map((el) => el.replace("_", "-"));
			setCompleteBadges(comp);
			setIncompleteBadges(incomp);
		}
	}, [myBadges]);

	useEffect(() => {
		getUserBadges();
	}, []);

	//jsx variables
	if (loading) return <Loading />;
	if (error) return <p>Unable to find User Badges :(</p>;

	return (
		<ul className={"badges " + page}>
			{badges.map(({ userBadgeFields: badgeInfo }, key) => {
				return (
					<React.Fragment key={key}>
						{status === "complete" && (
							<>
								{/* DEPENDS ON SUPER BADGES */}
								{/* {type === "core" && (
									<>
										{in_array(badgeInfo["id"], $badges_complete_core) && (
											<li>
												<img src={$imagepath + "badges/" + badgeInfo["id"] + ".svg"} />
												{isset($_GET["p"]) && $_GET["p"] === "my-badges" && (
													<p>{translation(badgeInfo["title"], badgeInfo["title_es"])}</p>
												)}
											</li>
										)}
									</>
								)}

								{type === "additional" && (
									<>
										{in_array(badgeInfo["id"], $badges_complete_additional) && (
											<li>
												<img src={$imagepath + "badges/" + badgeInfo["id"] + ".svg"} />
												{$_GET["p"] === "my-badges" && (
													<p>{translation(badgeInfo["title"], badgeInfo["title_es"])}</p>
												)}
											</li>
										)}
									</>
								)} */}
								{in_array(badgeInfo["id"], completeBadges) && (
									<li>
										<img src={imgPath + "/badges/" + badgeInfo["id"] + ".svg"} alt="" />
										{isset(page) && page === "my-badges" && (
											<p>
												<ParseHTMLContent
													htmlString={translation(badgeInfo["title"], badgeInfo["title_es"])}
												/>
											</p>
										)}
									</li>
								)}
							</>
						)}
						{status === "incomplete" && (
							<>
								{in_array(badgeInfo["id"], incompleteBadges) && (
									<li>
										<img src={imgPath + "/badges/" + badgeInfo["id"] + "-bw.svg"} alt="" />
										{page === "my-badges" && (
											<p>
												<ParseHTMLContent
													htmlString={translation(badgeInfo["title"], badgeInfo["title_es"])}
												/>
											</p>
										)}
									</li>
								)}
							</>
						)}
					</React.Fragment>
				);
			})}
		</ul>
	);
};

export default GetBadges;
