import React, { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import ParseHTMLContent from "../../../../components/ParseHTMLContent/ParseHTMLContent";

const Intro = ({ flashcard, next, exit }) => {
	const { store } = useContext(UserContext);
	const { translation } = store;
	return (
		<div id="intro">
			<div className="flashcard qa intro">
				<div>
					<h1>
						<ParseHTMLContent
							htmlString={translation(
								flashcard.flashcardFields.title,
								flashcard.flashcardFields.titleEs,
							)}
						/>
					</h1>
					<p>
						<ParseHTMLContent
							htmlString={translation(
								flashcard.flashcardFields.description,
								flashcard.flashcardFields.descriptionEs,
							)}
						/>
					</p>
					<p>{translation("Instructions", "Instrucciones")}</p>
					<ul className="instructions">
						<li>
							{translation(
								"Select your answer to see your result.",
								"Selecciona tu respuesta para ver el resultado.",
							)}
						</li>
						<li>
							{translation(
								"Click the card to flip back to the question.",
								"Haz clic en la tarjeta para voltearla a la pregunta",
							)}
						</li>
						<li>
							{translation(
								'Move to the next questions by clicking "next question" under each card.',
								'Vé a la siguiente preguna haciendo clic en "siguiente pregunta" debajo de cada tarjeta',
							)}
						</li>
					</ul>
					<a
						id="startQuiz"
						href="#start"
						className="btn btn-primary"
						onClick={(e) => {
							e.preventDefault();
							next();
						}}
					>
						{translation("Let's Go!", "¡Vamos!")}
					</a>
				</div>
			</div>
			<a
				className=" btn"
				href="#exit"
				onClick={(e) => {
					e.preventDefault();
					exit();
				}}
			>
				{translation("Exit", "Salir")}
			</a>
		</div>
	);
};

export default Intro;
