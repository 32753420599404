import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

const _appActive = atom<string>({
	key: "appActive",
	default: "",
});

export const useGetAppActive = () => {
	const appActive = useRecoilValue(_appActive);

	return { appActive };
};

export const useSetAppActive = () => {
	const setAppActive = useSetRecoilState(_appActive);

	return { setAppActive };
};

export const useResetAppActive = () => {
	const resetAppActive = useResetRecoilState(_appActive);

	return { resetAppActive };
};
