import React, { useContext, useState } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import Intro from "./Intro";
import Question from "./Question";
import { Flashcard } from "../../../../generated/graphql";

interface QuizProps {
	flashcard: Flashcard;
	exit: () => void;
	updateTask: () => void;
	completedTaskLoading: boolean;
}

const Quiz: React.FC<QuizProps> = ({ flashcard, exit, updateTask, completedTaskLoading }) => {
	const { store } = useContext(UserContext);
	const { translation } = store;

	const [currentQuestionIndex, setCurrentQuestion] = useState<number>(-1);

	const nextQuestion = () => {
		setCurrentQuestion(currentQuestionIndex + 1);
	};

	return (
		<div className="row">
			<div className="col-xs-12 col-sm-6 col-sm-offset-3">
				{currentQuestionIndex === -1 && (
					<Intro flashcard={flashcard} next={nextQuestion} exit={exit} />
				)}
				{currentQuestionIndex > -1 &&
					flashcard.flashcardFields?.qa != null &&
					currentQuestionIndex < flashcard.flashcardFields.qa.length && (
						<Question
							index={currentQuestionIndex}
							quizClass={flashcard.flashcardFields.class}
							qa={flashcard.flashcardFields.qa[currentQuestionIndex]}
							exit={exit}
							next={nextQuestion}
						/>
					)}
				{flashcard.flashcardFields?.qa != null &&
					currentQuestionIndex === flashcard.flashcardFields.qa.length && (
						<div id="done" className="qa-container">
							<div
								className={"anc " + (completedTaskLoading ? "disabled" : "")}
								onClick={() => updateTask()}
							>
								<div id="question" className="flashcard qa done animated flipInX">
									<div>
										<h2>✅ {translation("All Done", "Terminado")}</h2>
										<h3>🕹️ {translation("Thanks for playing", "Gracias por jugar")}</h3>
										<p>{translation("Click me to go back<", "Haz clic aquí para regresar")}</p>
									</div>
								</div>
							</div>
						</div>
					)}
			</div>
		</div>
	);
};

export default Quiz;
