// import React, { useContext } from "react";
// import { useLocation } from "react-router";
// import { UserContext } from "../../../contexts/UserContext";
// import useQueryParams from "../../../hooks/useQueryParams";

import { useSetRecoilState } from "recoil";
import { modalRenderAtom } from "../../../contexts/modal.recoil";

const FeedbackModal = ({ store, query, location }) => {
	// const { store } = useContext(UserContext);
	const { translation } = store;
	// const query = useQueryParams();
	const qModule = query.get("m");
	const qSubModule = query.get("msub");
	const qLanguage = query.get("l");
	// const location = useLocation();

	const setModalRender = useSetRecoilState(modalRenderAtom);

	return (
		// <div className="" id="feedback" tabIndex="-1" role="dialog" aria-labelledby="feedbackModal">
		// <div className="modal-dialog" role="document">
		<div className="feedback-modal">
			<div className="modal-header">
				{/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
					{" "}
					<span aria-hidden="true">&times;</span>{" "}
				</button> */}
				<h3 className="modal-title" id="feedbackModalLabel">
					{translation("Can't Find Something?", " No Puedes Encontrar Algo?")}
				</h3>
			</div>
			{/* <div className="modal-body"> */}
			<div className="row">
				<div className="col-xs-12">
					<form
						id="feedbackForm"
						name="feedback-form"
						method="POST"
						action={
							"feedback.php" +
							"?m=" +
							qModule +
							"&msub=" +
							qSubModule +
							"&l=" +
							qLanguage +
							"&submit=true"
						}
					>
						<p>
							<strong
								dangerouslySetInnerHTML={{
									__html: translation(
										"For technical issues, please open a support ticket with ATOS <br />(800-515-3636).",
										"Para problemas t&eacute;cnicos, por favor abre un ticket de spoporte con ATOS (800-515-3636).",
									),
								}}
							/>
						</p>
						<p
							dangerouslySetInnerHTML={{
								__html: translation(
									"Please share your feedback <strong>here</strong> if:",
									"Por favor comparte tus comentarios <strong>aquí</strong> si...",
								),
							}}
						/>
						<ul className="indented">
							<li>
								{translation(
									"you couldn't find what you were looking for in Learn As You Go",
									"no pudiste encontrar lo que estabas buscando en Aprende Mientras Trabajas (LAYGO)",
								)}
							</li>
							<li>
								{translation(
									"you have a suggestion on something you'd like added to Learn As You Go",
									"tienes una sugerencia sobre algo que te gustar&iacute;a que fuese agregado a Aprende Mientras Trabajas (LAYGO).",
								)}
							</li>
						</ul>
						<p>
							{translation(
								"We review and consider all feedback we receive and are grateful for yours!",
								"&iexcl;Revisamos y consideramos todos los comentarios que recibimos y estamos agradecidos por los tuyos!",
							)}
						</p>
						<label htmlFor="comments">
							{translation("Please provide your feedback:", "Por favor provee tus comentarios:")}{" "}
						</label>
						<textarea name="comments" required></textarea>
						<label htmlFor="store">
							{translation(
								"Please provide your store number:",
								"Por favor provee el n&uacute;mero de tu tienda:",
							)}{" "}
						</label>
						<input name="store" type="text" />
						<input
							id="pageUrl"
							name="pageUrl"
							type="hidden"
							value={location.pathname + location.search}
						/>
						<button
							name="close"
							type="button"
							className="btn btn-default"
							data-dismiss="modal"
							onClick={() => setModalRender(undefined)}
						>
							{translation("Close", "Cerrar")}
						</button>
						<button name="submit" type="submit" value="submit" className="btn btn-primary">
							{translation("Send", "Enviar")}
						</button>
					</form>
				</div>
			</div>
			{/* </div> */}
		</div>
		// </div>
		// </div>
	);
};

export default FeedbackModal;
