import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { printRenderAtom } from "../../contexts/print.recoil";
// import "./printarea.scss";

const PrintArea = () => {
	const [render, setRender] = useRecoilState(printRenderAtom);

	useEffect(() => {
		if (render) {
			window.print();
			window.onafterprint = () => {
				setRender(undefined);
			};
		}
	}, [render]);

	if (!render) {
		return null;
	}

	const css = `
	.print-area {
		display: none;
	}
	
	@media print {
		html,
		body {
			background: white;
			color: black;
		}
		body > div > :not(.print-area) {
			display: none !important;
		}
		body > div > .print-area {
			margin: 0;
			padding: 0;
			display: block;
		}
	}
	`;

	return (
		<div className="print-area" data-testid="print-area">
			<style>{css}</style>
			{render}
		</div>
	);
};

export default PrintArea;
