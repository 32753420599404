import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";

// @TODO where?
const MySettingsUpdate = () => {
	const query = useQueryParams();
	const systems = query.get("systems");
	const ref = query.get("ref");
	const citLead = query.get("cit-lead");
	const otp = query.get("otp");
	const supportRoles = query.get("support-roles");
	const oa = query.get("oa");
	const fieldOffice = query.get("field-office");
	const l = query.get("l");

	const { store } = useContext(UserContext);
	const { translation } = store;

	return <div className="col-xs-12">My Settings Update</div>;
};

export default MySettingsUpdate;
