import React, { useCallback } from "react";
import "./healthChecksTable.scss";
import { StoreForm } from "../../../types/formTypes";
import { healthChecksDataTable } from "../../../utils/deleteme/formsData";
import { modalRenderAtom, modalTitleAtom } from "../../../contexts/modal.recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { HealthCheckModal } from "./healthCheckModal/HealthCheckModal";
import { useHistory } from "react-router-dom";
import { healthCheckFilterValueAtom } from "../healthChecksArea/healthChecksArea.hooks";

export const HealthChecksTable = () => {
	const setModalRender = useSetRecoilState(modalRenderAtom);
	const setModalTitle = useSetRecoilState(modalTitleAtom);
	const history = useHistory();

	const handleViewHistory = useCallback(
		(index: number) => {
			setModalTitle("Health Check History");
			setModalRender(<HealthCheckModal healthChecks={healthChecksDataTable} />);
		},
		[setModalTitle, setModalRender],
	);

	const handleViewLatest = (index: number) => {
		history.push("/mcd-forms/?m=fsl&form=2&submission=2");
	};
	const handleViewSection = (index: number) => {
		history.push("/mcd-forms/?m=fsl&form=2&submission=2");
	};
	const filterValue = useRecoilValue(healthCheckFilterValueAtom);

	const filteredHealthChecks = healthChecksDataTable.filter((entry) =>
		entry.restaurantNSN.toString().includes(filterValue.toString().trim()),
	);

	return (
		<div>
			<table className="health-checks-table">
				<thead>
					<tr>
						<th>Restaurant NSN</th>
						<th>Restaurant Name</th>
						<th>Last Health Check</th>
						<th>Completed By</th>
						<th>Action Plan?</th>
						<th>View Health Check</th>
					</tr>
				</thead>
				<tbody>
					{filteredHealthChecks.map((entry, index) => (
						<tr key={index}>
							<td>{entry.restaurantNSN}</td>
							<td>{entry.restaurantName}</td>
							<td>{entry.createdAt}</td>
							<td>{entry.completedBy}</td>
							<td>
								{" "}
								{entry.hasActionPlan ? (
									<span>
										Yes |{" "}
										<span className="anc" onClick={() => handleViewSection(index)}>
											View
										</span>
									</span>
								) : (
									"No"
								)}
							</td>
							<td>
								<span className="anc" onClick={() => handleViewHistory(index)}>
									History
								</span>{" "}
								|{" "}
								<span className="anc" onClick={() => handleViewLatest(index)}>
									View Latest
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
