import useWindowSize from "./useWindowSize";

export const useWindowBreakpoints = () => {
	const size = useWindowSize();

	const isWindowMobile = size.width <= 510;
	const isWindowTablet = size.width > 510 && size.width <= 1100;
	const isWindowDesktop = size.width > 1100;

	return {
		isWindowMobile,
		isWindowTablet,
		isWindowDesktop,
	};
};
