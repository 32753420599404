import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useContext, useEffect, useState } from "react";
import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import { UserContext } from "../contexts/UserContext";
import {
	useGetOrCreateTkitUserMutation,
	useGetTkitUserSettingsLazyQuery,
} from "../generated/graphql";

// Does not return data as result. Use UserContext to get data
const useGetCurrentUser = () => {
	const [getCurrentUserRes, setGetCurrentUserRes] = useState<{
		isCalled: boolean;
		isLoading: boolean;
		error: any;
	}>({
		isCalled: false,
		isLoading: false,
		error: undefined,
	});
	const { store: userStore, dispatch } = useContext(UserContext) as any;

	const [getOrCreateTKITUser, getOrCreateTKITUserRes] = useGetOrCreateTkitUserMutation();

	const [getUserSettings, getUserSettingsRes] = useGetTkitUserSettingsLazyQuery();

	const getCurrentUser = () => {
		const token = localStorage.getItem(LocalStorageKeys.TOKEN);
		if (token) {
			setGetCurrentUserRes({
				isCalled: true,
				isLoading: true,
				error: undefined,
			});
			getOrCreateTKITUser();
		} else {
			setGetCurrentUserRes((state) => {
				return {
					...state,
					isCalled: true,
					isLoading: false,
					error: "No token",
				};
			});
		}
	};

	const permissionGroupCustomRole = ({ cit_lead, otp, oa }: any) => {
		const arr: string[] = [];
		cit_lead === 1 && arr.push("CIT Lead");
		otp === 1 && arr.push("OTP");
		oa === 1 && arr.push("OA");
		cit_lead === 0 && otp === 0 && arr.push("None");
		return arr;
	};

	useEffect(() => {
		if (
			getOrCreateTKITUserRes.called &&
			!getOrCreateTKITUserRes.loading &&
			getOrCreateTKITUserRes.data?.getOrCreateTKITUser
		) {
			getUserSettings();
		}
	}, [getOrCreateTKITUserRes.called, getOrCreateTKITUserRes.loading, getOrCreateTKITUserRes.data]);

	useEffect(() => {
		if (
			getOrCreateTKITUserRes.called &&
			!getOrCreateTKITUserRes.loading &&
			getOrCreateTKITUserRes.data?.getOrCreateTKITUser &&
			getUserSettingsRes.called &&
			!getUserSettingsRes.loading &&
			getUserSettingsRes.data?.getTKITUserSettings
		) {
			// ADD ALL USER DATA TO THE CONTEXT
			const { cit_lead, oa, otp, support, field_office, systems } =
				getUserSettingsRes.data.getTKITUserSettings;
			const {
				personID,
				company_type,
				access_level,
				company_name,
				dn,
				eID,
				email,
				last_access,
				real_name,
				phone,
				store_number,
				user_role,
			} = getOrCreateTKITUserRes.data.getOrCreateTKITUser.user!;
			const { isSuperUser } = getOrCreateTKITUserRes.data.getOrCreateTKITUser;
			dispatch({
				type: "setPersonID",
				data: personID,
			});
			dispatch({
				type: "setRealName",
				data: real_name,
			});
			dispatch({
				type: "setIsSuperUser",
				data: isSuperUser,
			});
			dispatch({
				type: "setPermissionGroup",
				data: {
					companyType: company_type || "franchisee",
					accessLevel: access_level || "None",
					customRole: permissionGroupCustomRole(getUserSettingsRes.data.getTKITUserSettings),
				},
			});
			dispatch({
				type: "setUserSettings",
				data: {
					support,
					citLead: cit_lead,
					otp,
					oa,
					fieldOffice: field_office,
					systems,
				},
			});
			dispatch({
				type: "setUserData",
				data: {
					dn,
					eID,
					userRole: user_role,
					companyName: company_name,
					email,
					phone,
					lastAccess: last_access,
					storeNumber: store_number,
				},
			});
		}
	}, [
		getOrCreateTKITUserRes.called,
		getOrCreateTKITUserRes.loading,
		getOrCreateTKITUserRes.data,
		getUserSettingsRes.called,
		getUserSettingsRes.loading,
		getUserSettingsRes.data,
	]);

	useEffect(() => {
		if (getOrCreateTKITUserRes.error) {
			setGetCurrentUserRes((state) => {
				return {
					...state,
					isLoading: false,
					error: getOrCreateTKITUserRes.error,
				};
			});
		}
	}, [getOrCreateTKITUserRes.error]);

	useEffect(() => {
		if (getUserSettingsRes.error) {
			setGetCurrentUserRes((state) => {
				return {
					...state,
					isLoading: false,
					error: getUserSettingsRes.error,
				};
			});
		}
	}, [getUserSettingsRes.error]);

	useEffect(() => {
		if (userStore.personID) {
			setGetCurrentUserRes((state) => {
				return {
					...state,
					isLoading: false,
				};
			});
		}
	}, [userStore]);

	return {
		getCurrentUser,
		getCurrentUserRes,
	};
};

export default useGetCurrentUser;
