import React from "react";
import "./formControls.scss";
import "../formFooter.scss";
interface FormControlsProps {
	onClose: () => void;
	onNext: () => void;
	onPrevious: () => void;
}

export const FormControls: React.FC<FormControlsProps> = ({ onClose, onNext, onPrevious }) => {
	return (
		<div className="form-controls">
			<button className="close-navBar" onClick={onClose}>
				Close
			</button>
			<div className="pre-next">
				<button className="previous-navBar" onClick={onPrevious}>
					Previous
				</button>
				<button className="next-navBar" onClick={onNext}>
					Next
				</button>
			</div>
		</div>
	);
};
