import React from "react";
import "./not-accessible.scss";

export const NotAccessible = () => {
	const EMAIL = "nuggetkms@traceablechange.com";
	const subject = "Not Accessible";

	const mailToLink = `mailto:${EMAIL}?subject=${subject}`;

	return (
		<div className="not-accessible">
			<span>
				This page is not accessible with your user profile. If you believe you should access to this
				page, please contact <a href={mailToLink}>nuggetkms@traceablechange.com</a>. Thank you!
			</span>
		</div>
	);
};
