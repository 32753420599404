import { useContext, useEffect } from "react";
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { DashboardMenu_Dashboardmenufields } from "../generated/graphql";
import { strip_tags } from "../utils/utils";
import { UserContext } from "./UserContext";
import {
	activeDashboardMenuAtom,
	dashboardMenuSectionsAtom,
	dashboardParentColorAtom,
	dashboardTitleAtom,
} from "./DashboardMenus.recoil";
import { _navActiveAtom } from "./Navbar.recoil";

export const sidebarActiveMenuAtom = atom<string>({
	key: "sidebarActiveMenu",
	default: "root",
});

export const sidebarTitleAtom = atom<string>({
	key: "sidebarTitle",
	default: "Dashboard",
});

export const sidebarParentColorAtom = atom<string>({
	key: "sidebarParentColor",
	default: "#828282",
});

export const sidebarParentItemAtom = atom<DashboardMenu_Dashboardmenufields | undefined>({
	key: "sidebarParentItem",
	default: undefined,
});

export const useSidebarMenu = () => {
	const [sidebarActiveMenu, setSidebarActiveMenu] = useRecoilState(sidebarActiveMenuAtom);
	const [sidebarTitle, setSidebarTitle] = useRecoilState(sidebarTitleAtom);
	const [sidebarParentColor, setSidebarParentColor] = useRecoilState(sidebarParentColorAtom);
	const [sidebarParentItem, setSidebarParentItem] = useRecoilState(sidebarParentItemAtom);

	const resetSidebarActiveMenu = useResetRecoilState(sidebarActiveMenuAtom);
	const resetSidebarTitle = useResetRecoilState(sidebarTitleAtom);
	const resetSidebarParentColor = useResetRecoilState(sidebarParentColorAtom);

	// depends on main dashboard menu recoils
	const activeDashboardMenu = useRecoilValue(activeDashboardMenuAtom);
	const dashboardTitle = useRecoilValue(dashboardTitleAtom);
	const dashboardParentColor = useRecoilValue(dashboardParentColorAtom);
	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const navActive = useRecoilValue(_navActiveAtom);

	const { store } = useContext(UserContext);
	const { translation } = store;

	const findParentItem = (parentID: string) => {
		var parentItem: DashboardMenu_Dashboardmenufields | undefined;
		Object.values(dashboardMenuSections).forEach((dashboardMenuSection) => {
			dashboardMenuSection.forEach((menuItem) => {
				if (menuItem.id === parentID) parentItem = menuItem;
			});
		});
		return parentItem;
	};

	useEffect(() => {
		return () => {
			resetSidebarActiveMenu();
			resetSidebarTitle();
			resetSidebarParentColor();
		};
	}, []);

	useEffect(() => {
		setSidebarActiveMenu(activeDashboardMenu);
		setSidebarTitle(dashboardTitle);
		setSidebarParentColor(dashboardParentColor);
	}, [activeDashboardMenu, dashboardTitle, dashboardParentColor, navActive]);

	useEffect(() => {
		if (navActive === "") {
			setSidebarActiveMenu(activeDashboardMenu);
			setSidebarTitle(dashboardTitle);
			setSidebarParentColor(dashboardParentColor);
		}
	}, [navActive]);

	useEffect(() => {
		const dmsLength = Object.keys(dashboardMenuSections).length > 0;
		if (!dmsLength) return;
		if (sidebarActiveMenu === "root") {
			setSidebarTitle(translation("Dashboard", "Dashboard"));
			setSidebarParentColor("#828282");
		} else {
			var parentMenu = sidebarActiveMenu;
			var tempParent = sidebarActiveMenu;
			const parentItem = findParentItem(parentMenu);
			setSidebarParentItem(parentItem);

			if (parentItem && parentMenu === sidebarActiveMenu) {
				setSidebarTitle(strip_tags(translation(parentItem.title, parentItem.titleEs)));
			}

			while (tempParent !== "root") {
				const parentItem = findParentItem(tempParent);
				if (parentItem && parentItem.parent) {
					tempParent = parentItem.parent;
				} else {
					tempParent = "root";
					setSidebarParentColor(parentItem?.color || "#828282");
				}
			}
		}
	}, [sidebarActiveMenu, dashboardMenuSections]);

	return {
		sidebarActiveMenu,
		sidebarTitle,
		sidebarParentColor,
		sidebarParentItem,
		setSidebarActiveMenu,
	};
};
