import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { isset } from "../../utils/utils";

const ShowNotification = ({
	citLead,
	otp,
	oa,
	supportRoles,
	resetDB,
	notificationRef,
	fav,
	stid,
	updatedTask,
}) => {
	const { store } = useContext(UserContext);
	const { translation } = store;
	const [isHidden, setIsHidden] = useState(false);
	let timeout;

	let $notificationCount = 0;

	//@TODO unverified DATA variables
	const $outcome = "";

	useEffect(() => {
		setIsHidden(false);
		if (!timeout) {
			timeout = setTimeout(() => {
				setIsHidden(true);
			}, 5000);
		}
	}, [citLead, otp, oa, supportRoles, resetDB, notificationRef, fav, stid, updatedTask]);

	useEffect(() => {
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<div className="notification no-print">
			{(() => {
				const jsx = [];
				if (updatedTask?.id && updatedTask.id !== "") {
					jsx.push(
						<div
							key="notification-updatedTask"
							className={
								"update-notification-container notification-" +
								$notificationCount +
								" task container-fluid animated fadeIn " +
								(isHidden ? "fadeOut" : "")
							}
						>
							<h3 className="message">
								{notificationRef !== "" ? (
									<>
										{translation("You have marked  ", "Haz marcado ")}
										<strong>{translation(updatedTask["title"], updatedTask["titleEs"])}</strong>
										{translation(" as complete.", " como terminado(a)")}
									</>
								) : (
									<>
										<strong>{translation("Congratulations! ", "¡Felicidades! ")}</strong>
										{translation("You have completed the task ", "Haz terminado la tarea: ")}
										{translation(updatedTask["title"], updatedTask["titleEs"])}
									</>
								)}
							</h3>
						</div>,
					);
					$notificationCount++;
				}
				if (citLead && citLead !== "") {
					jsx.push(
						<div
							key="notification-citLead"
							className={
								"update-notification-container notification-" +
								$notificationCount +
								" container-fluid animated fadeIn " +
								(isHidden ? "fadeOut" : "")
							}
						>
							<h3 className="message">
								{citLead === "true" && (
									<span
										dangerouslySetInnerHTML={translation(
											"You will now see content relating to the <strong>CIT Lead</strong> role",
											"Ahora veras contenido para la función de <strong>Líder de CIT</strong>",
										)}
									/>
								)}
								{citLead === "false" && (
									<span
										dangerouslySetInnerHTML={translation(
											"You will no longer see content relating to the <strong>CIT Lead</strong> role",
											"Ya no veras contenido para la función de <strong>Líder de CIT</strong>",
										)}
									/>
								)}
							</h3>
						</div>,
					);
					$notificationCount++;
				}
				if (otp && otp !== "") {
					jsx.push(
						<div
							key="notification-otp"
							className={
								"update-notification-container notification-" +
								$notificationCount +
								" container-fluid animated fadeIn " +
								(isHidden ? "fadeOut" : "")
							}
						>
							<h3 className="message">
								{otp === "true" && (
									<span
										dangerouslySetInnerHTML={translation(
											"You will now see content relating to the <strong>Tech Lead</strong> role",
											"Ahora veras contenido para la función de <strong>Líder de Tecnología</strong>",
										)}
									/>
								)}
								{otp === "false" && (
									<span
										dangerouslySetInnerHTML={translation(
											"You will no longer see content relating to the <strong>Tech Lead</strong> role",
											"Ya no veras contenido para la función de <strong>Líder de Tecnología</strong>",
										)}
									/>
								)}
							</h3>
						</div>,
					);
					$notificationCount++;
				}

				if (supportRoles && supportRoles !== "") {
					jsx.push(
						<div
							key="notification-supportRoles"
							className={
								"update-notification-container notification-" +
								$notificationCount +
								" container-fluid animated fadeIn " +
								(isHidden ? "fadeOut" : "")
							}
						>
							<h3 className="message">
								{isset(supportRoles) && (
									<>
										{supportRoles === "mcopco" && (
											<span
												dangerouslySetInnerHTML={translation(
													"You will now see content for <strong>McOpCo</strong> orgs by default",
													"Ahora veras contenido para las organizaciones tipo <strong>McOpCo</strong> por defecto.",
												)}
											/>
										)}
										{supportRoles === "franchisee" && (
											<span
												dangerouslySetInnerHTML={translation(
													"You will now see content for <strong>Franchisee</strong> orgs by default",
													"Ahora veras contenido para las organizaciones tipo <strong>Franquisia</strong> por defecto.",
												)}
											/>
										)}
									</>
								)}
							</h3>
						</div>,
					);
					$notificationCount++;
				}
				if (oa && oa !== "") {
					jsx.push(
						<div
							key="notification-oa"
							className={
								"update-notification-container notification-" +
								$notificationCount +
								" container-fluid animated fadeIn " +
								(isHidden ? "fadeOut" : "")
							}
						>
							<h3 className="message">
								{isset(oa) && (
									<>
										{oa === "true" && (
											<span
												dangerouslySetInnerHTML={translation(
													"You will now see content for <strong>Operations Associates (OA)</strong> by default",
													"Ahora veras contenido para <strong>Operations Associates (OA)</strong> por defecto.",
												)}
											/>
										)}
										{oa === "false" && (
											<span
												dangerouslySetInnerHTML={translation(
													"You will no longer see content for <strong>Operations Associates (OA)</strong> by default",
													"Ya no veras contenido para <strong>Operations Associates (OA)</strong> por defecto.",
												)}
											/>
										)}
									</>
								)}
							</h3>
						</div>,
					);
					$notificationCount++;
				}
				if (resetDB && resetDB !== "") {
					jsx.push(
						<div
							key="notification-resetDB"
							className={
								"update-notification-container notification-" +
								$notificationCount +
								" db-reset container-fluid animated fadeIn " +
								(isHidden ? "fadeOut" : "")
							}
						>
							<h3 className="message">
								{$outcome === "success" && <>"Database has been reset"</>}
							</h3>
						</div>,
					);
					$notificationCount++;
				}
				if (stid && stid !== "") {
					jsx.push(
						<div
							key="notification-stid"
							className={
								"update-notification-container notification-" +
								$notificationCount +
								" db-reset container-fluid animated fadeIn " +
								(isHidden ? "fadeOut" : "")
							}
						>
							<h3 className="message">
								{fav === "add" && <>Restaurant # {stid} has been added to your "My Restaurants"</>}
								{fav === "remove" && (
									<>Restaurant # {stid} has been removed from your "My Restaurants"</>
								)}
							</h3>
						</div>,
					);
					$notificationCount++;
				}

				return jsx;
			})()}
		</div>
	);
};

export default ShowNotification;
