export const PathName = {
	DASHBOARD: "/",
	LOGIN: "/login",
	DEMO: "/mcd-demo",
	FLASHCARDS: "/mcd-flashcards",
	INFO: "/mcd-info",
	LAYGO: "/mcd-laygo",
	NUMBERS: "/mcd-numbers",
	OPS: "/mcd-ops",
	PATHS: "/mcd-paths",
	RESOURCES: "/mcd-resources",
	SEARCH: "/mcd-search",
	WHATS_NEW: "/?p=whats-new",
	MY_SETTINGS_UPDATE: "/?p=my-settings-update",
	MY_SETTINGS: "/?p=my-settings",
	CHECKLISTS: "/mcd-checklists",
	FORMS: "/mcd-forms",
	CHECKLIST: function (checklistAssignmentId: string) {
		return (
			this.CHECKLISTS + (checklistAssignmentId !== undefined ? `/${checklistAssignmentId}` : "")
		);
	},
	CHECKLIST_REPORTS: "/mcd-checklists/reports",
	CHECKLIST_REPORT: function (checklistReportId: string) {
		return (
			this.CHECKLIST_REPORTS + (checklistReportId !== undefined ? `/${checklistReportId}` : "")
		);
	},
};

export const imgPath = "https://files-cdn.nuggetkms.com/nuggetkms/images";
export const mediaPath = "https://files-cdn.nuggetkms.com";
export const docPath = "https://files-cdn.nuggetkms.com/documents";
export const videoPath = "https://files-cdn.nuggetkms.com/video";
export const mediaImgPath = "https://files-cdn.nuggetkms.com/images";
