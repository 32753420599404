import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import AppSwitcher from "./AppSwitcher/AppSwitcher";
import NotificationsPanel from "./NotificationsPanel/NotificationsPanel";
import ProfileLinks from "./ProfileLinks/ProfileLinks";
import { imgPath, PathName } from "../../constants/PathName";
import useQueryParams from "../../hooks/useQueryParams";
import useProduct from "../../hooks/useProduct";
import { gql, useQuery } from "@apollo/client";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { notificationCountersAtom } from "../../contexts/Notifications.recoil";
import { modalRenderAtom } from "../../contexts/modal.recoil";
import FeedbackModal from "../../apps/laygo/FeedbackModal/FeedbackModal";
import { useHistory, useLocation } from "react-router";
import { isSearchQuouted, validateSearch } from "../../utils/utils";
import { activeDashboardMenuAtom } from "../../contexts/DashboardMenus.recoil";
import { GiHamburgerMenu } from "react-icons/gi";
import { _navActiveAtom } from "../../contexts/Navbar.recoil";
import ParseHTMLContent from "../ParseHTMLContent/ParseHTMLContent";

const NAVBAR_QUERY = gql`
	query GetNavbarQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		mainNavApps(
			first: 100
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				mainNavAppsFields {
					fieldGroupName
					icon
					id
					label
					labelEs
					module
					msub
					url
				}
				metaFields {
					sortOrder
				}
			}
		}
		mainNavDashboards {
			nodes {
				mainNavModulesFields {
					fieldGroupName
					icon
					id
					label
					labelEs
					url
				}
			}
		}
	}
`;

const Navbar = () => {
	//state hooks
	const [navActive, setNavActive] = useRecoilState(_navActiveAtom);
	const [mnaCurrentProduct, setMnaCurrentProduct] = useState();
	const [mainDashboard, setMainDashboard] = useState();
	const [searchValue, setSearchValue] = useState("");
	const history = useHistory();
	const [isSearchValid, setSearchValid] = useState(true);

	//context
	const { store, dispatch } = useContext(UserContext);
	const { translation, userLanguage, permissionGroup, userData } = store;
	const { activeCount } = useRecoilValue(notificationCountersAtom);
	const setModalRender = useSetRecoilState(modalRenderAtom);
	const resetActiveDashboardMenu = useResetRecoilState(activeDashboardMenuAtom);

	//custom hooks
	const query = useQueryParams();
	const location = useLocation();
	const product = useProduct();

	const { data } = useQuery(NAVBAR_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	//local variables
	const qry = {
		module: query.get("m"),
		msub: query.get("msub"),
		r: query.get("r"),
		stid: query.get("stid"),
		s: query.get("s"),
		ss1: query.get("ss1"),
		p: query.get("p"),
		t: query.get("t"),
	};

	//functions
	const toggleNav = (nav) => {
		if (navActive === nav) {
			setNavActive("");
		} else {
			setNavActive(nav);
		}
	};

	const swingNotification = (activeC, prod, m, p) => {
		if (activeCount > 0 || qry["p"] !== "whats-new" || qry["module"] !== "eeoft") {
			return " swing ";
		}
		return "";
	};

	const submitSearch = (e) => {
		e.preventDefault();

		if (!isSearchValid) {
			return false;
		}
		const isQuoted = isSearchQuouted(searchValue);
		let cleanTerm = searchValue;
		if (isQuoted) {
			cleanTerm = cleanTerm.replaceAll('"', "").replaceAll("'", "");
		}

		const querySearch = isQuoted ? "'" + cleanTerm + "'" : cleanTerm;

		history.push(
			PathName.DASHBOARD +
				"mcd-search/" +
				"?q=" +
				querySearch +
				"&m=" +
				qry.module +
				"&msub=" +
				qry.msub +
				"&l=" +
				userLanguage,
		);
		setSearchValue("");
		setNavActive("");
	};

	//React hooks
	useEffect(() => {
		if (data && data.mainNavDashboards) {
			setMainDashboard(data.mainNavDashboards.nodes[0].mainNavModulesFields || []);
		}
	}, [data]);

	const mainNavApps = (data && data.mainNavApps.nodes) || [];

	useEffect(() => {
		if (mainNavApps && mainNavApps.length > 0) {
			setMnaCurrentProduct(mainNavApps.filter((app) => app.mainNavAppsFields.id === product)[0]);
		}
	}, [mainNavApps, product]);

	useEffect(() => {
		// validate content
		//split search term
		if (searchValue !== null && searchValue !== undefined) {
			let cleanTerm = searchValue;
			cleanTerm = cleanTerm.replaceAll('"', "").replaceAll("'", "");
			const termArray = cleanTerm.split(/\s+/);
			const isInvalid = termArray.some((term) => {
				return !validateSearch(term, userLanguage);
			});

			if (!isInvalid) {
				setSearchValid(true);
			} else {
				setSearchValid(false);
			}
		}
	}, [searchValue]);

	return (
		<div>
			<NotificationsPanel
				isActive={navActive === "navbar-n"}
				closeNavOpt={() => setNavActive("")}
			/>

			<div className={"search-dd " + (navActive === "navbar-s" ? "" : "hidden ")}>
				<form id="#search_form" className="search-form" onSubmit={submitSearch}>
					<input
						className={isSearchValid ? "" : "invalid"}
						type="text"
						name="q"
						id="search_input"
						value={searchValue}
						onInput={(e) => setSearchValue(e.currentTarget.value)}
						placeholder={translation("Search", "Buscar")}
					/>
				</form>
				<div className="anc search-close" onClick={() => toggleNav("")}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
						<path d="M23.39 17.5l11.17 11.17a1.51 1.51 0 0 1 0 2.14l-3.76 3.75a1.51 1.51 0 0 1-2.14 0L17.5 23.39 6.33 34.56a1.5 1.5 0 0 1-2.13 0L.44 30.81a1.51 1.51 0 0 1 0-2.14L11.61 17.5.44 6.34a1.51 1.51 0 0 1 0-2.14L4.2.44a1.5 1.5 0 0 1 2.13 0L17.5 11.61 28.66.44a1.51 1.51 0 0 1 2.14 0l3.76 3.76a1.51 1.51 0 0 1 0 2.14z" />
					</svg>
				</div>
			</div>

			<nav className="navbar navbar-fixed-top ">
				<div className="container-fluid">
					<div id="appSwitcher" className="app-switcher" onClick={() => toggleNav("appSwitcher")}>
						<div className="anc slide-menu-control" data-target="mainMenu" data-action="toggle">
							<svg
								className={
									"app-switcher-close-icon " + (navActive === "appSwitcher" ? "" : "hidden ")
								}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 35 35"
							>
								<path d="M23.39 17.5l11.17 11.17a1.51 1.51 0 0 1 0 2.14l-3.76 3.75a1.51 1.51 0 0 1-2.14 0L17.5 23.39 6.33 34.56a1.5 1.5 0 0 1-2.13 0L.44 30.81a1.51 1.51 0 0 1 0-2.14L11.61 17.5.44 6.34a1.51 1.51 0 0 1 0-2.14L4.2.44a1.5 1.5 0 0 1 2.13 0L17.5 11.61 28.66.44a1.51 1.51 0 0 1 2.14 0l3.76 3.76a1.51 1.51 0 0 1 0 2.14z" />
							</svg>
							<GiHamburgerMenu
								className={
									"app-switcher-app-icon " + (navActive === "appSwitcher" ? "hidden " : "")
								}
							/>
						</div>
					</div>
					<div className="navbar-header">
						<span className="navbar-brand">
							<Link to={PathName.DASHBOARD} onClick={() => resetActiveDashboardMenu()}>
								<img
									className="brand-logo"
									src={imgPath + "/nuggetkms-logo.svg"}
									alt="nugget kms logo"
								/>
								<span className="hidden-xs">NuggetKMS</span>
							</Link>
						</span>
						<ul className="navbar-user">
							{
								//if ($pageTitle == 'Feedback' || $pageTitle == 'feedback') {
								//don't show on feedback page
								product === "mcd-laygo" && (
									<>
										<li className="nav navbar-feedback full">
											<div
												className="anc "
												data-toggle="modal"
												data-target="#feedback"
												onClick={() =>
													setModalRender(
														<FeedbackModal store={store} query={query} location={location} />,
													)
												}
											>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
													<path
														className="cls-1"
														d="M34.75,13.56V31.24l-6-2.82H12.47a3,3,0,0,1-3-3V21.86H22.15a3.62,3.62,0,0,0,3.62-3.62V10.56h6A3,3,0,0,1,34.75,13.56Z"
													/>
													<path
														className="cls-1"
														d="M24.78,6.39V18.25a2.62,2.62,0,0,1-2.62,2.62H5.87L.25,23.48V6.39A2.63,2.63,0,0,1,2.88,3.76H22.16A2.63,2.63,0,0,1,24.78,6.39Z"
													/>
													<path d="M34.75,13.56V31.24l-6-2.82H12.47a3,3,0,0,1-3-3V21.86H22.15a3.62,3.62,0,0,0,3.62-3.62V10.56h6A3,3,0,0,1,34.75,13.56Z" />
													<path d="M24.78,6.39V18.25a2.62,2.62,0,0,1-2.62,2.62H5.87L.25,23.48V6.39A2.63,2.63,0,0,1,2.88,3.76H22.16A2.63,2.63,0,0,1,24.78,6.39Z" />
												</svg>
												{translation("Can't Find Something?", "¿No encuentras algo?")}
											</div>
										</li>
										<li className="nav navbar-feedback condensed">
											<div
												className="anc "
												data-toggle="modal"
												data-target="#feedback"
												onClick={() =>
													setModalRender(
														<FeedbackModal store={store} query={query} location={location} />,
													)
												}
											>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
													<path
														className="cls-1"
														d="M34.75,13.56V31.24l-6-2.82H12.47a3,3,0,0,1-3-3V21.86H22.15a3.62,3.62,0,0,0,3.62-3.62V10.56h6A3,3,0,0,1,34.75,13.56Z"
													/>
													<path
														className="cls-1"
														d="M24.78,6.39V18.25a2.62,2.62,0,0,1-2.62,2.62H5.87L.25,23.48V6.39A2.63,2.63,0,0,1,2.88,3.76H22.16A2.63,2.63,0,0,1,24.78,6.39Z"
													/>
													<path d="M34.75,13.56V31.24l-6-2.82H12.47a3,3,0,0,1-3-3V21.86H22.15a3.62,3.62,0,0,0,3.62-3.62V10.56h6A3,3,0,0,1,34.75,13.56Z" />
													<path d="M24.78,6.39V18.25a2.62,2.62,0,0,1-2.62,2.62H5.87L.25,23.48V6.39A2.63,2.63,0,0,1,2.88,3.76H22.16A2.63,2.63,0,0,1,24.78,6.39Z" />
												</svg>
											</div>
										</li>
									</>
								)
								//}
								//if (getProduct() == 'mcd-laygo' || getProduct() == 'mcd-demo') {
							}

							<li className="navbar-l">
								<div
									className={
										"anc " +
										(userLanguage === "en" || userLanguage === "" || userLanguage == null
											? "active-l"
											: "")
									}
									onClick={() => {
										dispatch({ type: "setUserLanguage", data: { userLanguage: "en" } });
									}}
								>
									<span className="hidden-xs">English</span>
									<span className="hidden-sm  hidden-md hidden-lg">EN</span>
								</div>
							</li>
							<li>|</li>
							<li className="navbar-l">
								<div
									className={"anc " + (userLanguage === "es" ? "active-l" : "")}
									onClick={() => {
										dispatch({ type: "setUserLanguage", data: { userLanguage: "es" } });
									}}
								>
									<span className="hidden-xs">Espa&ntilde;ol</span>
									<span className="hidden-sm  hidden-md hidden-lg">ES</span>
								</div>
							</li>

							<li className="navbar-s" onClick={() => toggleNav("navbar-s")}>
								<div className={"anc " + (navActive === "navbar-s" ? "active " : "")}>
									<img
										src={imgPath + "/icon-search-black.svg"}
										onError={(e) => {
											e.target.src = imgPath + "/icon-search-black.png"; e.target.onerror=null;
										}}
										className={"on " + (navActive === "navbar-s" ? "" : "hidden ")}
										alt="search icon"
									/>
									<img
										src={imgPath + "/icon-search-white.svg"}
										onError={(e) => {
											e.target.src = imgPath + "/icon-search-white.png"; e.target.onerror=null;
										}}
										className={"off " + (navActive === "navbar-s" ? "hidden " : "")}
										alt="search icon selected"
									/>
								</div>
							</li>

							<li className="navbar-n" onClick={() => toggleNav("navbar-n")}>
								<div
									className={
										"anc " +
										(navActive === "navbar-n" ? "active " : "") +
										(activeCount > 0 &&
										product === "mcd-transitionkit" &&
										!qry["module"] &&
										!qry["p"]
											? "active"
											: "")
									}
								>
									<div className="notifications-number">{activeCount <= 0 ? 0 : activeCount}</div>
									{navActive !== "navbar-n" ? (
										<img
											src={imgPath + "/icon-bell-yellow.svg"}
											alt="active notifications bell"
											className={"off animated " + swingNotification()}
											style={{ animationIterationCount: 3 }}
										/>
									) : (
										<img
											src={imgPath + "/icon-bell-black.svg"}
											onError={(e) => {
												/*e.target.src = imgPath + "/icon-bell-black.png"; e.target.onerror=null;*/
											}}
											alt="inactive notifications bell"
											className={"on animated " + swingNotification()}
											style={{ animationIterationCount: 3 }}
										/>
									)}
								</div>
							</li>

							<li className="navbar-p" onClick={() => toggleNav("navbar-p")}>
								<div className={"anc " + (navActive === "navbar-p" ? "active " : "")}>
									<img
										src={imgPath + "/icon-profile.svg"}
										onError={(e) => {
											/*e.target.src = imgPath + "/icon-profile.png"; e.target.onerror=null;*/
										}}
										className={"off " + (navActive === "navbar-p" ? "hidden " : "")}
										alt="user profile icon"
									/>

									<img
										src={imgPath + "/icon-profile-black.svg"}
										onError={(e) => {
											/*e.target.src = imgPath + "/icon-profile-black.png"; e.target.onerror=null;*/
										}}
										className={"on " + (navActive === "navbar-p" ? "" : "hidden ")}
										alt="user profile icon selected"
									/>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>
			{
				/** SHOW PRODUCT INFO SUBMENU **/
				product === "mcd-info" && qry["module"] === "eeotf" && (
					<nav className="navbar navbar-secondary">
						<div className="container-fluid">
							<div className="row">
								<div className="col-xs-12">
									<ul>
										<Link to={"?m=eeotf&msub=zip&l=" + userLanguage} title="Shift Swap App">
											<li className={qry.msub === "zip" ? "active" : ""}>
												<img src={imgPath + "/laygo-icons/icon-laygo-zip.svg"} alt="" />
												<span className="hidden-sm hidden-xs">Shift Swap App</span>
											</li>
										</Link>
									</ul>
								</div>
							</div>
						</div>
					</nav>
				)
			}

			<AppSwitcher isActive={navActive === "appSwitcher"} closeNavOpt={() => setNavActive("")} />

			<ProfileLinks isActive={navActive === "navbar-p"} closeNavOpt={() => setNavActive("")} />

			<div
				className={
					"fade-away " + (navActive === "appSwitcher" || navActive === "navbar-p" ? "" : "hidden ")
				}
				onClick={() => setNavActive("")}
			></div>
		</div>
	);
};

export default Navbar;
