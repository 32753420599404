import React from "react";
import { useRecoilValue } from "recoil";
import {
	activeDashboardMenuAtom,
	dashboardMenuSectionsAtom,
	dashboardTitleAtom,
} from "../../../contexts/DashboardMenus.recoil";
import DashBoardMenu from "./DashboardMenu";

const DashBoardMenus: React.FC = () => {
	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const activeDashboardMenu = useRecoilValue(activeDashboardMenuAtom);
	const dashboardTitle = useRecoilValue(dashboardTitleAtom);
	return (
		<div className={"dashboard-menus " + activeDashboardMenu}>
			<div className="dash-title">{dashboardTitle}</div>
			{dashboardMenuSections && dashboardMenuSections[activeDashboardMenu] && (
				<DashBoardMenu
					menuItems={dashboardMenuSections[activeDashboardMenu]}
					menuId={activeDashboardMenu}
				/>
			)}
		</div>
	);
};

export default DashBoardMenus;
