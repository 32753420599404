import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { docPath, imgPath, mediaPath, PathName } from "../../../constants/PathName";
import { UserContext } from "../../../contexts/UserContext";
import useProduct from "../../../hooks/useProduct";
import useQueryParams from "../../../hooks/useQueryParams";
import { calculate_top_margin, ucfirst } from "../../../utils/utils";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";
import ParseUrlTarget from "../../../components/ParseUrlTarget/ParseUrlTarget";

const ResourceDetails: React.FC<any> = ({ resourceID, resourceLibrary }) => {
	const query = useQueryParams();
	const { store }: any = useContext(UserContext);
	const { translation } = store;
	const product = useProduct();

	const module = query.get("m");
	const subModule = query.get("msub");

	const preResource = resourceLibrary.find((el: any) => el.resourceLibraryFields.id === resourceID);
	if (!preResource) {
		return null;
	}
	const resource = preResource.resourceLibraryFields;

	return (
		<div className="container-fluid main" style={{ ...calculate_top_margin(product) }}>
			<div className="row">
				<div className="col-xs-12 ">
					<p>
						<Link to={PathName.RESOURCES + "?m=" + module + "&msub=" + subModule}>
							<svg className="icon-back" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
								<path
									d="M18.2.37l3.15 3.14a1.27 1.27 0 0 1 0 1.77L9.13 17.5l12.22 12.22a1.27 1.27 0 0 1 0 1.77l-3.15 3.14a1.25 1.25 0 0 1-1.77 0L.5 18.7a1.68 1.68 0 0 1 0-2.4L16.43.37a1.25 1.25 0 0 1 1.77 0z"
									fill="#f2f2f2"
								/>
								<path d="M18.2.37l3.15 3.14a1.27 1.27 0 0 1 0 1.77L9.13 17.5l12.22 12.22a1.27 1.27 0 0 1 0 1.77l-3.15 3.14a1.25 1.25 0 0 1-1.77 0L.5 18.7a1.68 1.68 0 0 1 0-2.4L16.43.37a1.25 1.25 0 0 1 1.77 0z" />
							</svg>
							{translation("Back", "Atrás")}
						</Link>
					</p>
				</div>
				{(() => {
					if (resource["resourcetype"] === "video" || resource["resourcetype"] === "webcast") {
						return (
							<>
								<div className="col-xs-12 col-sm-8">
									<video
										controls
										preload="auto"
										poster={
											mediaPath + "/" + resource["resourcetype"] + "/" + resource["thumbnail"]
										}
									>
										<source
											src={
												mediaPath +
												"/" +
												resource["resourcetype"] +
												"/" +
												resource["filename"] +
												".webm"
											}
											type="video/webm"
										/>
										<source
											src={
												mediaPath +
												"/" +
												resource["resourcetype"] +
												"/" +
												resource["filename"] +
												".mpeg"
											}
											type="video/mpeg"
										/>
										<source
											src={
												mediaPath +
												"/" +
												resource["resourcetype"] +
												"/" +
												resource["filename"] +
												".ogg"
											}
											type="video/ogg"
										/>
										<source
											src={
												mediaPath +
												"/" +
												resource["resourcetype"] +
												"/" +
												resource["filename"] +
												".mp4"
											}
											type="video/mp4"
										/>
									</video>
								</div>
								<div className="col-xs-12 col-sm-4">
									<h4>
										<ParseHTMLContent htmlString={resource["title"] || ""} />
									</h4>
									<p className="description">
										<ParseHTMLContent htmlString={resource["content"] || ""} />
									</p>
									<div className="row">
										<div className="col-xs-12 date">
											{translation("Date:", "Fecha:")}
											{resource["date"]}
										</div>
										<div className="col-xs-12 language">
											{translation("Language:", "Idioma:")}
											{ucfirst(resource["language"])}
										</div>
										<div className="col-xs-12 duration">
											{translation("Duration:", "Duración:")}
											{resource["duration"]}
										</div>
									</div>
								</div>
								<div className="col-xs-12">
									<p>
										<ParseHTMLContent
											htmlString={translation(
												'<strong>Note:</strong> For a high-resolution version of any video, please contact <a href="mailto:Bridget.Weber@us.mcd.com">Bridget Weber</a>',
												'<strong>Nota:</strong> Para una versión de alta resolución de cualquier video, por favor contacta a <a href="mailto:Bridget.Weber@us.mcd.com">Bridget Weber</a>',
											)}
										/>
									</p>
								</div>
							</>
						);
					} else if (resource["resourcetype"] === "document") {
						return (
							<>
								<div className="col-xs-12">
									<div className="inline-link">
										<ParseUrlTarget
											url={docPath + "/" + resource["filename"] + resource["fileextension"]}
											target="_blank"
										>
											<img
												src={imgPath + "/" + resource["icon"] + ".svg"}
												alt=""
												// onerror="this.src='<?php echo $imagepath.resourceLibrary[$r]['icon'].'.png'; ?>'; this.onerror=null;"
											/>
											{resource["title"] + resource["fileextension"]}
										</ParseUrlTarget>
									</div>
								</div>
								<div className="col-xs-12">
									<p className="description">
										<ParseHTMLContent htmlString={resource["content"] || ""} />
									</p>
									<p className="date">
										{translation("Date:", "Fecha:")}
										{resource["date"]}
									</p>
								</div>
							</>
						);
					}
				})()}
			</div>
		</div>
	);
};

export default ResourceDetails;
