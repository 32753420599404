import { format } from "date-fns-tz";

export function formatDate(date: Date): string {
	const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric" };
	return date.toLocaleDateString("en-US", options);
}
export function formatDate_MM_DD_YYYY(date: Date): string {
	const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
	return date.toLocaleDateString("en-US", options);
}
export function formatDateFromYYYYMMDD(inDate: string) {
	if (inDate.length !== 8) return { date: null, dateString: "" };

	const date = new Date(
		Number(inDate.slice(0, 4)),
		Number(inDate.slice(4, 6)) - 1,
		Number(inDate.slice(6, 8)),
	);
	const dateString = formatDate_MM_DD_YYYY(date);

	return { date, dateString };
}
export function formatDate_YYYY_MM_DD(date: Date): string {
	const year = date.getFullYear();
	const realMonth = date.getMonth() + 1;
	const month = realMonth.toString().length > 1 ? realMonth : `0${realMonth}`;
	const day = date.getDate().toString().length > 1 ? date.getDate() : `0${date.getDate()}`;

	return `${year}-${month}-${day}`;
}

// format date to format('Y-m-d H:i:s')
export function formatDateTimeYYYY_MM_DD_HH_MM_SS(date: Date): string {
	const formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
	return formattedDate;
}

export function formatDueDate(
	goLiveDate: Date,
	date: Date,
	daysToAdd: number,
): { message: string; color: string } {
	const targetDate = new Date(date);
	targetDate.setDate(targetDate.getDate() + daysToAdd);

	// Difference between the target and go-live date
	const diffTime = goLiveDate.getTime() - targetDate.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	let message: string;
	let color: string;

	if (diffDays >= 0 && diffDays <= 5) {
		// Due in less than 5 days
		message = `Due in ${diffDays} days`;
		color = "#f2c94c";
	} else if (diffDays < 0) {
		// Overdue
		message = `${Math.abs(diffDays)} days overdue`;
		color = "#eb5757";
	} else {
		// Neither
		const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
		message = targetDate.toLocaleDateString("en-US", options);
		color = "#27ae60";
	}

	return { message, color };
}

export const parseTimezone = (timezone: string) => {
	// show PT, CT, ET, MT based on the time zone.
	if (timezone === "") {
		return "";
	}
	if (timezone === "-08:00") {
		return "(PT)";
	} else if (timezone === "-07:00") {
		return "(MT)";
	} else if (timezone === "-06:00") {
		return "(CT)";
	} else if (timezone === "-05:00") {
		return "(ET)";
	}
	return `(${timezone})`;
};
