import React, { PropsWithChildren, useContext } from "react";
import { UserContextType, UserContext } from "../../../contexts/UserContext";
import { DashboardMenu_Dashboardmenufields } from "../../../generated/graphql";
import { BsChevronRight } from "react-icons/bs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
	activeDashboardMenuAtom,
	dashboardMenuSectionsAtom,
	dashboardParentColorAtom,
} from "../../../contexts/DashboardMenus.recoil";
import ParseHTMLContent from "../../../components/ParseHTMLContent/ParseHTMLContent";
import { isMobile, isValidUrl } from "../../../utils/utils";
import { topPdfViewerUrlAtom } from "../../../contexts/topPdfViewer.recoil";
import { Link } from "react-router-dom";
import OADTLink from "./oadt/OADTLink";
interface Props {
	menuItems: DashboardMenu_Dashboardmenufields[];
	menuId: string;
}

interface LinkProps {
	menuItem: DashboardMenu_Dashboardmenufields;
}

const HandleClick: React.FC<PropsWithChildren<LinkProps>> = ({ children, menuItem }) => {
	const setActiveDashboardMenu = useSetRecoilState(activeDashboardMenuAtom);
	const dashboardMenuSections = useRecoilValue(dashboardMenuSectionsAtom);
	const setPDFUrl = useSetRecoilState(topPdfViewerUrlAtom);

	const menuItemId = menuItem.id;
	const menuItemUrl = menuItem.url;

	//first if it has a menu will go to child menu, else check if has valid url/path
	if (menuItemId && dashboardMenuSections[menuItemId]) {
		return (
			<div
				onClick={() => {
					setActiveDashboardMenu(menuItemId);
				}}
			>
				{children}
			</div>
		);
	} else if (menuItemUrl) {
		// if its a FULL url not just the path
		if (isValidUrl(menuItemUrl)) {
			const url = new URL(menuItemUrl);
			if (menuItemUrl.includes("https://files-cdn") && menuItemUrl.includes(".pdf") && isMobile()) {
				return (
					<div className="anc" onClick={() => setPDFUrl(menuItemUrl)}>
						{children}
					</div>
				);
			}

			if (
				(url.hostname === window.location.hostname ||
					url.hostname.includes("localhost") ||
					url.hostname.includes("nuggetkms.com") ||
					url.hostname.includes("mcdtkit.com")) &&
				!url.hostname.includes("files-cdn")
			) {
				return <Link to={url.pathname + url.search + url.hash}>{children}</Link>;
			} else {
				// for external links
				return (
					<a href={menuItemUrl} target={"_blank"} rel="noreferrer noopener">
						{children}
					</a>
				);
			}
		} else {
			if (menuItemUrl.includes("/rdt")) {
				return <OADTLink url={menuItemUrl}>{children}</OADTLink>;
			}
			return <Link to={menuItemUrl}>{children}</Link>;
		}
	} else {
		return <>{children}</>;
	}
};

const DashBoardMenu: React.FC<Props> = ({ menuItems, menuId }) => {
	const { store } = useContext<UserContextType>(UserContext);
	const { translation } = store;
	const dashboardParentColor = useRecoilValue(dashboardParentColorAtom);

	return (
		<div className="dashboard-menu">
			{menuItems.map((menuItem, key) => {
				return (
					<React.Fragment key={menuId + "-" + key}>
						<HandleClick menuItem={menuItem}>
							<div
								key={menuId + "-" + key}
								className="menu-item"
								style={{ backgroundColor: menuItem.color || dashboardParentColor || "" }}
							>
								<div className="icon-slot">
									<img src={menuItem.icon || ""} alt="" />
								</div>
								<div className="text-slot">
									<div className="title">
										<ParseHTMLContent htmlString={translation(menuItem.title, menuItem.titleEs)} />
									</div>
									{(menuItem.description || menuItem.descriptionEs) && (
										<div className="description">
											<ParseHTMLContent
												htmlString={translation(menuItem.description, menuItem.descriptionEs)}
											/>
										</div>
									)}
								</div>
								<div className="arrow-slot">
									<BsChevronRight className="icon" />
								</div>
							</div>
						</HandleClick>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default DashBoardMenu;
