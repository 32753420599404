import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useContext, useEffect } from "react";
// import { useHistory } from "react-router";
import Loading from "../../../components/Loading/Loading";
// import { PathName } from "../../../constants/PathName";
import { UserContext } from "../../../contexts/UserContext";
import useComponentVisible from "../../../hooks/useComponentVisible";
import useQueryParams from "../../../hooks/useQueryParams";
import useStates from "../../../hooks/useStates";
import { in_array } from "../../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UPDATE_USER_SETTINGS_MUTATION = gql`
	mutation updateUserAdditionalRoles(
		$personID: String!
		$cit_lead: Int!
		$oa: Int!
		$otp: Int!
		$support: String!
		$field_office: String
		$systems: String
	) {
		updateTKITUserSettings(
			input: {
				tKITUserSettingsFieldsInput: {
					personID: $personID
					cit_lead: $cit_lead
					oa: $oa
					otp: $otp
					support: $support
					field_office: $field_office
					systems: $systems
				}
			}
		) {
			message
			didUpdate
		}
	}
`;

// const UPDATE_USER_ONBOARDING = gql`
// 	mutation UpdateUserOnboarding($personID: String!, $mcd_transitionkit: Int!) {
// 		updateTKITUserOnboarding(
// 			input: {
// 				tKITUserOnboardingFieldsInput: {
// 					personID: $personID
// 					mcd_transitionkit: $mcd_transitionkit
// 				}
// 			}
// 		) {
// 			message
// 			didUpdate
// 		}
// 	}
// `;

const MySettings = () => {
	// state hooks
	const [{ citLead, otp, support, oa, fieldOffice, systems }, setFormState] = useStates({
		citLead: "0",
		otp: "0",
		support: "",
		oa: "0",
		fieldOffice: "",
		systems: [],
	});
	// recoils
	const { store, dispatch } = useContext(UserContext);
	const { translation, permissionGroup, userSettings, personID } = store;

	// custom hooks
	const query = useQueryParams();
	const [updateUserSettings, updateUserSettingsRes] = useMutation(UPDATE_USER_SETTINGS_MUTATION);
	// const [updateUserOnboarding, updateUserOnboardingRes] = useMutation(UPDATE_USER_ONBOARDING);
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	// const history = useHistory();

	// local variables
	const noAdditionalRolesSettings = [
		"None",
		"Operator",
		"Organization Admin",
		"Level 5",
		"Level 6",
	];

	// functions
	// const mainOnboardingReset = async () => {
	// 	await updateUserOnboarding({
	// 		variables: {
	// 			personID,
	// 			mcd_transitionkit: 0,
	// 		},
	// 	});

	// 	//redirect to dashboard
	// 	history.push(PathName.DASHBOARD);
	// };

	const submitOAFieldOfficeSystems = async (e) => {
		e.preventDefault();

		//sanitize variables
		const cleanSystems = systems.length > 0 ? systems.join(";") : "";
		let newFieldOffice = fieldOffice;
		if (fieldOffice === "-1") {
			newFieldOffice = "";
		}

		try {
			const { data } = await updateUserSettings({
				variables: {
					personID,
					cit_lead: userSettings.citLead,
					otp: userSettings.otp,
					//from state
					oa: oa === "1" ? 1 : 0,
					support,
					field_office: newFieldOffice,
					systems: cleanSystems,
				},
			});

			if (data && data.updateTKITUserSettings) {
				if (data.updateTKITUserSettings.didUpdate) {
					//Update Context Values
					dispatch({
						type: "setUserSettings",
						data: {
							...userSettings,
							oa: oa === "1" ? 1 : 0,
							support,
							fieldOffice: newFieldOffice,
							systems: cleanSystems,
						},
					});

					toast.success(data.updateTKITUserSettings.message);
				} else {
					toast.error(data.updateTKITUserSettings.message);
				}
			} else {
				toast.error("Failed to update settings. Please try again.");
			}
		} catch (error) {
			console.error("Error updating settings:", error);
			toast.error("An error occurred while updating settings. Please try again.");
		}
	};

	const submitCitLeadOTP = async (e) => {
		e.preventDefault();

		await updateUserSettings({
			variables: {
				personID,
				cit_lead: citLead === "1" ? 1 : 0,
				otp: otp === "1" ? 1 : 0,
				oa: userSettings.oa,
				support: userSettings.support,
			},
		});

		//Update Context Values
		dispatch({
			type: "setUserSettings",
			data: {
				...userSettings,
				citLead: citLead === "1" ? 1 : 0,
				otp: otp === "1" ? 1 : 0,
			},
		});

		//update custom role which only exists in the context
		const newCustomRoles = [];
		if (support === "1") {
			newCustomRoles.push("OA");
		}
		if (citLead === "1") {
			newCustomRoles.push("CIT Lead");
		}
		if (otp === "1") {
			newCustomRoles.push("OTP");
		}
		dispatch({
			type: "setPermissionGroup",
			data: {
				...permissionGroup,
				customRole: newCustomRoles,
			},
		});
	};

	const handleRadioChange = (field, value) => {
		setFormState({ [field]: value });
	};

	const handleCheckboxChange = (value) => {
		setFormState((prevState) => {
			const updatedSystems = prevState.systems.includes(value)
				? prevState.systems.filter((system) => system !== value)
				: [...prevState.systems, value];
			return { ...prevState, systems: updatedSystems };
		});
	};

	const systemOptions = [
		{ value: "1", label: "Inventory" },
		{ value: "2", label: "Staffing, Scheduling & Positioning (SSP)" },
		{ value: "3", label: "Service, Production & Assembly (SPA)" },
		{ value: "4", label: "Food Safety" },
		{ value: "5", label: "People Practices" },
		{ value: "6", label: "Safety & Security" },
		{ value: "7", label: "Shift Management" },
		{ value: "8", label: "Learning & Development" },
		{ value: "9", label: "Planned & Daily Maintenance" },
	];

	// React hooks

	useEffect(() => {
		setFormState({
			citLead: String(userSettings.citLead),
			otp: String(userSettings.otp),
			support: userSettings.support,
			oa: String(userSettings.oa),
			fieldOffice: userSettings.fieldOffice,
			systems: userSettings.systems?.split(";") || [],
		});
	}, [
		setFormState,
		userSettings.citLead,
		userSettings.fieldOffice,
		userSettings.oa,
		userSettings.otp,
		userSettings.support,
		userSettings.systems,
	]);

	// jsx variables

	const getPage = () => {
		if (
			permissionGroup.companyType !== "mcopco" &&
			permissionGroup.accessLevel !== "None" &&
			permissionGroup.companyType === "franchisee" &&
			!in_array(permissionGroup.accessLevel, noAdditionalRolesSettings)
		) {
			return (
				<>
					{query.get("errors") === "true" ? "customRole set to ." + permissionGroup.customRole : ""}
					<form id="userSettings" name="userSettings" onSubmit={(e) => submitCitLeadOTP(e)}>
						{/* <input type="hidden" name="p" value="my-settings-update" /> */}
						<div className="row">
							<div className="col-xs-12">
								<p>
									{translation(
										"You can update your settings below:",
										"Puedes actualizar tus ajustes a continuación:",
									)}
								</p>
							</div>
						</div>
						<div className="row settings-question">
							<div className="col-xs-12 col-sm-8 text-left ">
								{translation(
									"Are you the CIT Lead for your organization?",
									"¿Eres el Líder de CIT de tu organización?",
								)}
							</div>
							<div
								className="col-xs-12 col-sm-4 text-center"
								onChange={(e) => {
									setFormState({ citLead: e.target.value });
								}}
							>
								<label className="container">
									{translation("No", "No")}
									<input
										type="radio"
										checked={citLead === "0"}
										onChange={() => handleRadioChange("citLead", "0")}
										name="cit-lead"
										value="0"
									/>
									<span className="checkmark"></span>
								</label>
								<label className="container ">
									{translation("Yes", "Si")}
									<input
										type="radio"
										checked={citLead === "1"}
										onChange={() => handleRadioChange("citLead", "1")}
										name="cit-lead"
										value="1"
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
						<div className="row section-grey settings-question">
							<div className="col-xs-12 col-sm-8 text-left">
								{translation(
									"Are you the Tech Lead for your organization?",
									"¿Eres miembro del personal de tecnología de tu organización?",
								)}
							</div>
							<div
								className="col-xs-12 col-sm-4 text-center"
								onChange={(e) => {
									setFormState({ otp: e.target.value });
								}}
							>
								<label className="container">
									{translation("No", "No")}
									<input
										type="radio"
										checked={otp === "0"}
										onChange={() => handleRadioChange("otp", "0")}
										name="otp"
										value="0"
									/>
									<span className="checkmark"></span>
								</label>
								<label className="container">
									{translation("Yes", "Si")}
									<input
										type="radio"
										checked={otp === "1"}
										onChange={() => handleRadioChange("otp", "1")}
										name="otp"
										value="1"
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-12 text-right">
								<input
									className={
										"btn btn-primary " + (updateUserSettingsRes.loading ? " disabled " : "")
									}
									type="submit"
									value={translation("Apply Settings", "Actualizar Ajustes")}
								/>
							</div>
						</div>
					</form>
				</>
			);
		} else if (
			permissionGroup.accessLevel === "None" &&
			permissionGroup.accessLevel !== "Level 5" &&
			permissionGroup.accessLevel !== "Level 6"
		) {
			return (
				<>
					{query.get("errors") === "true" ? "customRole set to ." + permissionGroup.customRole : ""}
					<form
						id="userSettings"
						name="userSettings"
						onSubmit={(e) => submitOAFieldOfficeSystems(e)}
					>
						{/* <input type="hidden" name="p" value="my-settings-update" /> */}
						<div className="row settings-question">
							<div className="col-xs-12 col-sm-8 text-left">
								{translation(
									"Which type of organization do you support?",
									"¿Qué tipo de organización apoyas?",
								)}
							</div>
							<div
								className="col-xs-12 col-sm-4 text-center"
								onChange={(e) => {
									setFormState({ support: e.target.value });
								}}
							>
								<label className="container">
									{translation("McOpCo", "McOpCo")}
									<input
										type="radio"
										checked={support === "mcopco"}
										onChange={() => handleRadioChange("support", "mcopco")}
										name="support-roles"
										value="mcopco"
									/>
									<span className="checkmark"></span>
								</label>
								<label className="container ">
									{translation("Franchisee", "Franquicia")}
									<input
										type="radio"
										checked={support === "franchisee"}
										onChange={() => handleRadioChange("support", "franchisee")}
										name="support-roles"
										value="franchisee"
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
						<div className="row section-grey settings-question">
							<div className="col-xs-12 col-sm-8 text-left">
								{translation(
									"Are you an Operations Associate (OA)?",
									'¿Eres un "Operations Associate (OA)"?',
								)}
							</div>
							<div
								className="col-xs-12 col-sm-4 text-right"
								onChange={(e) => {
									setFormState({ oa: e.target.value });
								}}
							>
								<label className="container">
									{translation("Yes", "Si")}
									<input
										type="radio"
										name="oa"
										value="1"
										checked={oa === "1"}
										onChange={() => handleRadioChange("oa", "1")}
									/>
									<span className="checkmark"></span>
								</label>
								<label className="container ">
									{translation("No", "No")}
									<input
										type="radio"
										name="oa"
										value="0"
										checked={oa === "0"}
										onChange={() => handleRadioChange("oa", "0")}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
							<div id="oadtSettings" className={`col-xs-12 padded ${oa === "0" ? "hidden" : ""}`}>
								<div className="row settings-question">
									<div className="col-xs-12 col-sm-8 text-left">
										{translation(
											"Select your field office",
											"Selecciona tu oficina de campo (field office)",
										)}
									</div>
									<div className="col-xs-12 col-sm-4">
										<select
											name="field-office"
											onChange={(e) => setFormState({ fieldOffice: e.target.value })}
											value={fieldOffice}
										>
											<option value="-1">Select An Option</option>
											<option value="ATLANTA FIELD OFFICE">Atlanta</option>
											<option value="BETHESDA FIELD OFFICE">Bethesda</option>
											<option value="CHICAGO FIELD OFFICE">Chicago</option>
											<option value="COLUMBUS FIELD OFFICE">Columbus</option>
											<option value="STAMFORD FIELD OFFICE">Stamford</option>
											<option value="DALLAS FIELD OFFICE">Dallas</option>
											<option value="DENVER FIELD OFFICE">Denver</option>
											<option value="LONG BEACH FIELD OFFICE">Long Beach</option>
											<option value="NASHVILLE FIELD OFFICE">Nashville</option>
											<option value="WALNUT CREEK FIELD OFFICE">Walnut Creek</option>
										</select>
									</div>
								</div>
								<div className="row settings-question">
									<div className="col-xs-12 col-sm-8 text-left">
										{translation("What system(s) do you support?", "¿Qué sistema(s) apoyas?")}
									</div>
									<div className="col-xs-12 col-sm-4">
										<div className="systems" ref={ref}>
											<div
												className="systemsTop"
												onClick={() => setIsComponentVisible(!isComponentVisible)}
											>
												<div>
													{systems.length === 0 ? "Select System(s)" : systems.length + " selected"}
												</div>
												<i className="arrow down" />
											</div>
											<div className={"options " + (isComponentVisible ? "" : " hidden ")}>
												{systemOptions.map((option) => (
													<label key={option.value} className="option">
														<input
															type="checkbox"
															checked={systems.includes(option.value)}
															onChange={() => handleCheckboxChange(option.value)}
															value={option.value}
														/>
														<span> {option.label} </span>
													</label>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-12 text-right">
								<input
									className={
										"btn btn-primary " + (updateUserSettingsRes.loading ? " disabled " : "")
									}
									type="submit"
									value={translation("Apply Settings", "Actualizar Ajustes")}
								/>
							</div>
						</div>
					</form>
				</>
			);
		} else {
			return <></>;
		}
	};
	return (
		<div className="col-xs-12">
			{/* {(updateUserOnboardingRes.loading || updateUserSettingsRes.loading) && <Loading />} */}
			{updateUserSettingsRes.loading && <Loading />}
			<h1 className="page-header">{translation("My Settings", "Mis Ajustes")}</h1>
			{getPage()}
			{/* <div className="row">
				<div className="col-xs-12 text-left settings-question">
					<p>
						{translation(
							"If you'd like to review the T-Kit's onboarding you can do so by clicking the link below. Please be aware that your settings will be reset to the defaults.",
							"Si deseas revisar el programa de integración del T-Kit, puedes hacer clic en el vínculo a continuación. Toma en cuenta que tus ajustes volverán a ser los predeterminados.",
						)}
					</p>
					<div
						className="anc"
						style={{ width: "max-content" }}
						onClick={() => mainOnboardingReset()}
					>
						{translation(
							"Click Here to View Onboarding",
							"Has clic aquí para ver el programa de integración.",
						)}
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default MySettings;
