import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { imgPath, PathName } from "../../constants/PathName";
import { UserContext } from "../../contexts/UserContext";
import useProduct from "../../hooks/useProduct";
import ParseHTMLContent from "../../components/ParseHTMLContent/ParseHTMLContent";
import { useResetAppActive } from "../../components/Navbar/AppSwitcher/appSwitcher.service";
import { useResetNavActive } from "../../contexts/Navbar.recoil";
import classNames from "classnames";
import {
	ProductInfoFragment,
	ProductInfoNavigationFieldsFragment,
	ProductInfoNavigationFragment,
} from "../../generated/graphql";
import { useInfoQueryParams } from "./Info.service";

interface Props {
	navigation: ProductInfoNavigationFragment[];
	pages: ProductInfoFragment[];
	isInAppSwitcher?: boolean;
}

const MenuList: React.FC<Props> = ({ navigation, pages, isInAppSwitcher }) => {
	const { store } = useContext(UserContext);
	const { translation, userLanguage } = store;

	const { page: inner_p } = useInfoQueryParams();

	const product = useProduct();

	const { resetNavActive } = useResetNavActive();
	const { resetAppActive } = useResetAppActive();

	const checkIsSectionActive = useCallback(
		(navSection: ProductInfoNavigationFieldsFragment, pages: ProductInfoFragment[]) => {
			const page = pages.filter(
				(page) =>
					page.productInfoFields?.parent === navSection.navId &&
					page.productInfoFields?.pifid === inner_p,
			);
			if (page && page.length > 0) {
				return true;
			}
			return false;
		},
		[inner_p],
	);

	return (
		<div className="menu-list">
			{navigation.map((nav, key) => {
				const nav_section = nav.productInfoNavigationFields;

				const isSectionActive = checkIsSectionActive(nav_section!, pages);

				const dataTarget = nav_section?.htmlid + "Sub" + (isInAppSwitcher ? "T" : "F") + key;
				return (
					<div key={key} id={nav_section?.htmlid ?? ""} className={classNames("nav-section")}>
						<div
							className={classNames("nav-section-header anc ", {
								collapsed: !isSectionActive,
							})}
							data-toggle="collapse"
							data-target={"#" + dataTarget}
							aria-expanded={false}
							aria-controls={dataTarget}
							style={{ borderLeft: `10px solid ${nav_section?.customColor} ` }}
						>
							<ParseHTMLContent
								htmlString={translation(nav_section?.label, nav_section?.labelEs)}
							/>
							<img
								className="icon-chevron"
								src={imgPath + "/icon-chevron.svg"}
								alt="icon"
								onError={(e) => {
									console.error(e);
								}}
							/>
						</div>
						<div
							id={dataTarget}
							className={classNames("sub-menu", {
								collapse: true,
								in: isSectionActive,
							})}
						>
							{pages
								.filter((page) => page.productInfoFields?.parent === nav_section?.navId)
								.map((page, key) => {
									const nav_item = page.productInfoFields;
									return (
										<Link
											key={key}
											className={classNames(
												"anc",
												"page-link",
												inner_p === nav_item?.pifid ? "active" : "",
											)}
											to={PathName.DASHBOARD + product + "/" + nav_item?.url + "&l=" + userLanguage}
											onClick={() => {
												resetNavActive();
												resetAppActive();
											}}
										>
											<ParseHTMLContent
												htmlString={translation(nav_item?.title, nav_item?.titleEs)}
											/>
										</Link>
									);
								})}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default MenuList;
