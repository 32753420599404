import React, { PropsWithChildren } from "react";
import { useSetRecoilState } from "recoil";
import { topPdfViewerUrlAtom } from "../../contexts/topPdfViewer.recoil";
import { isMobile, isValidUrl } from "../../utils/utils";
import { Link } from "react-router-dom";

interface Props {
	url?: string;
	target?: string;
}

const ParseUrlTarget: React.FC<PropsWithChildren<Props>> = ({ children, url, target }) => {
	const setPDFUrl = useSetRecoilState(topPdfViewerUrlAtom);

	if (!url) {
		return <div className="no-url-found">{children}</div>;
	}

	if (isValidUrl(url)) {
		// from cdn
		if (url.includes("https://files-cdn") && url.includes(".pdf") && isMobile()) {
			return (
				<div className="anc" onClick={() => setPDFUrl(url)}>
					{children}
				</div>
			);
		}

		// in the same system just get from pathname
		const toUrl = new URL(url);
		if (
			(toUrl.hostname === window.location.hostname ||
				toUrl.hostname.includes("localhost") ||
				toUrl.hostname.includes("nuggetkms.com") ||
				toUrl.hostname.includes("mcdtkit.com")) &&
			!toUrl.hostname.includes("files-cdn")
		) {
			return (
				<Link
					to={toUrl.pathname + toUrl.search + toUrl.hash}
					target={isMobile() ? undefined : target}
				>
					{children}
				</Link>
			);
		}

		// for external links, last fallback
		return (
			<a href={url} target={target} rel="noreferrer noopener">
				{children}
			</a>
		);
	}

	return (
		<Link to={url} target={isMobile() ? undefined : target}>
			{children}
		</Link>
	);
};

export default ParseUrlTarget;
