import { gql, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import Loading from "../../../components/Loading/Loading";
import { UserContext } from "../../../contexts/UserContext";
import useProduct from "../../../hooks/useProduct";
import useQueryParams from "../../../hooks/useQueryParams";
import { calculate_top_margin } from "../../../utils/utils";
import GetBadges from "../PathsCommon/GetBadges";

const MY_BADGES_QUERY = gql`
	query GetMyBadgesComponentQuery(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
	) {
		userBadges(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				orderby: { field: SORT_ORDER, order: ASC }
			}
		) {
			nodes {
				userBadgeFields {
					id
					parentCard
					title
					titleEs
				}
			}
		}
	}
`;

const MyBadges = () => {
	//recoils / context
	const product = useProduct();
	const { store } = useContext(UserContext);
	const { translation, permissionGroup, userData } = store;
	const query = useQueryParams();
	const m = query.get("m");

	const { loading, error, data } = useQuery(MY_BADGES_QUERY, {
		variables: {
			acceptAccessRole: permissionGroup.accessLevel,
			acceptCompanyType: permissionGroup.companyType,
			acceptCustomRole: permissionGroup.customRole,
			userEid: userData.eID,
		},
	});

	if (loading) return <Loading />;
	if (error) return <p>Unable to load Badges page :(</p>;

	const userBadges = data.userBadges.nodes || [];

	return (
		<div id="main" className="container-fluid main" style={{ ...calculate_top_margin(product) }}>
			<div className="row">
				<div className="col-xs-12 last">
					<div className="row">
						<div className="col-xs-12 my-badges">
							<h1 className="page-header">{translation("My Badges", "Mis Insignias")}</h1>
							<p>
								{translation(
									"Earn badges as you complete all tasks in a card.",
									"Gana insignias conforme vayas completando todas las tareas en una tarjeta.",
								)}
							</p>

							<h5>{translation("Earned Badges", "Insignias Ganadas")}</h5>
							<GetBadges badges={userBadges} status="complete" />

							<h5>{translation("Badges I Could Earn", "Insignias que podría ganar")}</h5>
							<GetBadges badges={userBadges} status="incomplete" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyBadges;
