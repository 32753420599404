import { useEffect } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import { calculate_top_margin, setPageTitle } from "../../utils/utils";
import LearningPathProgress from "./LearningPathProgress/LearningPathProgress";
import LearningPaths from "./LearningPaths/LearningPaths";
import LearningPathStore from "./LearningPathStore/LearningPathStore";
import "../../styles/mcd-paths.scss";
import MyBadges from "./MyBadges/MyBadges";
import useProduct from "../../hooks/useProduct";

const Paths = () => {
	const product = useProduct();
	const query = useQueryParams();
	const p = query.get("p");
	const r = query.get("r");

	//@TODO unverified variables
	const $grantLPR = [
		/* Access Levels */
		"Level 1",
		//'Level 2',
		//'Level 3',
		//'Level 4',
		//'Level 5',
		//'Level 6',
		"Supervisor",
		"Supervisor w/ Role Assignment",
		"Organization Admin",
		"Operator",
		//'None',

		/* Company Type */
		"franchisee",

		/* Specific personID */
		//'2317651', // for local testing only
		// '4200156', //Erik Barraza
		// '165398', //Bridget Weber
		// '2486332', //Marvin Spence in Plainfield
		// '3878375', //Santiago Bahena in Plainfield

		/* Module */
		"cit",
	];

	useEffect(() => {
		setPageTitle(product);
	}, []);

	return (
		<div>
			{(p === "" || p === null) && (
				<div
					id="main"
					className="container-fluid main"
					style={{ ...calculate_top_margin(product) }}
				>
					<div className="row">
						{" "}
						<div className={"col-xs-12 "}>
							{r === "org-learning-path-progress" && <LearningPathProgress grantLPR={$grantLPR} />}
							{r === "org-learning-path-store" && <LearningPathStore grantLPR={$grantLPR} />}

							{!r && (
								/** SHOW THE LEARNING PATHS */
								<LearningPaths $grantLPR={$grantLPR} />
							)}
						</div>
					</div>
				</div>
			)}
			{p === "my-badges" && <MyBadges />}
		</div>
	);
};

export default Paths;
