import React, { useState } from "react";
import "./pdf-viewer.scss";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"; // Webpack 4 / CRA 4
import ControlPanel from "./ControlPanel";
import { useSetRecoilState } from "recoil";
import { topPdfViewerUrlAtom } from "../../contexts/topPdfViewer.recoil";

interface Props {
	file: string;
	shouldShowControl?: boolean;
	shouldShowFooter?: boolean;
	title?: string;
}

const PDFViewer: React.FC<Props> = ({ file, shouldShowControl, shouldShowFooter, title }) => {
	const [scale, setScale] = useState(1.0);
	const [rotate, setRotate] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const setTopPdfViewerUrl = useSetRecoilState(topPdfViewerUrlAtom);

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
	};

	return (
		<section className="pdf-viewer flex w-full flex-col items-center bg-slate-300">
			{shouldShowControl && (
				<ControlPanel
					scale={scale}
					setScale={setScale}
					numPages={numPages}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					setRotate={setRotate}
					rotate={rotate}
					onCloseClick={() => setTopPdfViewerUrl(undefined)}
				/>
			)}

			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				className="overflow-auto border border-slate-300"
			>
				<Page pageNumber={pageNumber} scale={scale} rotate={rotate} />
			</Document>

			{shouldShowFooter && (
				<div className="pdf-footer flex w-full justify-center border bg-white p-3">{title}</div>
			)}
		</section>
	);
};

export default PDFViewer;
