import { StoreForm } from "../../types/formTypes";

export const healthChecksDataTable: StoreForm[] = [
	{
		restaurantNSN: "2344",
		restaurantName: "72916 Raquel Route",
		createdAt: "2023-02-16",
		completedBy: "Darin Powlowski",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "0792",
		restaurantName: "96585 Becker Burgs",
		createdAt: "2023-02-20",
		completedBy: "Laverne Stoltenberg",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "9103",
		restaurantName: "270 Wolff Ports",
		createdAt: "2023-01-18",
		completedBy: "Santiago Sporer",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "5018",
		restaurantName: "3561 Osinski River",
		createdAt: "2023-03-06",
		completedBy: "Vivian Hoeger V",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "7472",
		restaurantName: "991 Elena Well",
		createdAt: "2023-07-14",
		completedBy: "Gary Veum",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "0895",
		restaurantName: "387 Conroy Corner",
		createdAt: "2023-03-20",
		completedBy: "Tanya Tillman III",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "7831",
		restaurantName: "33196 Corine Drive",
		createdAt: "2023-09-07",
		completedBy: "Steve Boehm MD",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "7284",
		restaurantName: "8701 Israel Neck",
		createdAt: "2023-06-24",
		completedBy: "Irma Thiel V",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "0484",
		restaurantName: "5564 Quitzon Neck",
		createdAt: "2023-10-27",
		completedBy: "Ana Wiegand Jr.",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "4129",
		restaurantName: "500 Karson Burg",
		createdAt: "2023-12-22",
		completedBy: "Shelly Weber",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "4903",
		restaurantName: "28566 Crona Avenue",
		createdAt: "2023-04-27",
		completedBy: "Kate Heller-Harris",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "8685",
		restaurantName: "88560 Larkin Gardens",
		createdAt: "2023-03-09",
		completedBy: "Scott Hessel",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "4535",
		restaurantName: "4246 Noe Estates",
		createdAt: "2023-11-25",
		completedBy: "Sophia Langworth-Bogisich Jr.",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "4706",
		restaurantName: "2259 Afton Port",
		createdAt: "2023-07-03",
		completedBy: "Kathy Spinka",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "5360",
		restaurantName: "75346 Hauck Shoals",
		createdAt: "2023-01-04",
		completedBy: "Deborah Hammes PhD",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "2862",
		restaurantName: "37619 Flatley Flat",
		createdAt: "2023-05-26",
		completedBy: "Joann Grady",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "2954",
		restaurantName: "2389 Leland Orchard",
		createdAt: "2023-12-24",
		completedBy: "Dianne Walsh-Cormier",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "8551",
		restaurantName: "870 Macejkovic Stravenue",
		createdAt: "2023-12-08",
		completedBy: "Nathan Ernser-Walker",
		hasActionPlan: true,
	},
	{
		restaurantNSN: "1215",
		restaurantName: "298 Hansen Point",
		createdAt: "2023-04-07",
		completedBy: "Crystal Conroy",
		hasActionPlan: false,
	},
	{
		restaurantNSN: "9938",
		restaurantName: "48696 Lola Trafficway",
		createdAt: "2023-10-14",
		completedBy: "Dr. Darrell McClure V",
		hasActionPlan: false,
	},
];

export const FormDetailData = {
	name: "Setting Expectations & Routines",
	hasFollowUp: false,
	organizationName: "MCDONALDS",
	updatedBy: "John Doe",
	estimatedTime: "20-30-min.",
	sections: [
		{
			name: "Section Test 1, This is just a test it will be replaced with real Data",
			description: "test description",
			items: [
				{
					name: "question 1",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [
						{
							type: "DROPDOWN",
							sortOrder: 1,
						},
					],
				},
				{
					name: "question 2",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [
						{
							type: "DROPDOWN",
							sortOrder: 1,
						},
					],
				},
			],
		},
		{
			name: "Section Test 2",
			description: "",
			items: [
				{
					name: "question 444",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [
						{
							type: "INPUT",
							sortOrder: 2,
						},
					],
				},
				{
					name: "question 20033",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [{ type: "SELECT2", sortOrder: 3 }],
				},
				{
					name: "question 6000",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [{ type: "SELECT1", sortOrder: 3 }],
				},
				{
					name: "question 6666",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [
						{
							type: "SELECT1",

							sortOrder: 3,
						},
						{
							name: "question 1",
							type: "TEXTAREA",

							sortOrder: 3,
						},
					],
				},
				{
					name: "question 7777",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [{ type: "SELECT1", sortOrder: 3 }],
				},
			],
		},
		{
			name: "Section Test 3",
			description: "test description 3",
			items: [
				{
					name: "question 1",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [
						{ name: "Hello", type: "DATE", sortOrder: 3, description: "answer this field" },
					],
				},
			],
		},
		{
			name: "Section Test 4",
			description: "test description",
			items: [
				{
					name: "question 1",
					description: "answer this field",
					sortOrder: 3,
					code: "q-1",
					relatedCode: null,
					answerFields: [
						{ name: "Hello", type: "RADIO", sortOrder: 4, description: "answer this field" },
					],
				},
			],
		},
		{
			name: "Section Test 5",
			description: "test description",
			items: [
				{
					name: "question 1",
					description: "answer this field",
					sortOrder: 1,
					code: "q-1",
					relatedCode: null,
					answerFields: [
						{ name: "Hello", type: "INPUT", sortOrder: 5, description: "answer this field" },
					],
				},
			],
		},
	],
};
