import { atom } from "recoil";

export const modalRenderAtom = atom<JSX.Element | undefined>({
	key: "modalRender",
	default: undefined,
});

export const modalTitleAtom = atom<string | undefined>({
	key: "modalTitle",
	default: undefined,
});
