import { useCallback, useContext, useEffect, useMemo } from "react";
import {
	useGetChecklistAssignmentReportsLazyQuery,
	useGetChecklistsDropdownLazyQuery,
} from "../../../generated/graphql";
import { UserContext, UserContextType } from "../../../contexts/UserContext";
import useQueryParams from "../../../hooks/useQueryParams";
import { ChecklistType } from "../checklistTypes";

export const useChecklistReports = () => {
	const { store } = useContext<UserContextType>(UserContext);
	const {
		userData: { storeNumber },
		permissionGroup: { accessLevel, companyType, customRole },
	} = store;
	const query = useQueryParams();
	const module = query.get("m"); // this is the initiative

	const [getChecklistAssignmentReports, getChecklistAssignmentReportsRes] =
		useGetChecklistAssignmentReportsLazyQuery();

	const callGetChecklistAssignmentReports = useCallback(
		(checklistId: number) => {
			getChecklistAssignmentReports({
				variables: {
					where: {
						acceptAccessRole: accessLevel,
						acceptCompanyType: companyType,
						acceptCustomRoles: customRole,
						checklistId,
						storeNSNs: storeNumber.split(";"),
						initiativeSlug: module,
					},
				},
			});
		},
		[accessLevel, companyType, customRole, getChecklistAssignmentReports, module, storeNumber],
	);

	const checklistAssignmentReports = useMemo(
		() => getChecklistAssignmentReportsRes.data?.getChecklistAssignmentReports?.rows ?? [],
		[getChecklistAssignmentReportsRes.data?.getChecklistAssignmentReports?.rows],
	);

	const groupedChecklistReports = useMemo(() => {
		const groupedChecklistReport: {
			[key: string]: {
				id: number;
				restaurantNSN: string;
				restaurantName: string;
				overdueTasks: number;
				incompleteTasks: number;
				assignmentDate: Date;
				checklistDefaultDate: Date;
				// customGoLiveDate: Date | null;
				initiative: string;
				storeId: number;
				targetDateLabel: string;
			};
		} = {};
		const overdueTasks = new Set<number>();
		const dueSooTasks = new Set<number>();
		checklistAssignmentReports.forEach((checklistAssignmentReport) => {
			let isTaskOverdue = null;
			if (
				checklistAssignmentReport != null &&
				checklistAssignmentReport.date != null &&
				checklistAssignmentReport.taskDueDateDays != null
			) {
				const currentAssignmentDate = checklistAssignmentReport.date;
				// checklistAssignmentReport.customGoLiveDate != null
				// 	? checklistAssignmentReport.customGoLiveDate
				// 	: checklistAssignmentReport.goLiveDate;
				isTaskOverdue = taskStatus(
					new Date(currentAssignmentDate),
					checklistAssignmentReport.taskDueDateDays,
				);
			}

			if (isTaskOverdue === "overdue") {
				overdueTasks.add(checklistAssignmentReport?.taskDbId!);
			}

			if (isTaskOverdue === "due soon") {
				dueSooTasks.add(checklistAssignmentReport?.taskDbId!);
			}

			groupedChecklistReport[String(checklistAssignmentReport?.checklistAssignmentId!)] = {
				id: checklistAssignmentReport?.checklistAssignmentId!,
				restaurantNSN: checklistAssignmentReport?.nsn!,
				restaurantName: checklistAssignmentReport?.storeName!,
				assignmentDate: new Date(checklistAssignmentReport?.date!),
				checklistDefaultDate: new Date(checklistAssignmentReport?.defaultDate!),
				// customGoLiveDate:
				// 	checklistAssignmentReport?.customGoLiveDate != null
				// 		? new Date(checklistAssignmentReport?.customGoLiveDate)
				// 		: null,
				incompleteTasks: dueSooTasks.size,
				overdueTasks: overdueTasks.size,
				initiative: checklistAssignmentReport?.initiative!,
				storeId: checklistAssignmentReport?.storeId!,
				targetDateLabel: checklistAssignmentReport?.targetDateLabel!,
			};
		});
		return groupedChecklistReport;
	}, [checklistAssignmentReports]);

	const isLoading = getChecklistAssignmentReportsRes.loading;

	return {
		checklistAssignmentReports,
		callGetChecklistAssignmentReports,
		isLoading,
		groupedChecklistReports,
	};
};

export const useChecklistDropdown = () => {
	const { store } = useContext<UserContextType>(UserContext);
	const {
		permissionGroup: { accessLevel, companyType, customRole },
	} = store;
	const query = useQueryParams();
	const module = query.get("m"); // this is the initiative

	const [getChecklistDropdown, getChecklistDropdownRes] = useGetChecklistsDropdownLazyQuery();

	useEffect(() => {
		getChecklistDropdown({
			variables: {
				acceptAccessRole: accessLevel,
				acceptCompanyType: companyType,
				acceptCustomRole: customRole,
				initiativeSlug: module,
			},
		});
	}, [accessLevel, companyType, customRole, getChecklistDropdown, module]);

	const checklistDropdowns = useMemo(() => {
		return (
			getChecklistDropdownRes.data?.checklists?.nodes?.filter(
				(checklist) => checklist?.checklistFields?.type === ChecklistType.Store,
			) ?? []
		);
	}, [getChecklistDropdownRes.data?.checklists?.nodes]);
	const isLoading = getChecklistDropdownRes.loading;

	return {
		checklistDropdowns,
		isLoading,
	};
};

export const taskStatus = (
	goLiveDate: Date,
	dueDateDays: number,
): "overdue" | "due soon" | null => {
	const today = new Date();
	const targetDate = new Date(goLiveDate);
	targetDate.setDate(targetDate.getDate() + dueDateDays);

	// Difference between today and target date
	const diffTime = targetDate.getTime() - today.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays >= 0 && diffDays <= 5) {
		return "due soon";
	} else if (diffDays < 0) {
		return "overdue";
	}

	return null;
};
