import Modal from "./Modal";
import { useModals } from "./modals.recoil";

const Modals: React.FC = () => {
	const { modals } = useModals();

	return (
		<div>
			{modals.map((modal, i) => {
				return <Modal key={i} {...modal} />;
			})}
		</div>
	);
};

export default Modals;
